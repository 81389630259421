import axios from "axios";
import { getApi } from "../backend";

export const allReferal = async () => {
  const config = {
    method: "get",
    url: `${getApi()}/auth/all-referal`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
      }`,
    },
  };
  try {
    const res = await axios(config);
    // console.log(res.data, "1.3");
    // console.log(localStorage.getItem("token"));
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const referalByUser = async (code) => {
  const config = {
    method: "get",
    url: `${getApi()}/auth/users/referal/${code}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
      }`,
    },
  };
  try {
    const res = await axios(config);
    // console.log(res.data, "2.3");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
