import React, { useEffect, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Card from "../../../components/Card";
import { AiOutlineEye } from "react-icons/ai";
import { getReportedPosts, deletePost } from "../../../Hooks/postHooks";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import useFullPageLoader from "../../../Hooks/useFullPageLoader";
import { toast } from "react-hot-toast";
import { Pagination } from "@mui/material";
import Stack from "@mui/material/Stack";

const ReportedPostList = () => {
  const initData = Object.freeze([
    {
      postId: "",
      userId: "",
    },
  ]);
  let initCap = "";
  const [item, setItem] = useState(initData);
  const [postCount, setPostCount] = useState();
  let history = useHistory();
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const fetchPosts = () => {
    showLoader();
    getReportedPosts(currentPage).then((res) => {
      if (res.success === false) {
        setPostCount("Error: " + res.message);
        hideLoader();
      } else {
        setItem(res.data);
        // console.log(res.data , "fetched data");
        setPostCount("Reported Post Count: " + res.data.length);
        setPageCount(res.pages);
        hideLoader();
      }
    });
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    fetchPosts();
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const nav = (id) => {
    history.push(`/dashboard/app/post-reported-details/${id}`);
  };

  // const nav = (id, isPostDeleted) => {
  //   if (isPostDeleted) {
  //     // alert("This post has been deleted. User details cannot be viewed.");
  //     toast.error("This post has been deleted. User details cannot be viewed.");
  //   } else {
  //     history.push(`/dashboard/app/post-reported-details/${id}`);
  //   }
  // };

  const delPost = async (id) => {
    deletePost(id).then((res) => {
      alert(res.message);
      fetchPosts();
    });
  };

  return (
    <>
      <div>
        <Row>
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Reported Post List</h4>
                  <span>
                    <button className="btn btn-sm btn-primary" onClick={fetchPosts}>
                      Reload
                    </button>
                  </span>
                </div>
                <div>
                  <h4 className="card-title">{postCount}</h4>
                </div>
              </Card.Header>
              <Card.Body className="px-0">
                <div className="table-responsive">
                  <table
                    id="user-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="ligth">
                        <th>Profile</th>
                        <th>User Name</th>
                        <th>Contact</th>
                        <th>Email</th>
                        <th>Post Caption</th>
                        <th>Type</th>
                        <th>Post Views</th>
                        <th min-width="100px">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.map((item, idx) => (
                        <tr key={idx}>
                          <td className="text-center">
                            <Image
                              className="bg-soft-primary rounded img-fluid avatar-40 me-3"
                              src={item.userId === null ? <p>Null</p> : item.userId.profilePic}
                              alt="profile"
                            />
                          </td>
                          <td>
                            {item.userId === null ? (
                              <p>Null</p>
                            ) : (
                              <p
                                style={{ color: "orange", cursor: "pointer" }}
                                onClick={() =>
                                  nav(item._id, item.postId === "This post has been deleted")
                                }
                              >
                                {item.userId.name}
                              </p>
                            )}
                          </td>
                          <td>{item.userId === null ? <p>Null</p> : item.userId.phone}</td>
                          <td>{item.userId === null ? <p>Null</p> : item.userId.email}</td>
                          <td>
                            {item.userId === null ? (
                              <p>Null</p>
                            ) : (
                              new String(item.postId.caption).slice(0, 18)
                            )}
                          </td>
                          <td>{item.userId === null ? <p>Null</p> : item.userId.mediaType}</td>
                          <td>{item.userId === null ? <p>Null</p> : item.userId.views}</td>
                          <td>
                            <div className="flex align-items-center list-user-action">
                              {item.userId === null ? (
                                <p></p>
                              ) : (
                                <Link
                                  className="btn btn-sm btn-icon btn-success mx-2"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title=""
                                  data-original-title="Edit"
                                  to="#"
                                  onClick={() =>
                                    nav(item._id, item.postId === "This post has been deleted")
                                  }
                                >
                                  <OverlayTrigger
                                    overlay={<Tooltip id="tooltip-disabled">View</Tooltip>}
                                  >
                                    <span className="btn-inner">
                                      <AiOutlineEye size={23} />
                                    </span>
                                  </OverlayTrigger>
                                </Link>
                              )}

                              {item.userId === null ? (
                                <p></p>
                              ) : (
                                <Link
                                  className="btn btn-sm btn-icon btn-danger"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title=""
                                  data-original-title="Delete"
                                  to="#"
                                  onClick={() => delPost(item._id)}
                                >
                                  <OverlayTrigger
                                    overlay={<Tooltip id="tooltip-disabled">Delete</Tooltip>}
                                  >
                                    <span className="btn-inner">
                                      <svg
                                        width="20"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        stroke="currentColor"
                                      >
                                        <path
                                          d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                        <path
                                          d="M20.708 6.23975H3.75"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                        <path
                                          d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                      </svg>
                                    </span>
                                  </OverlayTrigger>
                                </Link>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <div style={{ display: "flex", justifyContent: "center", padding: "8px" }}>
        {/* <p>{userCount}</p> */}
        <Stack spacing={2} sx={{ "& .MuiPaginationItem-root": { borderRadius: 0 } }}>
          {currentPage !== 1 ? (
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={handlePageChange}
              color="grey"
              size="large"
              showFirstButton
              showLastButton
            />
          ) : (
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={handlePageChange}
              color="grey"
              size="large"
              hidePrevButton
              hideNextButton
            />
          )}
        </Stack>
      </div>
      {loader}
    </>
  );
};

export default ReportedPostList;
