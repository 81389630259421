import React, { useState, useEffect } from "react";
import { Row, Col, Image, Tab } from "react-bootstrap";
import Card from "../../../components/Card";
import { useParams, useHistory, Link } from "react-router-dom";
import { reportedCommentInfo, deleteComment } from "../../../Hooks/postHooks";
import { banUser, deleteUser, unbanUser } from "../../../Hooks/userHooks";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { HiOutlineBan } from "react-icons/hi";
import { AiOutlineEye } from "react-icons/ai";
import { BsCircle } from "react-icons/bs";

const ReportedCommentsDetails = () => {
  const initData = Object.freeze({
    reportedBy: "",
    userId: "",
  });
  const initDataComment = Object.freeze({
    commentId: "",
    postId: "",
  });
  const initUser = Object.freeze({
    email: "",
    username: "",
    profilePic: "",
  });
  const [reportedBy, setReportedBy] = useState([initData]);
  const [userInfo, setUserInfo] = useState(initUser);

  const [title, setTitle] = useState(initDataComment);
  const { id } = useParams();
  const history = useHistory();

  const CommentInfo = () => {
    reportedCommentInfo(id)
      .then((res) => {
        setTitle(res.data);
        setReportedBy(res.data.reportedBy);
        setUserInfo(res.data.userId);
        console.log(res.data.userId);
      })
      .catch((err) => {
        setTitle("Data Not Found");
      });
  };

  useEffect(() => {
    CommentInfo();
  }, []);

  const delComment = async (pid, cid) => {
    deleteComment(pid, cid).then((res) => {
      history.push("/dashboard/app/comments-reported");
    });
  };
  const banUserById = async (id) => {
    // alert(id)
    banUser(id).then((res) => {
      // console.log(res);
      // alert(res.message);
    });
  };
  const unbanUserById = async (id) => {
    // alert(id)
    unbanUser(id).then((res) => {
      // console.log(res)
      alert(res.message);
    });
  };

  const delUser = async (id) => {
    // alert(id)
    deleteUser(id).then((res) => {
      // console.log(res)
      alert(res.message);
    });
  };
  const nav = (id) => {
    history.push(`/dashboard/app/user-profile/${id}`);
  };

  return (
    <>
      <Tab.Container defaultActiveKey="first">
        <Row>
          <Col lg="12">
            <Card>
              <Card.Body>
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                      <h4 className="me-2 h4">
                        Comment: <p>{title.commentId.comment}</p>
                      </h4>
                    </div>
                  </div>
                  <span>
                    <button
                      className="btn btn-sm btn-primary"
                      style={{ marginRight: "0.5rem" }}
                      onClick={CommentInfo}
                    >
                      Reload
                    </button>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() =>
                        delComment(title.postId, title.commentId._id)
                      }
                    >
                      Delete
                    </button>
                  </span>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="12">
            <Card>
              <Card.Body>
                <h4 style={{ marginBottom: "1rem" }}>User Info</h4>
                <div className="table-responsive">
                  <table
                    id="user-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="ligth">
                        <th>User Profile</th>
                        <th>User Name</th>
                        <th>Email</th>
                        <th min-width="100px">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">
                          <Image
                            className="bg-soft-primary rounded img-fluid avatar-40 me-3"
                            src={userInfo.profilePic}
                            alt="profile"
                          />
                        </td>
                        <td>{userInfo.username}</td>
                        <td>{userInfo.email}</td>
                        <td>
                          <div className="flex align-items-center list-user-action">
                            {userInfo.active ? (
                              <Link
                                className="btn btn-sm btn-icon btn-warning mx-1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Add"
                                to="#"
                                onClick={() => banUserById(userInfo._id)}
                              >
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-disabled">Ban</Tooltip>
                                  }
                                >
                                  <span className="btn-inner">
                                    <HiOutlineBan size={23} />
                                  </span>
                                </OverlayTrigger>
                              </Link>
                            ) : (
                              <Link
                                className="btn btn-sm btn-icon btn-info mx-1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Add"
                                to="#"
                                onClick={() => unbanUserById(userInfo._id)}
                              >
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      Unban
                                    </Tooltip>
                                  }
                                >
                                  <span className="btn-inner">
                                    <BsCircle size={23} />
                                  </span>
                                </OverlayTrigger>
                              </Link>
                            )}
                            <Link
                              className="btn btn-sm btn-icon btn-success"
                              data-toggle="tooltip"
                              data-placement="top"
                              title=""
                              data-original-title="Edit"
                              to="#"
                              onClick={() => nav(userInfo._id)}
                            >
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    View More
                                  </Tooltip>
                                }
                              >
                                <span className="btn-inner">
                                  <AiOutlineEye size={23} />
                                </span>
                              </OverlayTrigger>
                            </Link>{" "}
                            <Link
                              className="btn btn-sm btn-icon btn-danger"
                              data-toggle="tooltip"
                              data-placement="top"
                              title=""
                              data-original-title="Delete"
                              to="#"
                              onClick={() => delUser(userInfo._id)}
                            >
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    Delete
                                  </Tooltip>
                                }
                              >
                                <span className="btn-inner">
                                  <svg
                                    width="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    stroke="currentColor"
                                  >
                                    <path
                                      d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M20.708 6.23975H3.75"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                </span>
                              </OverlayTrigger>
                            </Link>{" "}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="12">
            <Card>
              <Card.Body>
                <h4 style={{ marginBottom: "1rem" }}>Reported By</h4>
                <div className="table-responsive">
                  <table
                    id="user-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="ligth">
                        <th>Reason</th>
                        <th>User Profile</th>
                        <th>User Name</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reportedBy.map((item, idx) => (
                        <tr key={idx}>
                          <td>{item.reason}</td>
                          <td className="text-center">
                            <Image
                              className="bg-soft-primary rounded img-fluid avatar-40 me-3"
                              src={item.userId.profilePic}
                              alt="profile"
                            />
                          </td>
                          <td>{item.userId.username}</td>
                          <td>{item.userId.email}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default ReportedCommentsDetails;
