import React from "react";
import { toast } from "react-hot-toast";
import { getApi } from "../../../backend";

const AddTournaments = () => {
  const handleAddAdmin = (event) => {
    event.preventDefault();
    const form = event.target;

    const name = form.name.value;
    const email = form.email.value;
    const username = form.username.value;
    const password = form.password.value;
    const role = form.role.value;
    const status = form.status.value;

    const admin = {
      name,
      email,
      username,
      password,
      role,
      status,
    };

    let url = `${getApi()}/auth/register`;

    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${
          typeof window !== "undefined" &&
          JSON.parse(localStorage.getItem("token"))
        }`,
      },
      body: JSON.stringify(admin),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          form.reset();
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => alert(error.message));
  };

  return (
    <div className="mt-1 bg-white p-4 rounded">
      <h1 className="fs-3 mb-4">Add Admin</h1>
      <form onSubmit={handleAddAdmin} noValidate="" className="row g-3 w-75">
        <div className="col-md-5">
          <label htmlFor="validationDefault01" className="form-label">
            Name
          </label>
          <input
            type="text"
            className="form-control"
            name="name"
            id="validationDefault01"
            placeholder="Name"
            required
          />
        </div>
        <div className="col-md-5">
          <label htmlFor="validationDefault02" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            name="email"
            id="validationDefault02"
            placeholder="Email"
            required
          />
        </div>
        <div className="col-md-5">
          <label htmlFor="validationDefaultUsername" className="form-label">
            Username
          </label>
          <div className="input-group">
            <span className="input-group-text" id="inputGroupPrepend2">
              @
            </span>
            <input
              type="text"
              className="form-control"
              name="username"
              id="validationDefaultUsername"
              aria-describedby="inputGroupPrepend2"
              required
            />
          </div>
        </div>
        <div className="col-md-5">
          <label htmlFor="validationDefault05" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            name="password"
            id="validationDefault05"
            placeholder="Password"
            required
          />
        </div>
        <div className="col-md-5">
          <label htmlFor="validationDefault03" className="form-label">
            Role
          </label>
          <select
            name="role"
            className="form-select"
            id="validationDefault03"
            required
          >
            <option selected value="admin">
              admin
            </option>
            <option value="superadmin">superadmin</option>
            <option value="organiser">organiser</option>
          </select>
        </div>
        <div className="col-md-5">
          <label htmlFor="validationDefault04" className="form-label">
            Status
          </label>
          <select
            name="status"
            className="form-select"
            id="validationDefault04"
            required
          >
            <option selected value="active">
              active
            </option>
            <option value="inactive">inactive</option>
          </select>
        </div>
        <div className="col-12">
          <button className="btn btn-primary" type="submit">
            Submit form
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddTournaments;
