import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Card from "../../../components/Card";
import { Link, useHistory } from "react-router-dom";
import { allReferal } from "../../../Hooks/referalHooks";
import { AiOutlineEye } from "react-icons/ai";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import useFullPageLoader from "../../../Hooks/useFullPageLoader";
import jsPDF from "jspdf";

const ReferalAll = () => {
  const [item, setItem] = useState([]);
  const [refCount, setRefCount] = useState([]);
  let history = useHistory();
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const fetchRefs = () => {
    showLoader();
    allReferal().then((res) => {
      if (res.success === false) {
        setRefCount("Error: " + res.message);
        hideLoader();
      } else {
        setItem(res.data);
        setRefCount("Referal Count: " + res.data.length);
        hideLoader();
      }
    });
  };

  useEffect(() => {
    fetchRefs();
  }, []);

  const nav = (code) => {
    history.push(`/dashboard/app/referal-user/${code}`);
  };
  const print = () => {
    const pdf = new jsPDF("p", "pt", "a4");
    const columns = [
      "S.No.",
      "Code",
      "Email",
      "Created At",
      "Refer Count",
    ];
    var rows = [];
    var count = 1;
    for (let i = 0; i < item.length; i++) {
      var temp = [
        count++,
        item[i].code,
        item[i].email,
        item[i].createdAt,
        item[i].userCount,
      ];
      rows.push(temp);
    }
    pdf.text(235, 40, "Playwise Referals List");
    pdf.autoTable(columns, rows, {
      startY: 65,
      theme: "grid",
      styles: {
        font: "times",
        halign: "center",
        cellPadding: 3.5,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        textColor: [0, 0, 0],
      },
      headStyles: {
        textColor: [0, 0, 0],
        fontStyle: "normal",
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [234, 106, 18],
      },
      alternateRowStyles: {
        fillColor: [212, 212, 212],
        textColor: [0, 0, 0],
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
      },
      rowStyles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
      },
      tableLineColor: [0, 0, 0],
    });
    // console.log(pdf.output("datauristring"));
    pdf.save("pdf");
  };
  console.log(item);
  return (
    <>
      <div>
        <Row>
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Referals</h4>
                  <span>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={fetchRefs}
                    >
                      Reload
                    </button>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={print}
                      style={{marginLeft:"15px"}}
                    >
                      Export
                    </button>
                  </span>
                </div>
                <div>
                  <h4 className="card-title">{refCount}</h4>
                </div>
              </Card.Header>
              <Card.Body className="px-0">
                <div className="table-responsive">
                  <table
                    id="user-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="ligth">
                        <th>Code</th>
                        <th>Email</th>
                        <th>Join Date</th>
                        <th>Refer Count</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.map((item, idx) => (
                        <tr key={idx}>
                          <td
                          style={{color:"orange", cursor: "pointer"}}
                            onClick={() => nav(item.code)}
                          >{item.code}</td>
                          <td>{item.email}</td>
                          <td>{new String(item.createdAt).split("T")[0]}</td>
                          <td>{item.userCount}</td>
                          <td>
                            <Link
                              className="btn btn-sm btn-icon btn-success"
                              data-toggle="tooltip"
                              data-placement="top"
                              title=""
                              data-original-title="Edit"
                              to="#"
                              onClick={() => nav(item.code)}
                            >
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-disabled">View</Tooltip>
                                }
                              >
                                <span className="btn-inner">
                                  <AiOutlineEye size={23} />
                                </span>
                              </OverlayTrigger>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      {loader}
    </>
  );
};

export default ReferalAll;
