import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Card from "../../../components/Card";
import { AiOutlineEye } from "react-icons/ai";
import { BsFilePost } from "react-icons/bs";
import {
  getReportedComments,
  deleteComment
} from "../../../Hooks/postHooks";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import useFullPageLoader from "../../../Hooks/useFullPageLoader";

const ReportedComments = () => {
  const [item, setItem] = useState([]);
  const [commentsCount, setCommentsCount] = useState([]);
  let history = useHistory();
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const fetchComments = () => {
    showLoader()
    getReportedComments().then((res) => { 
      console.log(res);
      if (res.success === false) {
        setCommentsCount("Error: " + res.message);
        hideLoader()
      } else {
        setItem(res.data);
        console.log(res.data)
        setCommentsCount("User Count: " + res.data.length);
        hideLoader()
      }
    });
  };

  useEffect(() => {
    fetchComments()
  }, []);

  const delComment = async (pid, cid) => {
    deleteComment(pid, cid)
    fetchComments()
  };

  const nav = (id) => {
    history.push(`/dashboard/app/comments-reported-details/${id}`);
  };

  return (
    <>
      <div>
        <Row>
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Comments List</h4>
                  <span>
                    <button className="btn btn-sm btn-primary" 
                    onClick={fetchComments}>
                      Reload
                    </button>
                  </span>
                </div>
                <div>
                  <h4 className="card-title">{commentsCount}</h4>
                </div>
              </Card.Header>
              <Card.Body className="px-0">
                <div className="table-responsive">
                  <table
                    id="user-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="ligth">
                        <th>Comment</th>                       
                        <th>No. of user who reported</th>                       
                        <th min-width="100px">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.map((item, idx) => (
                        <tr key={idx}>                       
                          <td>{item.commentId && item.commentId.comment}</td>                  
                          <td>{item.reportedBy}</td>                  
                          <td>
                            <div className="flex align-items-center list-user-action">                         
                            <Link
                                className="btn btn-sm btn-icon btn-success"
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Edit"
                                to="#"
                                onClick={() => nav(item.commentId.id)}
                              >
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      View More
                                    </Tooltip>
                                  }
                                >
                                  <span className="btn-inner">
                                    <AiOutlineEye size={23} />
                                  </span>
                                </OverlayTrigger>
                              </Link>{" "}
                              <Link
                                className="btn btn-sm btn-icon btn-secondary"
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Edit"
                                to="#"
                                onClick={() =>  history.push(`/dashboard/app/post-reported-details/${item.postId}`)}
                              >
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      View Post
                                    </Tooltip>
                                  }
                                >
                                  <span className="btn-inner">
                                    <BsFilePost size={23} />
                                  </span>
                                </OverlayTrigger>
                              </Link>{" "}
                              <Link
                                className="btn btn-sm btn-icon btn-danger"
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Delete"
                                to="#"
                                onClick={() => delComment(item.postId, item.commentId._id)}
                              >
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      Delete
                                    </Tooltip>
                                  }
                                >
                                  <span className="btn-inner">
                                    <svg
                                      width="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      stroke="currentColor"
                                    >
                                      <path
                                        d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M20.708 6.23975H3.75"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                    </svg>
                                  </span>
                                </OverlayTrigger>
                              </Link>{" "}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      {loader}
    </>
  );
};

export default ReportedComments;
