import moment from "moment";
export const convertUTCtoISTForDisplay = (dateUTC) => {
  // return moment(dateUTC).format("YYYY-MM-DDTHH:mm");

  // console.log(
  //   dateUTC,
  //   moment(dateUTC).format("YYYY-MM-DDTHH:mm"),
  //   moment
  //     .utc(moment(dateUTC).format("YYYY-MM-DDTHH:mm"))
  //     .format("YYYY-MM-DDTHH:mm"),
  //   moment.utc(dateUTC).format("YYYY-MM-DDTHH:mm")
  // );

  return moment.utc(dateUTC).format("YYYY-MM-DDTHH:mm"); //use this on test or production

  // return moment
  //   .utc(moment(dateUTC).format("YYYY-MM-DDTHH:mm"))
  //   .format("YYYY-MM-DDTHH:mm"); // use this on localhost
};
