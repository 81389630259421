import React, { useEffect, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import Card from "../../../components/Card";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { AiOutlineEye } from "react-icons/ai";
import { Link, useHistory } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import "./Paginate/Paginate.css";
import { tw } from "twind";
import axios from "axios";
import LoaderSpiner from "./loading";
import { getApi } from "../../../backend";

const gamesList = () => {
  const initData = Object.freeze([
    {
      gameName: "loading...",
      short_Name: "loading...",
      cover: "loading...",
      platform: "loading"
    },
  ]);

  const [item, setItem] = useState(initData);
  const [loading, setLoading] = useState(true);
  let history = useHistory();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [sizes, setSize] = useState(10);


  const [allGames, setAllGames] = useState([]);

 
  


//   console.log(allTournaments);

  const getAllGames = async () => {
    const allGames = await axios({
      url: `${getApi()}/games/`,
      method: "get",
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? JSON.parse(localStorage.getItem("token"))
            : ""
        }`,
      },
    });

    console.log(allGames);
    setAllGames(allGames?.data?.data);
    setLoading(false);
  };

  useEffect(() => {
    // fetchTournament();
    getAllGames();
  }, [page]);

  const pages = Math.ceil(count / sizes);

  // console.log("count", count);

  const nav = (id) => {
    history.push(`/dashboard/app/games/${id}`);
  };


  const delGame = async (item) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this game?");
    
    if (isConfirmed) {
      
    
    try {
      const response = await axios({
        url: `${getApi()}/games/deleteGame/${item}`,
        method: "delete",
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token")
              ? JSON.parse(localStorage.getItem("token"))
              : ""
          }`,
        },
      });

      if (response.data.success) {
        toast.success(response.data.message);
        getAllGames();
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
  
  };

  if(loading) {
    return <LoaderSpiner />
  }

  return (
    <>
      <div>
        <Toaster />
        <Row>
          <Col sm="12">
            <Card>
              

              <Card.Body className="px-0">
                {item?.length === 0 ? (
                  <>
                    <h3 className="mx-4">No Games list Found</h3>
                  </>
                ) : (
                  <>
                    {/* <Card.Body> */}
                    <div className="table-responsive">
                      <table
                        id="user-list-table"
                        className="table table-striped"
                        role="grid"
                        data-toggle="data-table"
                      >
                        <thead>
                          <tr className="ligth">
                            <th>Cover</th>
                            <th>Game Name</th>
                            <th>Platform</th>
                            <th min-width="100px">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allGames?.map((item, idx) => (
                            <tr key={idx}>
                              <td
                                className="text-center"
                                onClick={() => nav(item._id)}
                                style={{ cursor: "pointer" }}
                              >
                                <Image
                                  className="bg-soft-primary rounded img-fluid avatar-40 me-3"
                                  src={item.coverUrl}
                                  alt="profile"
                                />
                              </td>
                              <td
                                onClick={() => nav(item._id)}
                                style={{ cursor: "pointer" }}
                              >
                                {item.gameName}
                              </td>
                              <td>
                                {item.platform ? item.platform : null}
                              </td>
                                
                              <td>
                                <div className="flex align-items-center list-user-action">
                                  <Link
                                    className="btn btn-sm btn-icon btn-success me-2"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title=""
                                    data-original-title="Edit"
                                    to="#"
                                    onClick={() => nav(item._id)}
                                  >
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip id="tooltip-disabled">
                                          View More
                                        </Tooltip>
                                      }
                                    >
                                      <span className="btn-inner">
                                        <AiOutlineEye size={23} />
                                      </span>
                                    </OverlayTrigger>
                                  </Link>
                                  <Link
                                    className="btn btn-sm btn-icon btn-warning"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title=""
                                    data-original-title="Add"
                                    to={`/dashboard/app/updateGames/${item._id}`}
                                    // onClick={() => banUserById(item._id)}
                                  >
                                    <span>
                                      <svg
                                        width="20"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                        <path
                                          d="M15.1655 4.60254L19.7315 9.16854"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                      </svg>
                                    </span>
                                  </Link>

                                  <div
                                    className="btn btn-sm btn-icon btn-danger ms-2"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title=""
                                    data-original-title="Delete"
                                    to="#"
                                    onClick={() => delGame(item._id)}
                                  >
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip id="tooltip-disabled">
                                          Delete
                                        </Tooltip>
                                      }
                                    >
                                      <span className="btn-inner">
                                        <svg
                                          width="20"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          stroke="currentColor"
                                        >
                                          <path
                                            d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                          <path
                                            d="M20.708 6.23975H3.75"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                          <path
                                            d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                        </svg>
                                      </span>
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {/* </Card.Body> */}
                  </>
                )}


                <div className="paginate-container">
                  <div className="paginate">
                    <button
                      onClick={() => {
                        page > 1 && setPage(page - 1);
                      }}
                    >
                      {"<"}
                    </button>
                    {[...Array(pages).keys()].map((number) => (
                      <button
                        key={number}
                        className={page === number + 1 ? "selected" : ""}
                        onClick={() => setPage(number + 1)}
                      >
                        {number + 1}
                      </button>
                    ))}
                    <button
                      onClick={() => {
                        Array(pages).length > page && setPage(page + 1);
                      }}
                    >
                      {">"}
                    </button>
                    <select
                      className="selection"
                      onChange={(event) => setSize(event.target.value)}
                      defaultValue={sizes}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    
    </>
  );
};

export default gamesList;
