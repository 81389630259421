import React from "react";
import Index from "../views/dashboard/index";
import { Switch, Route } from "react-router-dom";

// user
import UserDetails from "../views/dashboard/app/user-details";
import DummyUserDetails from "../views/dashboard/app/dummy-user-details";
import ReportedUserProfile from "../views/dashboard/app/reported-user-profile";
import UserAdd from "../views/dashboard/app/user-add";
import UserList from "../views/dashboard/app/user-list";
import UserDummy from "../views/dashboard/app/user-dummy";
import userProfileEdit from "../views/dashboard/app/user-privacy-setting";
import UserReported from "../views/dashboard/app/user-reported";
import DeletedUserList from "../views/dashboard/app/deleted-user-list";

// Post
import ReportedPostList from "../views/dashboard/app/reported-post-list";
import ReportedPostProfile from "../views/dashboard/app/reported-post-details";
import ReportedComments from "../views/dashboard/app/reported-comment-list";
import ReportedCommentsDetails from "../views/dashboard/app/reported-comments-details";
import PostList from "../views/dashboard/app/p-list";
import PostDetails from "../views/dashboard/app/p-details";
//SMS
import SendMsg from "../views/dashboard/app/send-msg";

// Referal
import ReferalAll from "../views/dashboard/app/referal-all";
import ReferalGen from "../views/dashboard/app/referal-gen";
import ReferalByUser from "../views/dashboard/app/referal-by-user";

//Tournaments

import AddTournaments from "../views/dashboard/app/add-tournaments copy.js";
import TournamentForm from "../views/dashboard/app/add-tournaments-new.js";
import TournamentsList from "../views/dashboard/app/tournaments-list";
import TournamentsDetails from "../views/dashboard/app/tournament-details";
import DeclareWinner from "../views/dashboard/app/declareWinner.js";

// widget
import Widgetbasic from "../views/dashboard/widget/widgetbasic";
import Widgetcard from "../views/dashboard/widget/widgetcard";
import Widgetchart from "../views/dashboard/widget/widgetchart";
// icon
import Solid from "../views/dashboard/icons/solid";
import Outline from "../views/dashboard/icons/outline";
import DualTone from "../views/dashboard/icons/dual-tone";
// Form
import FormElement from "../views/dashboard/from/form-element";
import FormValidation from "../views/dashboard/from/form-validation";
import FormWizard from "../views/dashboard/from/form-wizard";
// table
import BootstrapTable from "../views/dashboard/table/bootstrap-table";
import TableData from "../views/dashboard/table/table-data";

// map
import Vector from "../views/dashboard/maps/vector";
import Google from "../views/dashboard/maps/google";

//extra
import PrivacyPolicy from "../views/dashboard/extra/privacy-policy";
import TermsofService from "../views/dashboard/extra/terms-of-service";

//TransitionGroup
import { TransitionGroup, CSSTransition } from "react-transition-group";
//Special Pages
import Billing from "../views/dashboard/special-pages/billing";
import Kanban from "../views/dashboard/special-pages/kanban";
import Pricing from "../views/dashboard/special-pages/pricing";
import Timeline from "../views/dashboard/special-pages/timeline";
import Calender from "../views/dashboard/special-pages/calender";
//admin
import Admin from "../views/dashboard/admin/admin";
import UserBanned from "../views/dashboard/app/user-banned";
import UserPostProfile from "../views/dashboard/app/User-Single-post";
import UserAllPost from "../views/dashboard/app/User-All-posts";
import Result from "../views/dashboard/app/result";
import UpdateTournaments from "../views/dashboard/app/tournament-update";
import UpdateTournamentsNew from "../views/dashboard/app/add-tournaments-new-update";

import AddAdmins from "../views/dashboard/app/add-admins";
import AdminsList from "../views/dashboard/app/admins-list";
import StageDetails from "../views/dashboard/app/stage-details";

//games routes
import createGame from "../views/dashboard/app/createGame.js";
import GamesList from "../views/dashboard/app/games-list.js";
import GamesDetails from "../views/dashboard/app/games-details.js";
import GamesUpdate from "../views/dashboard/app/games-update.js";

//rewards
import createRewards from "../views/dashboard/app/createRewards.js"

const DefaultRouter = () => {
  return (
    <TransitionGroup>
      <CSSTransition>
        <Switch>
          <Route path="/dashboard" exact component={Index} />
          {/* user */}
          <Route
            path="/dashboard/app/user-profile/:id"
            exact
            component={UserDetails}
          />
          <Route
            path="/dashboard/app/reported-user-profile/:id"
            exact
            component={ReportedUserProfile}
          />
          <Route
            path="/dashboard/app/user-all-post/:id"
            exact
            component={UserAllPost}
          />
          <Route path="/dashboard/app/user-add" exact component={UserAdd} />
          <Route path="/dashboard/app/user-list" exact component={UserList} />
          <Route
            path="/dashboard/app/user-banned"
            exact
            component={UserBanned}
          />
          <Route
            path="/dashboard/app/user-reported"
            exact
            component={UserReported}
          />
          <Route path="/dashboard/app/user-dummy" exact component={UserDummy} />
          <Route
            path="/dashboard/app/dummy-user-profile/:id"
            exact
            component={DummyUserDetails}
          />
          <Route
            path="/dashboard/app/user-deleted"
            exact
            component={DeletedUserList}
          />
          <Route
            path="/dashboard/app/user-privacy-setting"
            exact
            component={userProfileEdit}
          />
          {/* Referal */}
          <Route
            path="/dashboard/app/gen-referal"
            exact
            component={ReferalGen}
          />
          <Route
            path="/dashboard/app/referal-list"
            exact
            component={ReferalAll}
          />
          <Route
            path="/dashboard/app/referal-user/:code"
            exact
            component={ReferalByUser}
          />
          {/* Post */}
          {/* ReportedPostList */}
          <Route
            path="/dashboard/app/post-reported"
            exact
            component={ReportedPostList}
          />
          <Route
            path="/dashboard/app/post-reported-details/:id"
            exact
            component={ReportedPostProfile}
          />
          <Route
            path="/dashboard/app/user-reported-details/:id"
            exact
            component={UserPostProfile}
          />
          <Route
            path="/dashboard/app/comments-reported"
            exact
            component={ReportedComments}
          />
          <Route
            path="/dashboard/app/comments-reported-details/:id"
            exact
            component={ReportedCommentsDetails}
          />
          <Route
            path="/dashboard/app/post-details"
            exact
            component={PostList}
          />
          <Route
            path="/dashboard/app/post-details/:id"
            exact
            component={PostDetails}
          />

          {/* Admins */}
          <Route path="/dashboard/app/add-admins" exact component={AddAdmins} />
          <Route
            path="/dashboard/app/admins-list"
            exact
            component={AdminsList}
          />
          {/* Tournaments */}

          <Route
            path="/dashboard/app/add-tournaments"
            exact
            component={TournamentForm}
          />
          <Route
            path="/dashboard/app/tournaments-list"
            exact
            component={TournamentsList}
          />
          <Route
            path="/dashboard/app/tournaments-details/:id"
            exact
            component={TournamentsDetails}
          />
          <Route
            path="/dashboard/app/tournaments-update/:id"
            exact
            component={UpdateTournamentsNew}
          />
          <Route
            path="/dashboard/app/tournament/getResult/:tournament_id"
            exact
            component={Result}
          />
          <Route
            path="/dashboard/app/tournament/declareWinner/:tournament_id"
            exact
            component={DeclareWinner}
          />

          {/* Games Routes  */}
          <Route
            path="/dashboard/app/games/createGame"
            exact
            component={createGame}
          />

          <Route path="/dashboard/app/games-list" exact component={GamesList} />

          <Route
            path="/dashboard/app/games/:id"
            exact
            component={GamesDetails}
          />

          <Route
            path="/dashboard/app/updateGames/:id"
            exact
            component={GamesUpdate}
          />

          {/* Rewards routes */}

          <Route
            path="/dashboard/app/rewards/createRewards"
            exact
            component={createRewards}
          />




          {/* Send msg */}
          <Route path="/dashboard/app/send-msg" exact component={SendMsg} />
          {/* widget */}
          <Route
            path="/dashboard/widget/widgetbasic"
            exact
            component={Widgetbasic}
          />
          <Route
            path="/dashboard/widget/widgetcard"
            exact
            component={Widgetcard}
          />
          <Route
            path="/dashboard/widget/widgetchart"
            exact
            component={Widgetchart}
          />
          {/* icon */}
          <Route path="/dashboard/icon/solid" exact component={Solid} />
          <Route path="/dashboard/icon/outline" exact component={Outline} />
          <Route path="/dashboard/icon/dual-tone" exact component={DualTone} />
          {/* From */}
          <Route
            path="/dashboard/form/form-element"
            exact
            component={FormElement}
          />
          <Route
            path="/dashboard/form/form-validation"
            exact
            component={FormValidation}
          />
          <Route
            path="/dashboard/form/form-wizard"
            exact
            component={FormWizard}
          />
          {/* table */}
          <Route
            path="/dashboard/table/bootstrap-table"
            exact
            component={BootstrapTable}
          />
          <Route
            path="/dashboard/table/table-data"
            exact
            component={TableData}
          />
          {/*special pages */}
          <Route
            path="/dashboard/special-pages/billing"
            exact
            component={Billing}
          />
          <Route
            path="/dashboard/special-pages/kanban"
            exact
            component={Kanban}
          />
          <Route
            path="/dashboard/special-pages/pricing"
            exact
            component={Pricing}
          />
          <Route
            path="/dashboard/special-pages/timeline"
            exact
            component={Timeline}
          />
          <Route
            path="/dashboard/special-pages/calender"
            exact
            component={Calender}
          />
          {/* map */}
          <Route path="/dashboard/map/vector" exact component={Vector} />
          <Route path="/dashboard/map/google" exact component={Google} />
          {/* extra */}
          <Route
            path="/dashboard/extra/privacy-policy"
            exact
            component={PrivacyPolicy}
          />
          <Route
            path="/dashboard/extra/terms-of-service"
            exact
            component={TermsofService}
          />
          {/*admin*/}
          <Route path="/dashboard/admin/admin" exact component={Admin} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default DefaultRouter;
