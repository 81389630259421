import React, { useEffect, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Card from "../../../components/Card";
import { HiOutlineBan } from "react-icons/hi";
import { AiOutlineEye } from "react-icons/ai";
import { BsCircle } from "react-icons/bs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  getusers,
  banUser,
  deleteUser,
  unbanUser,
} from "../../../Hooks/userHooks";

import ReactPaginate from "react-paginate";
import "./css/pagination.css";
import useFullPageLoader from "../../../Hooks/useFullPageLoader";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const UserBanned = () => {
  const initData = Object.freeze([
    {
      _id: "loading...",
      profilePic: "loading...",
      name: "loading...",
      phone: "loading...",
      email: "loading...",
      createdAt: "loading...",
      role: "loading...",
      active: true,
      delete: "",
    },
  ]);
  const [item, setItem] = useState(initData);
  let history = useHistory();

  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [userCount, setUserCount] = useState();
  const [pageCounter, setPageCounter] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [searchUser, setSearchUser] = useState("");

  const fetchUsers = () => {
    showLoader();
    getusers(currentPage).then((res) => {
      if (res.success === false) {
        setUserCount("Error: " + res.message);
        hideLoader();
      } else {
        setItem(res.users);
        setPageCount(res.pages);
        setUserCount("Banned User Count: " + res.users.length);
        hideLoader();
      }
    });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    fetchUsers();
  };

  const banUserById = async (id) => {
    banUser(id).then((res) => {
      fetchUsers();
    });
  };

  const nav = (id) => {
    history.push(`/dashboard/app/user-profile/${id}`);
  };

  const unbanUserById = async (id) => {
    unbanUser(id).then((res) => {
      fetchUsers();
    });
  };

  const delUser = async (id) => {
    deleteUser(id).then((res) => {
      alert(res.message);
      fetchUsers();
    });
  };

  const handleSearch = (e) => {
    setSearchUser(e.target.value);
  };

  const filteredItems = item.filter((item) => {
    return (
      item.active === true &&
      (item.name.toLowerCase().includes(searchUser.toLowerCase()) ||
        item.email.toLowerCase().includes(searchUser.toLowerCase()))
    );
  });

  return (
    <>
      <div>
        <Row>
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Banned Users</h4>
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="inputGroup-sizing-sm"
                    aria-label="Search banned users"
                    aria-describedby="button-addon2"
                    name="search"
                    style={{
                      height: "35px",
                      width: "500px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid grey",
                    }}
                    placeholder="Search banned users..."
                    value={searchUser}
                    onChange={handleSearch}
                  />
                </div>
                <div>
                  <h4 className="card-title">{userCount}</h4>
                  <span style={{ marginTop: "1rem", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={fetchUsers}
                    >
                      Reload
                    </button>
                  </span>
                </div>
              </Card.Header>
              <Card.Body className="px-0">
                <div className="table-responsive">
                  <table
                    id="user-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="ligth">
                        <th>Profile</th>
                        <th>Name</th>
                        <th>Contact</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Deleted</th>
                        <th>Status</th>
                        <th>Join Date</th>
                        <th>Join Time</th>
                        <th min-width="100px">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredItems.map((item, idx) => (
                        <tr key={idx}>
                          <td className="text-center">
                            <Image
                              className="bg-soft-primary rounded img-fluid avatar-40 me-3"
                              src={item.profilePic}
                              alt="profile"
                            />
                          </td>
                          <td
                            style={{ color: "orange", cursor: "pointer" }}
                            onClick={() => nav(item._id)}
                          >
                            {item.name}
                          </td>
                          <td>{item.phone}</td>
                          <td>{item.email}</td>
                          <td>{item.role}</td>
                          <td style={{ textAlign: "center" }}>
                            {item.delete ? <p>Deleted</p> : <p>No</p>}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.active ? (
                              <p>Active</p>
                            ) : item.delete ? (
                              <p>Deleted</p>
                            ) : (
                              "Banned"
                            )}
                          </td>
                          <td>{new String(item.createdAt).split("T")[0]}</td>
                          <td>
                            {new String(item.createdAt)
                              .split("T")
                              .pop()
                              .split(".")[0]}
                          </td>
                          <td>
                            <div className="flex align-items-center list-user-action">
                              {item.active ? (
                                <Link
                                  className="btn btn-sm btn-icon btn-warning mx-1"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title=""
                                  data-original-title="Add"
                                  to="#"
                                  onClick={() => banUserById(item._id)}
                                >
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="tooltip-disabled">
                                        Ban
                                      </Tooltip>
                                    }
                                  >
                                    <span className="btn-inner">
                                      <HiOutlineBan size={23} />
                                    </span>
                                  </OverlayTrigger>
                                </Link>
                              ) : (
                                <Link
                                  className="btn btn-sm btn-icon btn-info mx-1"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title=""
                                  data-original-title="Add"
                                  to="#"
                                  onClick={() => unbanUserById(item._id)}
                                >
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="tooltip-disabled">
                                        Unban
                                      </Tooltip>
                                    }
                                  >
                                    <span className="btn-inner">
                                      <BsCircle size={23} />
                                    </span>
                                  </OverlayTrigger>
                                </Link>
                              )}
                              <Link
                                className="btn btn-sm btn-icon btn-success"
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Edit"
                                to="#"
                                onClick={() => nav(item._id)}
                              >
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      View More
                                    </Tooltip>
                                  }
                                >
                                  <span className="btn-inner">
                                    <AiOutlineEye size={23} />
                                  </span>
                                </OverlayTrigger>
                              </Link>{" "}
                              <Link
                                className="btn btn-sm btn-icon btn-danger"
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Delete"
                                to="#"
                                onClick={() => delUser(item._id)}
                              >
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      Delete
                                    </Tooltip>
                                  }
                                >
                                  <span className="btn-inner">
                                    <svg
                                      width="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      stroke="currentColor"
                                    >
                                      <path
                                        d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M20.708 6.23975H3.75"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                    </svg>
                                  </span>
                                </OverlayTrigger>
                              </Link>{" "}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "8px",
                  }}
                >
                  <Stack
                    spacing={2}
                    sx={{ "& .MuiPaginationItem-root": { borderRadius: 0 } }}
                  >
                    {currentPage !== 1 ? (
                      <Pagination
                        count={pageCount}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="grey"
                        size="large"
                        showFirstButton
                        showLastButton
                      />
                    ) : (
                      <Pagination
                        count={pageCount}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="grey"
                        size="large"
                        hidePrevButton
                        hideNextButton
                      />
                    )}
                  </Stack>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      {loader}
    </>
  );
};

export default UserBanned;
