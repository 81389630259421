const Tournament = ({ data }) => {
  const convertTime = (timeString) => {
    const date = new Date(timeString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleString("en-US", options);
  };
  return (
    <>
      <div style={{ marginTop: "10px" }}>
        <h2>Organiser</h2>
      </div>
      <table
        style={{
          width: " 100%",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
              }}
            >
              Name
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
              }}
            >
              Email
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
              }}
            >
              PhoneNumber
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
              }}
            >
              Discord Link
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
              }}
            >
              Whatsapp Link
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
              }}
            >
              {data.organiser.name}
            </td>
            <td
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
              }}
            >
              {data.organiser.email}
            </td>
            <td
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
              }}
            >
              {data.organiser.mobile}
            </td>
            <td
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
              }}
            >
              {data.organiser.discord}
            </td>
            <td
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
              }}
            >
              {data.organiser.whatsapp}
            </td>
          </tr>
        </tbody>
      </table>

      <div style={{ marginTop: "10px" }}>
        <h2>Event Details</h2>
      </div>
      <table
        style={{
          width: " 100%",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
              }}
            >
              Event Name
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
              }}
            >
              Total Slots
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
              }}
            >
              Slots Filled
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
              }}
            >
              Registration Open
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
              }}
            >
              Registration Close
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
              }}
            >
              {" "}
              {data.eventName}
            </td>
            <td
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
              }}
            >
              {data.totalSlots}
            </td>
            <td
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
              }}
            >
              {data.slotsFilled}
            </td>
            <td
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
              }}
            >
              {convertTime(data.registration.open)}
            </td>
            <td
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
              }}
            >
              {convertTime(data.registration.close)}
            </td>
          </tr>
        </tbody>
      </table>
      {/* Tournament Info */}
      <div
        style={{
          marginTop: "10px",
        }}
      >
        <h2>Tournament Info</h2>
      </div>
      <table
        style={{
          width: " 100%",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
              }}
            >
              Type
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
              }}
            >
              Is Open
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
              }}
            >
              Nature
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
              }}
            >
              Start Time
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
              }}
            >
              Max Players
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
              }}
            >
              {data.type}
            </td>
            <td
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
              }}
            >
              {data.isOpen.toString()}
            </td>
            <td
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
              }}
            >
              {" "}
              {data.nature}
            </td>
            <td
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
              }}
            >
              {convertTime(data.startTime)}
            </td>
            <td
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
              }}
            >
              {data.maxPlayers}
            </td>
          </tr>
        </tbody>
      </table>

      {/* Additional Details */}
      <div style={{ marginTop: "10px" }}>
        <h2>Additional Details</h2>
      </div>

      <table
        style={{
          width: " 100%",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
              }}
            >
              Entry Fee
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
              }}
            >
              Number Of Matches
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
              }}
            >
              Tournament Created At
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
              }}
            >
              Tournament Updated At
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
              }}
            >
              {data.entryFee}
            </td>
            <td
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
              }}
            >
              {data.numberOfMatches}
            </td>
            <td
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
              }}
            >
              {convertTime(data.createdAt)}
            </td>
            <td
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
              }}
            >
              {convertTime(data.updatedAt)}
            </td>
          </tr>
        </tbody>
      </table>

      <div style={{ marginTop: "10px" }}>
        <div>
          <h2>Game Details</h2>
        </div>
        <div>
          <h3>Game Name: {data.gameId.gameName}</h3>
        </div>
        <table
          style={{
            width: " 100%",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "left",
                  backgroundColor: "#f2f2f2",
                }}
              >
                Short Name
              </th>
              <th
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "left",
                  backgroundColor: "#f2f2f2",
                }}
              >
                Platform
              </th>
              <th
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "left",
                  backgroundColor: "#f2f2f2",
                }}
              >
                {" "}
                Description
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                {" "}
                {data.gameId.short_name}
              </td>
              <td
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                {" "}
                {data.gameId.platform}
              </td>
              <td
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                {" "}
                {data.gameId.descriptions}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div style={{ marginTop: "10px" }}>
        <h2>Prize Pool Details</h2>
      </div>
      <table
        style={{
          width: " 100%",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
              }}
            >
              {" "}
              Total Amount
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
              }}
            >
              {" "}
              Prize Type
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
              }}
            >
              {" "}
              Currency
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
              }}
            >
              {data.prizePool.amount}
            </td>

            <td
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
              }}
            >
              {" "}
              {data.prizePool.prizeType}
            </td>
            <td
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
              }}
            >
              {" "}
              {data.prizePool.currency}
            </td>
          </tr>
        </tbody>
      </table>

      <div style={{ marginTop: "10px" }}>
        <h2>Individual Prizes</h2>
      </div>
      <table
        style={{
          width: " 100%",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
              }}
            >
              Prize Name
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
              }}
            >
              Rank
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
              }}
            >
              Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {data.prizes.map((prize) => (
            <tr key={prize._id}>
              <th
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "left",
                  backgroundColor: "#f2f2f2",
                }}
              >
                {prize.prize_name}
              </th>
              <th
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "left",
                  backgroundColor: "#f2f2f2",
                }}
              >
                {prize.rank}
              </th>
              <th
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "left",
                  backgroundColor: "#f2f2f2",
                }}
              >
                {prize.amount}
              </th>
            </tr>
          ))}
        </tbody>
      </table>

      <div style={{ marginTop: "10px" }}>
        <div>
          <h2>Team Details</h2>
        </div>

        {data.teams?.teams?.length > 0 ? (
          <table
            style={{
              width: " 100%",
              borderCollapse: "collapse",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "left",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  S.NO.
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "left",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Team Name
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "left",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Leader Name
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "left",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Team Player Names
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "left",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Emails
                </th>
              </tr>
            </thead>
            <tbody>
              {data.teams?.teams?.map((team, index) => (
                <tr key={team._id}>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      textAlign: "left",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      textAlign: "left",
                    }}
                  >
                    {" "}
                    {team.teamId.teamName}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      textAlign: "left",
                    }}
                  >
                    {" "}
                    {team.teamId.leaderGameName}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      textAlign: "left",
                    }}
                  >
                    {team.teamId.players.map((player) => (
                      <div key={player._id} style={{ marginLeft: "10px" }}>
                        {player.userId.username}
                      </div>
                    ))}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      textAlign: "left",
                    }}
                  >
                    {team.teamId.players.map((player) => (
                      <div key={player._id} style={{ marginLeft: "10px" }}>
                        {player.userId.email}
                      </div>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div style={{ color: "red" }}>
            <h4>No teams registered yet</h4>
          </div>
        )}
      </div>

      <div style={{ marginTop: "10px" }}>
        <div>TOC</div>
        <div>
          <div dangerouslySetInnerHTML={{ __html: data.toc }} />
        </div>
      </div>
    </>
  );
};

export default Tournament;
