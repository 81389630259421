// auth.js (Custom Hook)

import { useState, useEffect } from 'react';

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(true); // Assuming the user is authenticated initially
  const token = localStorage.getItem('token'); // You can modify this based on your token storage mechanism

  // Function to check token expiration
  const checkTokenExpiration = () => {
    const token = localStorage.getItem('token'); // Retrieve the token from storage
    if (!token) {
      // Token is missing, consider it expired
      logout();
      return;
    }

    try {
      // Decode the token payload to get the expiration timestamp
      const tokenPayload = JSON.parse(
        window.atob(token.split('.')[1])
          .replace(/-/g, '+')
          .replace(/_/g, '/')
      );
      const expirationTime = tokenPayload.exp * 1000; // Convert to milliseconds

      // Compare the current time with the token's expiration time
      const currentTime = Date.now();
      if (currentTime >= expirationTime) {
        // Token has expired
        logout();
      }
    } catch (error) {
      // If there's an error decoding the token or checking expiration, treat it as expired
      logout();
    }
  };

  // Function to handle logout
  const logout = () => {
    // Implement the logout logic here
    localStorage.removeItem('token'); // Clear the token from storage
    setIsAuthenticated(false); // Update the authentication state
    // Perform any other cleanup, e.g., reset user data, redirect to login page, etc.
  };

  useEffect(() => {
    // Check token expiration on component mount
    checkTokenExpiration();

    // Setup a timer to periodically check token expiration
    const tokenExpirationCheckInterval = setInterval(checkTokenExpiration, 60000); // Check every minute

    // Clear the interval when the component unmounts
    return () => clearInterval(tokenExpirationCheckInterval);
  }, [token]);

  // Add a global error handler to detect the 500 error and log out the user
  useEffect(() => {
    const handleServerError = (error) => {
      // Check if the error is a 500 Internal Server Error
      if (error?.response?.status === 500) {
        logout();
      }
    };

    // Attach the global error handler
    window.addEventListener('error', handleServerError);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('error', handleServerError);
    };
  }, []);

  return isAuthenticated;
};

export default useAuth;
