import React, { useState, useEffect } from "react";
import FsLightbox from "fslightbox-react";
import { Row, Col, Image, Form, Nav, Dropdown, Tab } from "react-bootstrap";
import Card from "../../../components/Card";
import { Link, useParams, useHistory } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";

// img

import avatars2 from "../../../assets/images/avatars/02.png";
import icon1 from "../../../assets/images/icons/01.png";
import icon2 from "../../../assets/images/icons/02.png";
import icon4 from "../../../assets/images/icons/04.png";
import icon8 from "../../../assets/images/icons/08.png";
import icon5 from "../../../assets/images/icons/05.png";
import shap2 from "../../../assets/images/shapes/02.png";
import shap4 from "../../../assets/images/shapes/04.png";
import shap6 from "../../../assets/images/shapes/06.png";
import { fetchAdminPostDetails, deletePost } from "../../../Hooks/postHooks";

const PostDetails = () => {
  const [toggler, setToggler] = useState(false);
  const initData = Object.freeze({
    caption: "",
    comments: [],
    createdAt: "",
    isPrivate: "",
    likes: "",
    media: "",
    mediaType: "",
    taggedUsers: "",
    tags: "",
    userId: "",
    views: "",
    visibility: "",
  });
  let history = useHistory();

  const [post, setPost] = useState(initData);
  const [comments, setComments] = useState([]);
  const [tags, setTags] = useState([]);
  const [media, setMedia] = useState([]);
  const [taggedUsers, setTaggedUsers] = useState([]);

  const [nullPost, setNullPost] = useState("Reported Post");
  const { id } = useParams();

  useEffect(() => {
    fetchAdminPostDetails(id).then((res) => {
      console.log("raoshab", res);
      if (res.data === null) {
        setNullPost("No data found");
        setComments([]); // Initialize comments as an empty array when the data is null
        setTags([]);
        setMedia([]);
        setTaggedUsers([]);
      } else {
        setPost(res.data);
        setComments(res.data.comments || []); // Use the comments data or initialize as empty array
        setTags(res.data.tags || []);
        setMedia(res.data.media || []);
        setTaggedUsers(res.data.taggedUsers || []);
      }
    });
  }, [id]);

  const delPost = (Id) => {
    deletePost(Id).then(history.push("/dashboard/app/post-details"));
  };
  return (
    <>
      <Tab.Container defaultActiveKey="first">
        <Row>
          <Col lg="9" className="col-lg-9">
            <Card>
              <Card.Header>
                <div className="header-title text-center">
                  <h4 className="card-title">About</h4>
                  <div className="media-support-user-img mb-3">
                    <Image
                      className="rounded-pill img-fluid avatar-80 bg-soft-primary p-2"
                      src={post.userId.profilePic}
                      alt="userimg"
                    />
                  </div>
                </div>
              </Card.Header>
              <Card.Body>
                <p>Name: {post.userId.name}</p>
                <p>User Name: {post.userId.username}</p>
                <div className="mb-1">
                  Email:{" "}
                  <Link to="#" className="">
                    {post.userId.email}
                  </Link>
                </div>
                Tags:
                <Row>
                  {tags.map((tag) => (
                    <Col xs="6" key={tag._id}>
                      <p>{tag}</p>
                    </Col>
                  ))}
                </Row>
                <p>Views: {post.views.length === 0 ? 0 : post.views.length}</p>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="3" className="col-lg-3">
            <Card>
              <Card.Header>
                <div className="header-title">
                  <h4 className="card-title">Tagged Users</h4>
                  {post.taggedUsers.length}
                </div>
              </Card.Header>
              <Card.Body>
                <ul className="list-inline m-0 p-0">
                  {taggedUsers.map((user) => (
                    <li className="d-flex mb-2" key={user._id}>
                      {" "}
                      {/* Replace 'user._id' with the actual unique identifier */}
                      {user.taggedUsers}
                    </li>
                  ))}
                </ul>
              </Card.Body>
            </Card>
          </Col>
          {/* Other components can be added here */}
        </Row>

        <Row>
          <Card>
            <Card.Header className="d-flex align-items-center justify-content-between pb-4">
              <div className="header-title">
                <div className="d-flex flex-wrap">
                  <div className="media-support-user-img me-3">
                    <Image
                      className="rounded-pill img-fluid avatar-60 bg-soft-danger p-1 ps-2"
                      src={avatars2}
                      alt=""
                    />
                  </div>
                  <div className="media-support-info mt-2">
                    <h5 className="mb-0">{post.caption}</h5>
                    <p className="mb-0 text-primary">
                      {new Date(post.createdAt)
                        .getDate()
                        .toString()
                        .padStart(2, "0")}
                      -
                      {(new Date(post.createdAt).getMonth() + 1)
                        .toString()
                        .padStart(2, "0")}
                      -{new Date(post.createdAt).getFullYear()}
                    </p>
                    {post.isPrivate === false && <p>Public</p>}
                    {post.isPrivate === true && <p>Private</p>}
                  </div>
                </div>
              </div>
              {/* <p
                onClick={() => delPost(post._id)}
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "#EA6A12",
                }}
              >
                Delete
              </p> */}
            </Card.Header>
            <Card.Body className="p-0">
              <div className="user-post">
                {post.mediaType === "text" && (
                  <p className="text-center" style={{ color: "#EA6A12" }}>
                    Text Post
                  </p>
                )}
                {post.mediaType === "media" &&
                  media.map((d) => (
                    <Image src={d.url} alt="post-image" className="img-fluid" />
                  ))}
              </div>
              <div className="comment-area p-3">
                <div className="d-flex flex-wrap justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center message-icon me-3">
                      <svg width="20" height="20" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z"
                        />
                      </svg>
                      <span className="ms-1">{post.likes.length}</span>
                    </div>
                    <div className="d-flex align-items-center feather-icon">
                      <svg width="20" height="20" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M10,16V19.08L13.08,16H20V4H4V16H10Z"
                        />
                      </svg>
                      {/* <span className="ms-1">{post.comments.length}</span> */}
                    </div>
                    <div className="d-flex align-items-center feather-icon">
                      <AiOutlineEye size={20} />
                      <span className="ms-1">{post.views}</span>
                    </div>
                  </div>
                  <div className="share-block d-flex align-items-center feather-icon">
                    Visibility: {post.visibility}
                  </div>
                </div>
                <hr />
                <div className="overflow-auto" style={{ maxHeight: "300px" }}>
                  <p>Comments</p>
                  <ul
                    className="list-unstyled p-0 m-0"
                    style={{ overflowWrap: "break-word" }}
                  >
                    {comments.map((comment, index) => (
                      <li className="mb-2" key={index}>
                        <span className="text-primary">{comment.comment}</span>
                        <br />
                        <p className="mb-0">
                          {new Date(comment.createdAt).toLocaleDateString()}{" "}
                          Time:{" "}
                          {new Date(comment.createdAt).toLocaleTimeString()}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Row>
      </Tab.Container>
      <FsLightbox
        toggler={toggler}
        sources={[
          icon4,
          shap2,
          icon8,
          shap4,
          icon2,
          shap6,
          icon5,
          shap4,
          icon1,
        ]}
      />
    </>
  );
};

export default PostDetails;
