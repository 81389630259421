import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Card from "../../../components/Card";
import { getApi } from "../../../backend";

const UserAdd = () => {
  const [count, setCount] = useState();
  const apiHit = async (e) => {
    e.preventDefault();
    let url = `${getApi()}/auth/dummy-users`;
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
        }`,
      },
      body: JSON.stringify({ count: count }),
    })
      .then((response) => response.json())
      .then((json) => {
        alert(json.message);
        // console.log(json);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  return (
    <>
      <div>
        <Row>
          <Col xl="9" lg="8">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Create Dummy User</h4>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="new-user-info">
                  <form>
                    <h5 className="mb-3">Number of user want to create</h5>
                    <p>Please enter number less then 10</p>
                    <div className="row">
                      <Form.Group className="col-md-6 form-group">
                        <Form.Control
                          type="number"
                          id="rpass"
                          placeholder="No.of user"
                          min="1"
                          max="10"
                          onChange={(e) => setCount(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                    <Button type="button" variant="btn btn-primary" onClick={apiHit}>
                      Add User
                    </Button>
                  </form>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UserAdd;
