import React, { useState, useEffect } from "react";
import { Toaster, toast } from "react-hot-toast";
import Card from "../../../components/Card";
import { Row, Col, Form, Button } from "react-bootstrap";
import "./css/MultipleStageModal.css";
import { tw } from "twind";
import { IoChevronBackCircleSharp } from "react-icons/io5";
import { RiCloseCircleFill } from "react-icons/ri";
import { addStageData } from "../../../Hooks/tournamentHooks";
// import { sassFalse } from "sass";

export default function Modal({
  stages,
  id,
  setModalOpen,
}) {
  const stagesValue = parseInt(stages);
  const [totalStages, setTotalStages] = useState(1);
  const [buttonName, setButtonName] = useState("Next");
  const [groupdata, setGroupdata] = useState([])
  
  const [validation, setValidation] = useState(false)
  const [review, setReview] = useState(false)
  const [form, setForm] = useState({
    roundName: "",
    roundNumber: 1,
    slots: 0,
    invited_teams: 0,
    match_time: "",
  })
  // console.log("Stages: ", totalStages, stagesValue);

  const SubmitStages = async () => {
    console.log("groupdata: ", groupdata)
    const res = await addStageData(groupdata, id)
    // console.log(groupdata+"added by madhav");
   
    if (res.success) {
      toast.success("Stages Submitted Successfully")
      setTimeout(() => {
        setModalOpen(false)
      }, 2000);
    }
    else {
      toast.error(res.message)
    }
  }


  useEffect(() => {
    if (totalStages === stagesValue) {
      setButtonName("Submit");
    }
    // console.log(setButtonName);
  }, [totalStages]);


  const handleChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  }

  const handleButton = async() => {

    if (form.roundName === "") {
      setValidation(true)
      return;
    }
    else if (form.slots === 0) {
      setValidation(true)
      return;
    }
    if (totalStages < stagesValue) {

      setGroupdata([...groupdata, form])
      setForm({
        roundName: "",
        roundNumber: totalStages + 1,
        slots: 0,
        invited_teams: 0,
        match_time: "",
      })
      setTotalStages(totalStages + 1);
      setValidation(false)

    } else if (totalStages === stagesValue) {
      // submitTournament();
      setGroupdata([...groupdata, form])
      setReview(true)
      SubmitStages();
    }
  };

  



  const reviewSubmit = (data) => {
    return (
      <div>
        <div className={tw("my-4")}>
          <p className={tw("text-lg")}>
            Review your changes before submitting
          </p>
          <span className={tw("text-red-400 text-sm")}>
            Once you submit, you will not be able to edit the tournament details.
          </span>

        </div>
        <main className={tw("grid grid-cols-2 place-items-center gap-5 my-5")}>
          {data.map((item, index) => {
            return (
              <div className={tw("w-[150px] h-[90px] bg-gray-100 rounded-md p-2")}>
                <p className={tw("font-semibold uppercase text-md text-blue-400")}>
                  {item.roundName}
                </p>
                <p>
                  Slots : <span className={tw("text-green-600 text-md font-semibold")}>
                    {item.slots}
                  </span>
                </p>
                {
                  item.invited_teams !== 0 && <p>  Invited Teams : <span className={tw("text-green-600 text-md font-semibold")}>
                    {item.invited_teams}
                  </span></p>
                }
                <p>
                  Match Start Time : <span className={tw("text-green-600 text-md font-semibold")}>
                    {item.match_time}
                  </span>
                </p>
              </div>
            )
          })}
        </main>

        <Button
          type="button"
          variant="btn btn-primary"
          onClick={SubmitStages}
          className={tw("float-right")}
        >
          Submit
        </Button>
      </div>
    )
  }


  const handleExit = () => {


    if (totalStages > 1) {
      if (review) {
        setReview(false)
        setGroupdata([])
        setForm({
          roundName: "",
          roundNumber: 1,
          slots: 0,
          invited_teams: 0,
          match_time: "",
        })
        setTotalStages(1)
        return;
      }
      setTotalStages(totalStages - 1);
      // if (totalStages < stagesValue) {
      // console.log("totalStages: ", totalStages)
      let newData = groupdata.filter((item, index) => item.roundNumber === totalStages - 1)
      let newArray = groupdata.filter((item, index) => item.roundNumber !== totalStages - 1)
      // console.log("newArray: ", newArray)
      // console.log("newData: ", newData)
      setGroupdata(newArray)
      setForm(newData[0])
      // }
    }
    else {
      setModalOpen(false)
    }


  }
  // console.log("form: ", form);
  // console.log("groupdata: ", groupdata);
  console.log("totalStages", totalStages)
  return (
    <>

      <div>
        {/* <Toaster /> */}
        <Row className="gopesh-modal" >
          <Col className="gopesh-overlay">
            <Card className={`${tw("w-full ml-25")} gopesh-modal-content`}>
              <Card.Body>
                <RiCloseCircleFill className={tw("w-10 h-10 text-red-700 cursor-pointer float-right")} onClick={() => {
                  setModalOpen(false)
                }} />
                <div className={tw("flex justify-start items-center space-x-6")}>
                  <IoChevronBackCircleSharp className={tw("w-10 h-10 text-red-700 cursor-pointer")} onClick={
                    () => {
                      handleExit()
                    }
                  } />
                  <p className={tw("text-xl text-black")}>
                    Enter Stage Details
                  </p>
                </div>
                {
                  review ? reviewSubmit(groupdata) : (<form action="">

                    <div className="gopesh-container">
                      <h2 className={tw("text-lg text-gray-400 my-4")}>Round Number:
                        <span className={tw("font-bold ml-5")}>
                          {totalStages}
                        </span>
                      </h2>
                      <div className="inputFields">
                        <Form.Group className="col-md-6 form-group gopesh ">
                          <Form.Label htmlFor="link">Round Name:</Form.Label>
                          {validation && !form.roundName && (
                            <p className={tw("text-red-500 text-sm py-1")}>
                              Round Name is required
                            </p>
                          )}
                          <Form.Control
                            type="text"
                            id="roundName"
                            onChange={handleChange}
                            placeholder="Round Name"
                            name="roundName"
                            value={form.roundName}
                            className={tw("w-full")}
                          />
                        </Form.Group>

                      </div>
                      <div className="inputFields">

                        <Form.Group className="col-md-6 form-group gopesh">
                          <Form.Label htmlFor="link">Slots:</Form.Label>
                          {validation && !form.slots && (
                            <p className={tw("text-red-500 text-sm py-1")}>
                              Slots is required
                            </p>
                          )}
                          <Form.Control
                            type="number"
                            min={0}
                            id="slots"
                            onChange={handleChange}
                            placeholder="Enter Slots"
                            name="slots"
                            className="w-100"
                            value={form.slots}
                          />
                        </Form.Group>
                      </div>
                      <div className="inputFields">

                        <Form.Group className="col-md-6 form-group gopesh">
                          <Form.Label htmlFor="link">
                            Invited Team Slots:
                          </Form.Label>
                          <Form.Control
                            type="number"
                            min={0}
                            defaultValue={0}
                            id="teamSlots"
                            onChange={handleChange}
                            placeholder="Enter Invited Team Slots"
                            name="invited_teams"
                            className="w-100"
                            value={form.invited_teams}
                          />
                        </Form.Group>
                      </div>
                      <div className="inputFields">

                        <Form.Group className="col-md-6 form-group gopesh">
                          <Form.Label htmlFor="link">
                            Match Time:
                          </Form.Label>
                          <Form.Control
                              type="datetime-local"
                              placeholder="Enter match time"
                              name="match_time"
                              value={form.match_time}
                              onChange={handleChange}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <Button
                      type="button"
                      variant="btn btn-primary"
                      onClick={handleButton}
                    >
                      {buttonName}
                    </Button>
                    {/* </div>
                </div> */}
                  </form>)
                }
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
