import React, { useState, useEffect } from "react";
import axios from "axios";
import { ErrorIcon, toast } from "react-hot-toast";
import { useParams, useHistory } from "react-router-dom";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { getApi } from "../../../backend";

function TournamentForm() {
  const history = useHistory();
  const [tournamentData, setTournamentData] = useState({
    organiserName: "",
    organiserEmail: "",
    organiserMobile: "",
    whatsappLink: "",
    discordLink: "",
    eventName: "",
    totalSlots: "",
    link: [""],
    registration_open: "",
    registration_close: "",
    type: "",
    isOpen: true,
    nature: "",
    toc: "",
    startTime: "",
    maxPlayers: "",
    entryFee: "",
    hidden: false,
    numberOfMatches: "",
    message: "",
    gameId: "",
    prizePoolAmount: "",
    prizePoolPrizeType: "",
    prizePoolCurrency: "INR",
    prizes: [
      {
        prize_name: "",
        rank: "",
        amount: "",
      },
    ],
  });

  const [formErrors, setFormErrors] = useState({});
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    // Clean up the object URL when the component unmounts or banner changes
    return () => {
      if (typeof tournamentData.cover === "object") {
        URL.revokeObjectURL(tournamentData.banner);
      }
      if (typeof tournamentData.tournamentData === "object") {
        URL.revokeObjectURL(tournamentData.tournamentData);
      }
    };
  }, [tournamentData.cover, tournamentData.largerBanner]);

  useEffect(() => {
    const currentHtml = convertToHTML(editorState.getCurrentContent());

    setTournamentData((prevData) => ({ ...prevData, toc: currentHtml }));
  }, [editorState]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleWheel = (event) => {
    event.target.blur();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setTournamentData((prevData) => ({ ...prevData, [name]: value }));

    if (name === "message") return;
    setFormErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      newErrors[name] = "";

      return newErrors;
    });
  };

  const handleArrayChange = (arrayName, index, value) => {
    const newArray = [...tournamentData[arrayName]];
    newArray[index] = value;

    setTournamentData({ ...tournamentData, [arrayName]: newArray });

    if (arrayName === "link") {
      return;
    }

    setFormErrors((prevErrors) => {
      const newErrors = { ...prevErrors };

      if (Array.isArray(newErrors[arrayName])) {
        newErrors[arrayName][index] = "";
      } else {
        newErrors[arrayName] = "";
      }

      return newErrors;
    });
  };

  const addArrayItem = (arrayName) => {
    setTournamentData({
      ...tournamentData,
      [arrayName]: [...tournamentData[arrayName], ""],
    });
  };

  const removeArrayItem = (arrayName, index) => {
    const newArray = [...tournamentData[arrayName]];
    newArray.splice(index, 1);
    setTournamentData({ ...tournamentData, [arrayName]: newArray });
  };

  const addPrizeItem = () => {
    setTournamentData({
      ...tournamentData,
      prizes: [
        ...tournamentData.prizes,
        { prize_name: "", rank: "", amount: "" },
      ],
    });
  };

  const removePrizeItem = (index) => {
    const newPrizes = [...tournamentData.prizes];
    newPrizes.splice(index, 1);
    setTournamentData({ ...tournamentData, prizes: newPrizes });
  };

  const handlePrizeChange = (index, field, value) => {
    const updatedPrizes = [...tournamentData.prizes];
    updatedPrizes[index] = { ...updatedPrizes[index], [field]: value };
    setTournamentData({ ...tournamentData, prizes: updatedPrizes });

    setFormErrors((prevErrors) => {
      const newErrors = { ...prevErrors };

      if (Array.isArray(newErrors.prizes) && newErrors.prizes[index]) {
        newErrors.prizes[index][field] = "";
      }

      return newErrors;
    });
  };

  const handleCover = (e) => {
    const { name } = e.target;
    setTournamentData({ ...tournamentData, [name]: e.target.files[0] });

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      cover: null,
    }));
  };

  const handleLargerBanner = (e) => {
    const { name } = e.target;
    setTournamentData({ ...tournamentData, [name]: e.target.files[0] });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      largerBanner: null,
    }));
  };
  const handleRemoveBanner = (name) => {
    setTournamentData({ ...tournamentData, [name]: "" });
  };

  const isValidImageType = (file) => {
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];

    return allowedTypes.includes(file.type);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validate(tournamentData);

    const formData = new FormData();

    Object.entries(tournamentData).forEach(([key, value]) => {
      if (!Array.isArray(value) && typeof value !== "object") {
        if (key === "message") {
          formData.append("message", " ");
        } else {
          formData.append(key, value);
        }
      }
    });

    tournamentData.link.forEach((link, index) => {
      formData.append(`link[${index}]`, link);
    });

    tournamentData.prizes.forEach((prize, index) => {
      Object.keys(prize).forEach((prizeKey) => {
        formData.append(`prizes[${index}][${prizeKey}]`, prize[prizeKey]);
      });
    });

    if (tournamentData.cover instanceof File) {
      formData.append("cover", tournamentData.cover);
    }
    if (tournamentData.largerBanner instanceof File) {
      formData.append("largerBanner", tournamentData.largerBanner);
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    } else {
      setFormErrors({});
      setIsSubmitting(true);

      try {
        let response = await axios({
          url: `${getApi()}/tournament/add`,
          method: "post",
          data: formData,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? JSON.parse(localStorage.getItem("token"))
                : ""
            }`,
          },
        });

        if (response.data.success) {
          toast.success(response.data.message);
          history.push("/dashboard/app/tournaments-list");
        }
      } catch (error) {
        const message = error.response?.data?.message;

        const i = message.indexOf('"');
        const j = message.lastIndexOf('"');

        const fieldName = message.slice(i + 1, j);

        const errors = {};

        errors[fieldName] = fieldName + message.slice(j + 1);
        setFormErrors(errors);
        toast.error(
          error.response?.data?.message
            ? error.response?.data?.message
            : error.message
        );
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;

    if (!values.organiserName.trim()) {
      errors.organiserName = "Organisername is required";
    }

    if (!values.organiserEmail.trim()) {
      errors.organiserEmail = "Organizer Email is required";
    } else if (!emailRegex.test(values.organiserEmail)) {
      errors.organiserEmail = "Invalid email format";
    }

    if (!values.eventName.trim()) {
      errors.eventName = "Event Name is required";
    }
    if (!values.totalSlots.trim()) {
      errors.totalSlots = "Total slots is required";
    }

    function validateRichTextContent(htmlContent) {
      // Remove HTML tags and trim the remaining string to check for meaningful content..
      const textContent = htmlContent.replace(/<[^>]*>/g, "").trim();

      // Check if the resulting string is empty
      if (textContent === "") {
        return false; // Indicates invalid or empty content
      }

      return true; // Indicates valid content
    }

    if (!validateRichTextContent(values.toc)) {
      errors.toc = "Toc is required";
    }

    if (!values.registration_open) {
      errors.registration_open = "Registration open time is required";
    }
    if (!values.registration_close) {
      errors.registration_close = "Registration close time is required";
    }
    if (!values.numberOfMatches.trim()) {
      errors.numberOfMatches = "Number of Matches is required";
    }
    if (!values.maxPlayers.trim()) {
      errors.maxPlayers = "Max players is required";
    } else if (values.maxPlayers > 5) {
      errors.maxPlayers = "Max players cannot be more than 5";
    }
    if (!values.entryFee.trim()) {
      errors.entryFee = "Entry fee is required";
    }
    if (!values.type.trim()) {
      errors.type = "Type is required";
    }
    if (!values.nature.trim()) {
      errors.nature = "Nature is required";
    }

    if (!values.prizePoolAmount.trim()) {
      errors.prizePoolAmount = "Prize pool amount is required";
    }

    if (!values.gameId.trim()) {
      errors.gameId = "Game is required";
    }

    if (!values.startTime.trim()) {
      errors.startTime = "Start time is required";
    }

    if (!values.prizePoolPrizeType) {
      errors.prizePoolPrizeType = "Prize Type is required";
    }

    if (values.prizePoolPrizeType === "currency") {
      if (!values.prizePoolCurrency.trim()) {
        errors.prizePoolCurrency = "Currency is required";
      }
    }

    if (!values.prizes || values.prizes.length === 0) {
      errors.prizes = "At least one prize is required";
    } else {
      // Additional validation for each prize in the array
      values.prizes.forEach((prize, index) => {
        const prizeErrors = {};

        if (!prize.prize_name.trim()) {
          prizeErrors.prize_name = `Prize name is required`;
        }

        if (!prize.rank.trim()) {
          prizeErrors.rank = `Rank is required`;
        }

        if (!prize.amount.trim()) {
          prizeErrors.amount = `Prize amount is required`;
        }
        if (Object.keys(prizeErrors).length > 0) {
          errors.prizes = errors.prizes || [];
          errors.prizes[index] = prizeErrors;
        }
      });
    }

    if (!values.cover || !(values.cover instanceof File)) {
      errors.cover = "Cover image is required";
    } else if (!isValidImageType(values.cover)) {
      errors.cover = "Invalid image format. Only Jpg, png, gif accepted";
    }

    if (!values.largerBanner || !(values.largerBanner instanceof File)) {
      errors.largerBanner = "Larger banner image is required";
    } else if (!isValidImageType(values.largerBanner)) {
      errors.largerBanner = "Invalid image format. Only Jpg, png, gif accepted";
    }
    return errors;
  };
  const { id } = useParams();

  const [games, setGames] = useState([]);

  const getAllGames = async () => {
    try {
      let allGames = await axios({
        url: `${getApi()}/games`,
        method: "get",
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token")
              ? JSON.parse(localStorage.getItem("token"))
              : ""
          }`,
        },
      });

      setGames(allGames?.data?.data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  React.useEffect(() => {
    getAllGames();
  }, []);

  return (
    <>
      <div className="bg-white rounded-md relative z-10">
        <div className="pt-6 px-6 text-2xl font-bold text-black">
          <h4>Add Tournament</h4>
        </div>

        <form onSubmit={handleSubmit} className="p-5 flex flex-wrap">
          {/* Organizer Fields */}
          <h1 className="w-full text-xl  font-bold mb-4 ">
            Organizer's Details
          </h1>

          <div className="w-full md:w-1/2 px-3 mb-4">
            <label
              htmlFor="organiserName"
              className="text-gray-700 text-md  mb-2 block"
            >
              Organizer Name <span className="text-red-700">*</span>:
            </label>
            <input
              name="organiserName"
              id="organiserName"
              type="text"
              value={tournamentData.organiserName}
              onChange={handleInputChange}
              placeholder="Organizer Name"
              className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm py-3 px-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
            {formErrors.organiserName && (
              <p className="text-red-500 text-sm">{formErrors.organiserName}</p>
            )}
          </div>

          <div className="w-full md:w-1/2 px-3 mb-4">
            <label
              htmlFor="organiserMobile"
              className="text-gray-700 text-md mb-2 block"
            >
              Organizer Mobile :
            </label>
            <input
              name="organiserMobile"
              id="organiserMobile"
              type="number"
              onWheel={handleWheel}
              value={tournamentData.organiserMobile}
              onChange={handleInputChange}
              placeholder="Organizer Mobile"
              className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm py-3 px-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
            {formErrors.organiserMobile && (
              <p className="text-red-500 text-sm">
                {formErrors.organiserMobile}
              </p>
            )}
          </div>

          <div className="w-full md:w-1/2 px-3 mb-4">
            <label
              htmlFor="organiserEmail"
              className="text-gray-700 text-md mb-2 block"
            >
              Organizer Email <span className="text-red-700">*</span> :
            </label>
            <input
              name="organiserEmail"
              id="organiserEmail"
              type="email"
              value={tournamentData.organiserEmail}
              onChange={handleInputChange}
              placeholder="Organizer Email"
              className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm py-3 px-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
            {formErrors.organiserEmail && (
              <p className="text-red-500 text-sm">
                {formErrors.organiserEmail}
              </p>
            )}
          </div>

          <div className="w-full md:w-1/2 px-3 mb-4">
            <label
              htmlFor="discordLink"
              className="text-gray-700 text-md mb-2 block"
            >
              Organizer Discord :
            </label>
            <input
              name="discordLink"
              id="discordLink"
              type="text"
              value={tournamentData.discordLink}
              onChange={handleInputChange}
              placeholder="Organizer Discord"
              className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm py-3 px-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
            {formErrors.discordLink && (
              <p className="text-red-500 text-sm">{formErrors.discordLink}</p>
            )}
          </div>

          <div className="w-full md:w-1/2 px-3 mb-4">
            <label
              htmlFor="whatsappLink"
              className="text-gray-700 text-md mb-2 block"
            >
              Organizer Whatsapp Link :
            </label>
            <input
              name="whatsappLink"
              id="whatsappLink"
              type="text"
              value={tournamentData.whatsappLink}
              onChange={handleInputChange}
              placeholder="Organizer Whatsapp"
              className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm py-3 px-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
            {formErrors.whatsappLink && (
              <p className="text-red-500 text-sm">{formErrors.whatsappLink}</p>
            )}
          </div>

          <h1 className="w-full text-xl font-bold mb-4 ">Event Details</h1>

          <div className="w-full md:w-1/2 px-3 mb-4">
            <label
              htmlFor="eventName"
              className="text-gray-700 text-md mb-2 block"
            >
              Event Name <span className="text-red-700">*</span>:
            </label>
            <input
              name="eventName"
              id="eventName"
              type="text"
              value={tournamentData.eventName}
              onChange={handleInputChange}
              placeholder="Event Name"
              className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm py-3 px-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
            {formErrors.eventName && (
              <p className="text-red-500 text-sm">{formErrors.eventName}</p>
            )}
          </div>

          <div className="w-full md:w-1/2 px-3 mb-4">
            <label
              htmlFor="totalSlots"
              className="text-gray-700 text-md mb-2 block"
            >
              Total Slots <span className="text-red-700">*</span>:
            </label>
            <input
              name="totalSlots"
              id="totalSlots"
              type="number"
              onWheel={handleWheel}
              value={tournamentData.totalSlots}
              onChange={handleInputChange}
              placeholder="Total Slots"
              className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm py-3 px-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
            {formErrors.totalSlots && (
              <p className="text-red-500 text-sm">{formErrors.totalSlots}</p>
            )}
          </div>

          <div className="w-1/2 px-3 mb-4">
            <label htmlFor="Link" className="text-gray-700 text-md mb-2 block">
              Link:
            </label>
            {/* Dynamic Array: Links */}
            {tournamentData.link.map((link, index) => (
              <div key={index} className="flex items-center space-x-2 mt-2">
                <input
                  type="text"
                  id="link"
                  value={link}
                  onChange={(e) =>
                    handleArrayChange("link", index, e.target.value)
                  }
                  placeholder="Link"
                  className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm p-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />

                <button
                  type="button"
                  onClick={() => removeArrayItem("link", index)}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold p-1 disabled:bg-red-200"
                  disabled={tournamentData.link.length === 1}
                >
                  <svg
                    width="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="currentColor"
                  >
                    <path
                      d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M20.708 6.23975H3.75"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </button>
              </div>
            ))}
            {formErrors.link && (
              <p className="text-red-500 text-sm">{formErrors.link}</p>
            )}

            <button
              type="button"
              onClick={() => addArrayItem("link")}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 rounded mt-4"
            >
              Add Link
            </button>
          </div>

          <div className="w-full md:w-1/2 px-3 mb-4">
            <label
              htmlFor="registrationOpen"
              className="text-gray-700 text-md mb-2 block"
            >
              Registration Open Date <span className="text-red-700">*</span>:
            </label>
            <input
              type="datetime-local"
              id="registrationOpen"
              name="registration_open"
              value={tournamentData.registration_open}
              onChange={handleInputChange}
              className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm py-3 px-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
            {formErrors.registration_open && (
              <p className="text-red-500 text-sm">
                {formErrors.registration_open}
              </p>
            )}
          </div>

          <div className="w-full md:w-1/2 px-3 mb-4">
            <label
              htmlFor="registrationClose"
              className="text-gray-700 text-md mb-2 block"
            >
              Registration Close Date <span className="text-red-700">*</span>:
            </label>
            <input
              type="datetime-local"
              id="registrationClose"
              name="registration_close"
              value={tournamentData.registration_close}
              onChange={handleInputChange}
              className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm py-3 px-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
            {formErrors.registration_close && (
              <p className="text-red-500 text-sm">
                {formErrors.registration_close}
              </p>
            )}
          </div>

          <div className="w-full md:w-1/2 px-3 mb-4">
            <label htmlFor="startTime" className="text-gray-700 text-md mb-2">
              Start Time <span className="text-red-700">*</span>:
            </label>
            <input
              type="datetime-local"
              name="startTime"
              id="startTime"
              value={tournamentData.startTime}
              onChange={handleInputChange}
              className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm p-1.5 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
            {formErrors.startTime && (
              <p className="text-red-500 text-sm">{formErrors.startTime}</p>
            )}
          </div>

          <div className="w-full md:w-1/2 px-3 mb-4">
            <label htmlFor="type" className="text-gray-700 text-md mb-2 block">
              Type <span className="text-red-700">*</span>:
            </label>
            <select
              name="type"
              id="type"
              value={tournamentData.type}
              onChange={handleInputChange}
              className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm p-1.5 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="">Select Type</option>
              <option value="solo">Solo</option>
              <option value="trio">Trio</option>
              <option value="squad">Squad</option>
              <option value="duo">Duo</option>
              <option value="other">Other</option>
            </select>
            {formErrors.type && (
              <p className="text-red-500 text-sm">{formErrors.type}</p>
            )}
          </div>

          <div className="w-full md:w-1/2 px-3 mb-4">
            <label className="text-gray-700 text-md mb-2 block">
              Nature <span className="text-red-700">*</span>:
            </label>
            <select
              name="nature"
              id="nature"
              value={tournamentData.nature}
              onChange={handleInputChange}
              className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm p-1.5 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="">Select Nature</option>
              <option value="knockout">Knockout</option>
              <option value="multiple">Multiple Stages</option>
              <option value="other">Other</option>
            </select>
            {formErrors.nature && (
              <p className="text-red-500 text-sm">{formErrors.nature}</p>
            )}
          </div>

          <div className="w-full md:w-1/2 px-3 mb-4">
            <label htmlFor="maxPlayers" className="text-gray-700 text-md mb-2">
              Max Players<span className="text-red-700">*</span>:
            </label>
            <input
              type="number"
              onWheel={handleWheel}
              name="maxPlayers"
              id="maxPlayers"
              value={tournamentData.maxPlayers}
              onChange={handleInputChange}
              placeholder="Max players"
              className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm p-1.5 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
            {formErrors.maxPlayers && (
              <p className="text-red-500 text-sm">{formErrors.maxPlayers}</p>
            )}
          </div>

          <div className="w-full md:w-1/2 px-3 mb-4">
            <label htmlFor="entryFee" className="text-gray-700 text-md mb-2">
              Entry Fee <span className="text-red-700">*</span>:
            </label>
            <input
              type="number"
              onWheel={handleWheel}
              name="entryFee"
              id="entryFee"
              value={tournamentData.entryFee}
              onChange={handleInputChange}
              placeholder="Entry fee"
              className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm p-1.5 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
            {formErrors.entryFee && (
              <p className="text-red-500 text-sm">{formErrors.entryFee}</p>
            )}
          </div>

          <div className="w-full md:w-1/2 px-3 mb-4">
            <label
              htmlFor="numberOfMatches"
              className="text-gray-700 text-md mb-2"
            >
              Number of Matches <span className="text-red-700">*</span>:
            </label>
            <input
              type="number"
              onWheel={handleWheel}
              name="numberOfMatches"
              id="numberOfMatches"
              value={tournamentData.numberOfMatches}
              onChange={handleInputChange}
              placeholder="No. of matches"
              className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm py-3 px-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
            {formErrors.numberOfMatches && (
              <p className="text-red-500 text-sm">
                {formErrors.numberOfMatches}
              </p>
            )}
          </div>

          <div className="w-full md:w-1/2 px-3 mb-4">
            <label htmlFor="gameId" className="text-gray-700 text-md mb-2">
              Game Selection <span className="text-red-700">*</span>:
            </label>

            <select
              name="gameId"
              id="gameId"
              value={tournamentData.gameId}
              onChange={handleInputChange}
              className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm p-1.5 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="">Select Game</option>
              {games.map((game, index) => (
                <option key={index} value={game._id}>
                  {game.gameName}
                </option>
              ))}
            </select>

            {formErrors.gameId && (
              <p className="text-red-500 text-sm">{formErrors.gameId}</p>
            )}
          </div>

          {/* Prize Pool */}
          <div className="w-full md:w-1/2 px-3 mb-4">
            <label
              htmlFor="prizePoolAmount"
              className="text-gray-700 text-md mb-2"
            >
              Prize Pool Amount <span className="text-red-700">*</span>:
            </label>
            <input
              type="number"
              onWheel={handleWheel}
              name="prizePoolAmount"
              id="prizePoolAmount"
              value={tournamentData.prizePoolAmount}
              onChange={handleInputChange}
              placeholder="prize pool amount"
              className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm py-3 px-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
            {formErrors.prizePoolAmount && (
              <p className="text-red-500 text-sm">
                {formErrors.prizePoolAmount}
              </p>
            )}
          </div>

          <div className="w-full md:w-1/2 px-3 mb-4">
            <label
              htmlFor="prizePoolPrizeType"
              className="text-gray-700 text-md mb-2"
            >
              Prize Type <span className="text-red-700">*</span>:
            </label>
            <select
              name="prizePoolPrizeType"
              id="prizePoolPrizeType"
              value={tournamentData.prizePoolPrizeType}
              onChange={handleInputChange}
              placeholder="Prize pool type"
              className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm p-1.5 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="">Select Prize Type</option>
              <option value="playCoins">Play Coins</option>
              <option value="currency">Currency</option>
            </select>
            {formErrors.prizePoolPrizeType && (
              <p className="text-red-500 text-sm">
                {formErrors.prizePoolPrizeType}
              </p>
            )}
          </div>

          <div className="w-full flex flex-col ">
            {tournamentData.prizePoolPrizeType === "currency" && (
              <div className="px-3 mb-4">
                <label
                  htmlFor="prizePoolCurrency"
                  className="text-gray-700 text-md mb-2"
                >
                  Currency <span className="text-red-700">*</span>:
                </label>
                <div className="w-1/2 pr-3">
                  <input
                    type="text"
                    name="prizePoolCurrency"
                    id="prizePoolCurrency"
                    value={tournamentData.prizePoolCurrency}
                    onChange={handleInputChange}
                    placeholder="Currency"
                    className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm p-1.5 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
                {formErrors.prizePoolCurrency && (
                  <p className="text-red-500 text-sm">
                    {formErrors.prizePoolCurrency}
                  </p>
                )}
              </div>
            )}
          </div>

          <div className="w-full md:w-1/2 px-3 mb-4">
            <label htmlFor="cover" className="text-gray-700 text-md mb-2">
              Cover Image <span className="text-red-700">*</span>
            </label>
            <input
              type="file"
              id="cover"
              size="60"
              name="cover"
              onChange={handleCover}
              placeholder="Banner URL"
              className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm  px-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
            {typeof tournamentData.cover === "object" && (
              <>
                <img
                  src={URL.createObjectURL(tournamentData.cover)}
                  alt="bannerImage"
                  className="w-[516px] h-[200px]"
                />
              </>
            )}
            {typeof tournamentData.cover === "string" &&
              tournamentData.cover && (
                <>
                  <img
                    src={tournamentData.cover}
                    className="w-[516px] h-[200px]"
                    alt="bannerImage"
                  />
                </>
              )}
            {tournamentData.cover && (
              <button
                onClick={() => handleRemoveBanner("cover")}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-4 rounded focus:outline-none focus:shadow-outline block"
              >
                Remove Cover Image
              </button>
            )}
            {formErrors.cover && (
              <p className="text-red-500 text-sm">{formErrors.cover}</p>
            )}
          </div>

          <div className="w-full md:w-1/2 px-3 mb-4">
            <label htmlFor="Banner" className="text-gray-700 text-md mb-2">
              Large Banner Image <span className="text-red-700">*</span>
            </label>
            <input
              type="file"
              id="largerBanner"
              size="60"
              name="largerBanner"
              onChange={handleLargerBanner}
              placeholder="Banner URL"
              className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm  px-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
            {typeof tournamentData.largerBanner === "object" && (
              <>
                <img
                  src={URL.createObjectURL(tournamentData.largerBanner)}
                  alt="largerBanner"
                  className="w-[516px] h-[200px]"
                />
              </>
            )}
            {typeof tournamentData.largerBanner === "string" &&
              tournamentData.largerBanner && (
                <>
                  <img
                    src={tournamentData.largerBanner}
                    alt="largerBanner"
                    className="w-[516px] h-[200px]"
                  />
                </>
              )}
            {tournamentData.largerBanner && (
              <button
                onClick={() => handleRemoveBanner("largerBanner")}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-4 rounded focus:outline-none focus:shadow-outline block"
              >
                Remove Large Banner Image
              </button>
            )}
            {formErrors.largerBanner && (
              <p className="text-red-500 text-sm">{formErrors.largerBanner}</p>
            )}
          </div>

          <div className="w-full md:w-1/2 px-3 mb-4">
            <label htmlFor="message" className="text-gray-700 text-md mb-2">
              Message:
            </label>
            <textarea
              name="message"
              id="message"
              value={tournamentData.message}
              onChange={handleInputChange}
              placeholder="enter your message"
              className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm p-1.5 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              rows="4"
            ></textarea>
            {formErrors.message && (
              <p className="text-red-500 text-sm">{formErrors.message}</p>
            )}
          </div>

          <div className="w-full md:w-1/2 px-3 mb-4">
            <label
              htmlFor="eventName"
              className="text-gray-700 text-md mb-2 block"
            >
              TOC <span className="text-red-700">*</span>:
            </label>

            <div
              className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm py-3 px-2
             w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              id="toc"
            >
              <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
              />
            </div>
            {formErrors.toc && (
              <p className="text-red-500 text-sm">{formErrors.toc}</p>
            )}
          </div>
          <div className="mb-4 w-1/2 px-3 flex items-center justify-start">
            <label htmlFor="hidden" className="text-gray-700 text-md mb-2">
              Hide
            </label>
            <input
              type="checkbox"
              name="hidden"
              id="hidden"
              checked={tournamentData.hidden}
              onChange={(e) =>
                setTournamentData({
                  ...tournamentData,
                  hidden: e.target.checked,
                })
              }
              className="text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            />
            {formErrors.hidden && (
              <p className="text-red-500 text-sm">{formErrors.hidden}</p>
            )}
          </div>

          {tournamentData.prizes.map((prize, index) => (
            <div
              key={index}
              className="flex items-center justify-center space-x-2 mt-2 px-3 w-full"
            >
              <div className="flex flex-col w-full">
                <div className=" text-gray-900">Prize Name</div>
                <input
                  type="text"
                  id="prizeName"
                  value={prize.prize_name}
                  onChange={(e) =>
                    handlePrizeChange(index, "prize_name", e.target.value)
                  }
                  placeholder="Prize Name"
                  className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm p-2  w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
                {formErrors.prizes &&
                  formErrors.prizes[index] &&
                  formErrors.prizes[index].prize_name && (
                    <p className="text-red-500 text-sm">
                      {formErrors.prizes[index].prize_name}
                    </p>
                  )}
              </div>
              <div className="flex flex-col w-full">
                <div className=" text-gray-900">Prize Rank</div>
                <input
                  type="number"
                  onWheel={handleWheel}
                  id="prizeRank"
                  value={prize.rank}
                  onChange={(e) =>
                    handlePrizeChange(index, "rank", e.target.value)
                  }
                  placeholder="Rank"
                  className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm p-2  w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
                {formErrors.prizes &&
                  formErrors.prizes[index] &&
                  formErrors.prizes[index].rank && (
                    <p className="text-red-500 text-sm">
                      {formErrors.prizes[index].rank}
                    </p>
                  )}
              </div>
              <div className="flex flex-col w-full">
                <div className="text-gray-900">Prize Amount</div>
                <input
                  type="number"
                  onWheel={handleWheel}
                  id="prizeAmount"
                  value={prize.amount}
                  onChange={(e) =>
                    handlePrizeChange(index, "amount", e.target.value)
                  }
                  placeholder="Amount"
                  className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm p-2   w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
                {formErrors.prizes &&
                  formErrors.prizes[index] &&
                  formErrors.prizes[index].amount && (
                    <p className="text-red-500 text-sm px-1">
                      {formErrors.prizes[index].amount}
                    </p>
                  )}
              </div>
              <button
                type="button"
                onClick={() => removePrizeItem(index)}
                className="bg-red-500 hover:bg-red-700 text-white font-bold p-1 disabled:bg-red-200 "
                disabled={tournamentData.prizes.length === 1}
              >
                <svg
                  width="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="currentColor"
                >
                  <path
                    d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M20.708 6.23975H3.75"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
              <button
                type="button"
                onClick={addPrizeItem}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-1 rounded  w-1/3"
              >
                Add Prize
              </button>
            </div>
          ))}

          <div className="px-3">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-4 rounded focus:outline-none focus:shadow-outline block"
              disabled={Object.keys(formErrors).length === 0 && isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default TournamentForm;
