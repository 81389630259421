import React, { useState, useEffect } from "react";
import axios, { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import LoaderSpiner from './loading.js';
import { getApi } from "../../../backend.js";


const result = () => {
  const { tournament_id } = useParams();
  const [result, setResult] = useState([]);

  const [loading, setLoading] = useState(true);
 
  const getResult = async() => {
    const result = await axios({
      url: `${getApi()}/tournament/getResult/${tournament_id}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")
          ? JSON.parse(localStorage.getItem("token"))
          : ""
          }`,
      },
    })
    console.log(result?.data);
    setResult(result?.data);
    setLoading(false);
}
 const [winnersDeclared, setWinnersDeclared] = useState(false);
  useEffect(() => {
    getResult();
  }, [tournament_id]);

  useEffect(() => {
    const resultsDeclaredValue = result?.data?.resultInfo?.resultsDeclared;
  
    if (resultsDeclaredValue !== false && resultsDeclaredValue !== undefined){
      setWinnersDeclared(true);   // Set winnersDeclared to true when results are declared
    }
  }, [result]);

  console.log(result);
  console.log(winnersDeclared);

  if (loading) {
    return <LoaderSpiner />;
  }

  return (

    <div className="bg-white rounded-md relative z-10">
        <div className="pt-6 px-6 text-2xl font-bold text-black">
          <h4>Tournament Results</h4>
        </div>
    {winnersDeclared ? (
      <div className="p-5 flex flex-col flex-wrap">
        <table>
          <thead className="text-black">
            <tr>
              <th>Rank</th>
              <th>Prize Name</th>
              <th>Prize Amount</th>
              <th>Team Name</th>
              <th>Players</th>
            </tr>
            <tr>
              <td colSpan="5">
                <hr />
              </td>
            </tr>
          </thead>
          <tbody>
          {result?.data?.winners?.length > 0 && result.data.winners.map((result, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>{result.prize.rank}</td>
                    <td>{result.prize.prize_name}</td>
                    <td>{result.prize.amount}</td>
                    <td>{result.team.teamName}</td>
                    <td>
                      <ul>
                        {result?.team?.players?.map((player, playerIndex) => (
                          <div className="flex space-x-2 space-y-1" key={playerIndex}>
                            <li><img src={player.userId.profilePic} alt="profile-pic" className="w-6 h-6 rounded-full mt-1"/></li>
                            <li>{player.userId.username}</li>
                          </div>
                        ))}
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="5">
                      <hr style={{ margin: "10px 0" }} />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </div>
    ) : (
      <div className="p-5 flex">
        <p>Result not declared yet!!</p>
      </div>
    )}
  </div>
);
    }

export default result;

      