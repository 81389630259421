import React, { useEffect, useState } from 'react'
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { tw } from "twind"
import { useParams } from 'react-router-dom';
import axios from 'axios';
import useFullPageLoader from "../../../Hooks/useFullPageLoader";
// import { getApi } from '../backend';
import { toast } from "react-hot-toast"
import { Link } from 'react-router-dom';

import { getApi } from '../../../backend';

import { getModalData } from '../../../Hooks/tournamentHooks';

// const ViewStageModal = ({ isOpen, openModal, closeModal, id}) => {

// const [modalData, setModalData] = useState([]);
// const [roundName, setRoundName]  = useState("");

// useEffect(() => {
//     getModalData(id).then((res) => {
//       console.log("I got this data" , id);
//       if (res.data === null) {
//         setModalData("No data found");
//       } else {
//           console.log("I am getting this data" , res);
//           setModalData(res.data.groups);
//           setRoundName (res.data.roundName)
//           console.log("I am getting Rounds name" + res.data.roundName)  
//       }
//     });
//   }, []);

//   console.log( "I got this data" + res.data);
//   console.log(id);




// const data = [
//     {
//         roundNumber: 1,
//         roundName: "Round 1",
//         slots: 4,
//         invited_teams: 0
//     },
//     {
//         roundNumber: 2,
//         roundName: "Round 2",
//         slots: 4,
//         invited_teams: 0
//     },
//     {
//         roundNumber: 3,
//         roundName: "Round 3",
//         slots: 4,
//         invited_teams: 0
//     },
//     {
//         roundNumber: 4,
//         roundName: "Round 4",
//         slots: 4,
//         invited_teams: 0
//     }
// ]

const ViewStageModal = ({ isOpen, openModal, closeModal, id }) => {

    const [data, setData] = useState(null);

    useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(`${getApi()}/tournament/tour/stage/${id}`);
      const data = await response.json();
      setData(data.data.groups);
    } catch (error) {
      // Handle error
    }
  };

  fetchData();
}, [id]);


    console.log('dd', data);

    return (
        <div>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className={tw("relative z-10 text-xl")} onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter={tw("ease-out duration-300")}
                        enterFrom={tw("opacity-0")}
                        enterTo={tw("opacity-100")}
                        leave={tw("ease-in duration-200")}
                        leaveFrom={tw("opacity-100")}
                        leaveTo={tw("opacity-0")}
                    >
                        <div className={tw("fixed inset-0 bg-black bg-opacity-25")} />
                    </Transition.Child>

                    <div className={tw("fixed inset-0 overflow-y-auto")}>
                        <div className={tw("flex min-h-full items-center justify-center p-4 text-center")}>
                            <Transition.Child
                                as={Fragment}
                                enter={tw("ease-out duration-300")}
                                enterFrom={tw("opacity-0 scale-95")}
                                enterTo={tw("opacity-100 scale-100")}
                                leave={tw("ease-in duration-200")}
                                leaveFrom={tw("opacity-100 scale-100")}
                                leaveTo={tw("opacity-0 scale-95")}
                            >
                                <Dialog.Panel className={tw("w-[1000px] max-w-lg h-[550px] transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all")}>
                                    <Dialog.Title
                                        as="h3"
                                        className={tw("text-xl font-medium leading-6 text-gray-900")}
                                    >
                                        Stage Details
                                    </Dialog.Title>
                                    <main className={tw("grid grid-cols-2 place-items-center gap-5 my-5")}>
                                        {data?.map((item, index) => {
                                            return (
                                                <Link to={`/dashboard/app/tournaments-details/${id}/group/${item.roundNumber}`}>
                                                    <div className={tw("w-[190px] h-[190px] bg-gray-100 rounded-md p-2")}>
                                                        <p className={tw("font-semibold uppercase text-md text-blue-400")}>
                                                            {item.roundName}
                                                        </p>
                                                        <p>
                                                            Slots : <span className={tw("text-green-600 text-md font-semibold")}>
                                                                {item.slots}
                                                            </span>
                                                        </p>
                                                        {
                                                            item.invited_teams !== 0 && <p>  Invited Teams : <span className={tw("text-green-600 text-md font-semibold")}>
                                                                {item.invited_teams}
                                                            </span></p>
                                                        }
                                                        <p>
                                                            Match Time:{" "}
                                                            <span className={tw("text-green-600 text-md font-semibold")}>
                                                                {new Date(item.match_time).toUTCString()
                                                                }
                                                            </span>
                                                        </p>
                                                    </div>
                                                </Link>
                                            )
                                        })}
                                    </main>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    )
}

export default ViewStageModal