import React, { useEffect, useState } from "react";
import { Row, Col, Image, Tooltip, OverlayTrigger } from "react-bootstrap";
import Card from "../../../components/Card";
import { Link, useHistory, useParams } from "react-router-dom";
import { referalByUser } from "../../../Hooks/referalHooks";
import jsPDF from "jspdf";
import { AiOutlineEye } from "react-icons/ai";

const ReferalAll = () => {
  let history = useHistory();
  const [item, setItem] = useState([]);
  const { code } = useParams();
  const refByUser = () => {
    referalByUser(code).then((res) => {
      setItem(res.data);
      // console/\.log(res);
    });
  };
  useEffect(() => {
    refByUser();
  }, []);


  const nav = (id) => {
    history.push(`/dashboard/app/user-profile/${id}`);
  };

  const userCount = item.length;
  const print = () => {
    const pdf = new jsPDF("p", "pt", "a4");
    const columns = ["S.No.", "User Name", "Email"];
    var rows = [];
    var count = 1;
    for (let i = 0; i < item.length; i++) {
      var temp = [
        count++,
        item[i].username,
        item[i].email,
      ];
      rows.push(temp);
    }
    pdf.text(180, 40, `Playwise - Referals List of ${code}`);
    pdf.autoTable(columns, rows, {
      startY: 65,
      theme: "grid",
      styles: {
        font: "times",
        halign: "center",
        cellPadding: 3.5,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        textColor: [0, 0, 0],
      },
      headStyles: {
        textColor: [0, 0, 0],
        fontStyle: "normal",
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [234, 106, 18],
      },
      alternateRowStyles: {
        fillColor: [212, 212, 212],
        textColor: [0, 0, 0],
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
      },
      rowStyles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
      },
      tableLineColor: [0, 0, 0],
    });
    // console.log(pdf.output("datauristring"));
    pdf.save("pdf");
  };
  return (
    <>
      <div>
        <Row>
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Referal Codes</h4>
                  <span>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={refByUser}
                    >
                      Reload
                    </button>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={print}
                      style={{ marginLeft: "15px" }}
                    >
                      Export
                    </button>
                  </span>
                </div>
                <div>
                  <h4 className="card-title">
                    Referal Code Count: {userCount}
                  </h4>
                </div>
              </Card.Header>
              <Card.Body className="px-0">
                <div className="table-responsive">
                  <table
                    id="user-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="ligth">
                        <th>Profile</th>
                        <th>User Name</th>
                        <th>Email</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.map((item, idx) => (
                        <tr key={idx}>
                          <td className="">
                            <Image
                              className="bg-soft-primary rounded img-fluid avatar-40 me-3"
                              src={item.profilePic}
                              alt="profile"
                            />
                          </td>
                          <td 
                          style={{color:"orange", cursor:"pointer"}}
                            onClick={() => nav(item._id)}
                          >{item.username}</td>
                          <td>{item.email}</td>
                          {/* <td>{item.createdAt}</td> */}
                          <td>
                            <div className="flex align-items-center list-user-action">
                              
                              <Link
                                className="btn btn-sm btn-icon btn-success"
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Edit"
                                to="#"
                                onClick={() => nav(item._id)}
                              >
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      View More
                                    </Tooltip>
                                  }
                                >
                                  <span className="btn-inner">
                                    <AiOutlineEye size={23} />
                                  </span>
                                </OverlayTrigger>
                              </Link>{" "}
                              
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReferalAll;
