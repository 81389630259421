import React, { useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Accordion,
  useAccordionButton,
  AccordionContext,
} from "react-bootstrap";
import {
  MdOutlineLocalPostOffice,
  MdOutlineDashboardCustomize,
} from "react-icons/md";
import { FaUsers, FaCommentDots, FaUserCog } from "react-icons/fa";
import { BsFilePost } from "react-icons/bs";
import { TbTournament } from "react-icons/tb";
import { AiOutlineShareAlt } from "react-icons/ai";
// Pop over
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import jwt_decode from "jwt-decode";

function CustomToggle({ children, eventKey, onClick }) {
  const { activeEventKey } = useContext(AccordionContext);

  //decoded

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}

const VerticalNav = () => {
  let location = useLocation();
  const token = localStorage.getItem("token");
  const decoded = jwt_decode(token);

  console.log(decoded.role);

  return (
    <>
      <Accordion as="ul" className="navbar-nav iq-main-menu">
        <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon">Home</span>
            <span className="mini-icon">-</span>
          </Link>
        </li>
        {decoded.role === "superadmin" ? (
          <>
            <li className="nav-item">
              <Link
                className={`${
                  location.pathname === "/dashboard" ? "active" : ""
                } nav-link `}
                aria-current="page"
                to="/dashboard"
                onClick={() => {}}
              >
                <MdOutlineDashboardCustomize size={24} />
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled">Dashboard</Tooltip>}
                >
                  <i className="sidenav-mini-icon">
                    {" "}
                    <MdOutlineDashboardCustomize size={22} />{" "}
                  </i>
                </OverlayTrigger>
                <span className="item-name">Dashboard</span>
              </Link>
            </li>
            <Accordion.Item as="li" eventKey="sidebar-user" bsPrefix="nav-item">
              <CustomToggle
                eventKey="sidebar-user"
                // onClick={(activeKey) => setActiveMenu(activeKey)}
              >
                <FaUsers size={24} />
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled"> Users</Tooltip>}
                >
                  <i className="sidenav-mini-icon">
                    {" "}
                    <FaUsers size={22} />{" "}
                  </i>
                </OverlayTrigger>
                <span className="item-name"> Users</span>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="sidebar-user">
                <ul className="sub-nav">
                  {/* <li className="nav-item">
                <Link
                  className={`${
                    location.pathname === "/dashboard/app/user-profile"
                      ? "active"
                      : ""
                  } nav-link`}
                  to="/dashboard/app/user-profile"
                >
                  <i className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> U </i>
                  <span className="item-name">User Profile</span>
                </Link>
              </li> */}
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/user-add"
                          ? "active"
                          : ""
                      } nav-link`}
                      to="/dashboard/app/user-add"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">Add Users</Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> A </i>
                      </OverlayTrigger>
                      <span className="item-name">Add User</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/user-list"
                          ? "active"
                          : ""
                      } nav-link`}
                      to="/dashboard/app/user-list"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">Users List</Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> L </i>
                      </OverlayTrigger>
                      <span className="item-name">User List</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/user-reported"
                          ? "active"
                          : ""
                      } nav-link `}
                      aria-current="page"
                      to="/dashboard/app/user-reported"
                      onClick={() => {}}
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Reported Users
                          </Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> R </i>
                      </OverlayTrigger>
                      <span className="item-name">Reported User</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/user-banned"
                          ? "active"
                          : ""
                      } nav-link `}
                      aria-current="page"
                      to="/dashboard/app/user-banned"
                      onClick={() => {}}
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">Banned Users</Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> B </i>
                      </OverlayTrigger>
                      <span className="item-name">Banned User</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/user-deleted"
                          ? "active"
                          : ""
                      } nav-link `}
                      aria-current="page"
                      to="/dashboard/app/user-deleted"
                      onClick={() => {}}
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">Deleted Users</Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> D </i>
                      </OverlayTrigger>
                      <span className="item-name">Deleted Users</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/user-dummy"
                          ? "active"
                          : ""
                      } nav-link `}
                      aria-current="page"
                      to="/dashboard/app/user-dummy"
                      onClick={() => {}}
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">Dummy Users</Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> D </i>
                      </OverlayTrigger>
                      <span className="item-name">Dummy Users</span>
                    </Link>
                  </li>
                </ul>
              </Accordion.Collapse>
            </Accordion.Item>
            <Accordion.Item as="li" eventKey="sidebar-user" bsPrefix="nav-item">
              <CustomToggle
                eventKey="sidebar-admins"
                // onClick={(activeKey) => setActiveMenu(activeKey)}
              >
                <FaUserCog size={24} />
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled">Admin</Tooltip>}
                >
                  <i className="sidenav-mini-icon">
                    {" "}
                    <TbTournament size={22} />{" "}
                  </i>
                </OverlayTrigger>
                <span className="item-name">Admins</span>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="sidebar-admins">
                <ul className="sub-nav">
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/add-admins"
                          ? "active"
                          : ""
                      } nav-link`}
                      to="/dashboard/app/add-admins"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">Add Admins</Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> A </i>
                      </OverlayTrigger>
                      <span className="item-name">Add Admins</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/admins-list"
                          ? "active"
                          : ""
                      } nav-link`}
                      to="/dashboard/app/admins-list"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">Admins List</Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> L </i>
                      </OverlayTrigger>
                      <span className="item-name">Admins List</span>
                    </Link>
                  </li>
                </ul>
              </Accordion.Collapse>
            </Accordion.Item>
            <Accordion.Item
              as="li"
              eventKey="sidebar-posts"
              bsPrefix="nav-item"
            >
              <CustomToggle
                eventKey="sidebar-posts"
                // onClick={(activeKey) => setActiveMenu(activeKey)}
              >
                {/* <FaUserCog size={24} /> */}
                <BsFilePost size={24} />
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled">Posts</Tooltip>}
                >
                  <i className="sidenav-mini-icon">
                    {" "}
                    <TbTournament size={22} />{" "}
                  </i>
                </OverlayTrigger>
                <span className="item-name">Posts</span>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="sidebar-posts">
                <ul className="sub-nav">
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/post-reported"
                          ? "active"
                          : ""
                      } nav-link `}
                      aria-current="page"
                      to="/dashboard/app/post-reported"
                      onClick={() => {}}
                    >
                      {/* <BsFilePost size={24} /> */}
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">Reported Post</Tooltip>
                        }
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle cx="12" cy="12" r="8" fill="currentColor">
                                {" "}
                              </circle>
                            </g>
                          </svg>
                        </i>
                      </OverlayTrigger>
                      <span className="item-name">Reported Post</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/comments-reported"
                          ? "active"
                          : ""
                      } nav-link `}
                      aria-current="page"
                      to="/dashboard/app/comments-reported"
                      onClick={() => {}}
                    >
                      {/* <FaCommentDots size={24} /> */}
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Reported Comments
                          </Tooltip>
                        }
                      >
                        {/* <i className="sidenav-mini-icon">
                          {" "}  
                          <FaCommentDots size={22} />{" "}
                        </i> */}
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle cx="12" cy="12" r="8" fill="currentColor">
                                {" "}
                              </circle>
                            </g>
                          </svg>
                        </i>
                      </OverlayTrigger>
                      <span className="item-name">Reported Comments</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/post-details"
                          ? "active"
                          : ""
                      } nav-link `}
                      aria-current="page"
                      to="/dashboard/app/post-details"
                      onClick={() => {
                        // Add your custom action here
                        console.log("Reported Post link clicked!"); // Example action: Logging to the console
                      }}
                    >
                      {/* <FaCommentDots size={24} /> */}
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle cx="12" cy="12" r="8" fill="currentColor">
                              {" "}
                            </circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">Post List</Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon">
                          {" "}
                          <FaCommentDots size={22} />{" "}
                        </i>
                      </OverlayTrigger>
                      <span className="item-name">Post List</span>
                    </Link>
                  </li>
                </ul>
              </Accordion.Collapse>
            </Accordion.Item>
            <li className="nav-item">
              <Link
                className={`${
                  location.pathname === "/dashboard/app/send-msg"
                    ? "active"
                    : ""
                } nav-link `}
                aria-current="page"
                to="/dashboard/app/send-msg"
                onClick={() => {}}
              >
                <MdOutlineLocalPostOffice size={24} />
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled">Send Email</Tooltip>}
                >
                  <i className="sidenav-mini-icon">
                    {" "}
                    <MdOutlineLocalPostOffice size={22} />{" "}
                  </i>
                </OverlayTrigger>
                <span className="item-name">Send Email</span>
              </Link>
            </li>
            <Accordion.Item as="li" eventKey="sidebar-user" bsPrefix="nav-item">
              <CustomToggle
                eventKey="sidebar-tournaments"
                // onClick={(activeKey) => setActiveMenu(activeKey)}
              >
                <TbTournament size={24} />
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled">Tournament</Tooltip>}
                >
                  <i className="sidenav-mini-icon">
                    {" "}
                    <TbTournament size={22} />{" "}
                  </i>
                </OverlayTrigger>
                <span className="item-name">Tournaments</span>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
              {/* <Accordion.Item as="li" eventKey="sidebar-user" bsPrefix="nav-item">
              <CustomToggle
                eventKey="sidebar-games"
                // onClick={(activeKey) => setActiveMenu(activeKey)}
              >
                <TbTournament size={24} />
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled">Game</Tooltip>}
                >
                  <i className="sidenav-mini-icon">
                    {" "}
                    <TbTournament size={22} />{" "}
                  </i>
                </OverlayTrigger>
                <span className="item-name">Games</span>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="sidebar-games">
                <ul className="sub-nav">
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/games/createGames"
                          ? "active"
                          : ""
                      } nav-link`}
                      to="/dashboard/app/games/createGame"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Add Games
                          </Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> A </i>
                      </OverlayTrigger>
                      <span className="item-name">Add Games</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/games-list"
                          ? "active"
                          : ""
                      } nav-link`}
                      to="/dashboard/app/games-list"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Games List
                          </Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> L </i>
                      </OverlayTrigger>
                      <span className="item-name">Games List</span>
                    </Link>
                  </li>
                </ul>
              </Accordion.Collapse>
            </Accordion.Item> */}
              <Accordion eventKey="sidebar-tournaments">
                <ul className="sub-nav">
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/add-tournaments"
                          ? "active"
                          : ""
                      } nav-link`}
                      to="/dashboard/app/add-tournaments"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Add Tournaments
                          </Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> A </i>
                      </OverlayTrigger>
                      <span className="item-name">Add Tournaments</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/tournaments-list"
                          ? "active"
                          : ""
                      } nav-link`}
                      to="/dashboard/app/tournaments-list"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Tournaments List
                          </Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> L </i>
                      </OverlayTrigger>
                      <span className="item-name">Tournaments List</span>
                    </Link>
                  </li>

                  {/* <Accordion.Item as="li" eventKey="sidebar-user" bsPrefix="nav-item">
              <CustomToggle
                eventKey="sidebar-games"
                // onClick={(activeKey) => setActiveMenu(activeKey)}
              >
                <TbTournament size={24} />
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled">Game</Tooltip>}
                >
                  <i className="sidenav-mini-icon">
                    {" "}
                    <TbTournament size={22} />{" "}
                  </i>
                </OverlayTrigger>
                <span className="item-name">Games</span>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="sidebar-games">
                <ul className="sub-nav">
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/games/createGames"
                          ? "active"
                          : ""
                      } nav-link`}
                      to="/dashboard/app/games/createGame"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Add Games
                          </Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> A </i>
                      </OverlayTrigger>
                      <span className="item-name">Add Games</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/games-list"
                          ? "active"
                          : ""
                      } nav-link`}
                      to="/dashboard/app/games-list"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Games List
                          </Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> L </i>
                      </OverlayTrigger>
                      <span className="item-name">Games List</span>
                    </Link>
                  </li>
                </ul>
              </Accordion.Collapse>
            </Accordion.Item> */}
                </ul>
                {/* <Accordion.Item as="li" eventKey="sidebar-user" bsPrefix="nav-item">
              <CustomToggle
                eventKey="sidebar-games"
                // onClick={(activeKey) => setActiveMenu(activeKey)}
              >
                <TbTournament size={24} />
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled">Game</Tooltip>}
                >
                  <i className="sidenav-mini-icon">
                    {" "}
                    <TbTournament size={22} />{" "}
                  </i>
                </OverlayTrigger>
                <span className="item-name">Games</span>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="sidebar-games">
                <ul className="sub-nav">
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/games/createGames"
                          ? "active"
                          : ""
                      } nav-link`}
                      to="/dashboard/app/games/createGame"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Add Games
                          </Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> A </i>
                      </OverlayTrigger>
                      <span className="item-name">Add Games</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/games-list"
                          ? "active"
                          : ""
                      } nav-link`}
                      to="/dashboard/app/games-list"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Games List
                          </Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> L </i>
                      </OverlayTrigger>
                      <span className="item-name">Games List</span>
                    </Link>
                  </li>
                </ul>
              </Accordion.Collapse>
            </Accordion.Item> */}
              </Accordion>
              <Accordion.Item
                as="li"
                eventKey="sidebar-user"
                bsPrefix="nav-item"
              >
                <CustomToggle
                  eventKey="sidebar-games"
                  // onClick={(activeKey) => setActiveMenu(activeKey)}
                >
                  <TbTournament size={24} />
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-disabled">Game</Tooltip>}
                  >
                    <i className="sidenav-mini-icon">
                      {" "}
                      <TbTournament size={22} />{" "}
                    </i>
                  </OverlayTrigger>
                  <span className="item-name">Games</span>
                  <i className="right-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </i>
                </CustomToggle>
                <Accordion.Collapse eventKey="sidebar-games">
                  <ul className="sub-nav">
                    <li className="nav-item">
                      <Link
                        className={`${
                          location.pathname ===
                          "/dashboard/app/games/createGames"
                            ? "active"
                            : ""
                        } nav-link`}
                        to="/dashboard/app/games/createGame"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">Add Games</Tooltip>
                          }
                        >
                          <i className="sidenav-mini-icon"> A </i>
                        </OverlayTrigger>
                        <span className="item-name">Add Games</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`${
                          location.pathname === "/dashboard/app/games-list"
                            ? "active"
                            : ""
                        } nav-link`}
                        to="/dashboard/app/games-list"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">Games List</Tooltip>
                          }
                        >
                          <i className="sidenav-mini-icon"> L </i>
                        </OverlayTrigger>
                        <span className="item-name">Games List</span>
                      </Link>
                    </li>
                  </ul>
                </Accordion.Collapse>
              </Accordion.Item>
            </Accordion.Item>
            <Accordion.Item as="li" eventKey="sidebar-user" bsPrefix="nav-item">
              <CustomToggle
                eventKey="sidebar-rewards"
                // onClick={(activeKey) => setActiveMenu(activeKey)}
              >
                <TbTournament size={24} />
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled">Rewards</Tooltip>}
                >
                  <i className="sidenav-mini-icon">
                    {" "}
                    <TbTournament size={22} />{" "}
                  </i>
                </OverlayTrigger>
                <span className="item-name">Rewards</span>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="sidebar-rewards">
                <ul className="sub-nav">
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/rewards/createRewards"
                          ? "active"
                          : ""
                      } nav-link`}
                      to="/dashboard/app/rewards/createRewards"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Add Games
                          </Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> A </i>
                      </OverlayTrigger>
                      <span className="item-name">Add Rewards </span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/rewards-list"
                          ? "active"
                          : ""
                      } nav-link`}
                      to="/dashboard/app/rewards-list"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Rewards List
                          </Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> L </i>
                      </OverlayTrigger>
                      <span className="item-name">Rewards List</span>
                    </Link>
                  </li>
                </ul>
              </Accordion.Collapse>
            </Accordion.Item> 

            <Accordion.Item as="li" eventKey="sidebar-user" bsPrefix="nav-item">
              <CustomToggle
                eventKey="sidebar-referal"
                // onClick={(activeKey) => setActiveMenu(activeKey)}
              >
                <AiOutlineShareAlt size={24} />
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled">Referal</Tooltip>}
                >
                  <i className="sidenav-mini-icon">
                    {" "}
                    <AiOutlineShareAlt size={22} />{" "}
                  </i>
                </OverlayTrigger>
                <span className="item-name">Referal</span>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="sidebar-referal">
                <ul className="sub-nav">
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/gen-referal"
                          ? "active"
                          : ""
                      } nav-link`}
                      to="/dashboard/app/gen-referal"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Generate Referal
                          </Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> G </i>
                      </OverlayTrigger>
                      <span className="item-name">Generate Referal</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/referal-list"
                          ? "active"
                          : ""
                      } nav-link`}
                      to="/dashboard/app/referal-list"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">Referals List</Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> L </i>
                      </OverlayTrigger>
                      <span className="item-name">All Referals</span>
                    </Link>
                  </li>
                </ul>
              </Accordion.Collapse>
            </Accordion.Item>{" "}
          </>
        ) : decoded.role === "admin" ? (
          <>
            <li className="nav-item">
              <Link
                className={`${
                  location.pathname === "/dashboard" ? "active" : ""
                } nav-link `}
                aria-current="page"
                to="/dashboard"
                onClick={() => {}}
              >
                <MdOutlineDashboardCustomize size={24} />
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled">Dashboard</Tooltip>}
                >
                  <i className="sidenav-mini-icon">
                    {" "}
                    <MdOutlineDashboardCustomize size={22} />{" "}
                  </i>
                </OverlayTrigger>
                <span className="item-name">Dashboard</span>
              </Link>
            </li>
            <Accordion.Item as="li" eventKey="sidebar-user" bsPrefix="nav-item">
              <CustomToggle
                eventKey="sidebar-user"
                // onClick={(activeKey) => setActiveMenu(activeKey)}
              >
                <FaUsers size={24} />
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled">Users</Tooltip>}
                >
                  <i className="sidenav-mini-icon">
                    {" "}
                    <FaUsers size={22} />{" "}
                  </i>
                </OverlayTrigger>
                <span className="item-name">Users</span>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="sidebar-user">
                <ul className="sub-nav">
                  {/* <li className="nav-item">
                <Link
                  className={`${
                    location.pathname === "/dashboard/app/user-profile"
                      ? "active"
                      : ""
                  } nav-link`}
                  to="/dashboard/app/user-profile"
                >
                  <i className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> U </i>
                  <span className="item-name">User Profile</span>
                </Link>
              </li> */}
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/user-add"
                          ? "active"
                          : ""
                      } nav-link`}
                      to="/dashboard/app/user-add"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">Add Users</Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> A </i>
                      </OverlayTrigger>
                      <span className="item-name">Add User</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/user-list"
                          ? "active"
                          : ""
                      } nav-link`}
                      to="/dashboard/app/user-list"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">Users List</Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> L </i>
                      </OverlayTrigger>
                      <span className="item-name">User List</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/user-reported"
                          ? "active"
                          : ""
                      } nav-link `}
                      aria-current="page"
                      to="/dashboard/app/user-reported"
                      onClick={() => {}}
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Reported Users
                          </Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> R </i>
                      </OverlayTrigger>
                      <span className="item-name">Reported User</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/user-banned"
                          ? "active"
                          : ""
                      } nav-link `}
                      aria-current="page"
                      to="/dashboard/app/user-banned"
                      onClick={() => {}}
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">Banned Users</Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> B </i>
                      </OverlayTrigger>
                      <span className="item-name">Banned User</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/user-deleted"
                          ? "active"
                          : ""
                      } nav-link `}
                      aria-current="page"
                      to="/dashboard/app/user-deleted"
                      onClick={() => {}}
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">Deleted Users</Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> D </i>
                      </OverlayTrigger>
                      <span className="item-name">Deleted Users</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/user-dummy"
                          ? "active"
                          : ""
                      } nav-link `}
                      aria-current="page"
                      to="/dashboard/app/user-dummy"
                      onClick={() => {}}
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">Dummy Users</Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> D </i>
                      </OverlayTrigger>
                      <span className="item-name">Dummy Users</span>
                    </Link>
                  </li>
                </ul>
              </Accordion.Collapse>
            </Accordion.Item>
            <li className="nav-item">
              <Link
                className={`${
                  location.pathname === "/dashboard/app/post-reported"
                    ? "active"
                    : ""
                } nav-link `}
                aria-current="page"
                to="/dashboard/app/post-reported"
                onClick={() => {}}
              >
                <BsFilePost size={24} />
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">Reported Post</Tooltip>
                  }
                >
                  <i className="sidenav-mini-icon">
                    {" "}
                    <BsFilePost size={22} />{" "}
                  </i>
                </OverlayTrigger>
                <span className="item-name">Reported Post</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`${
                  location.pathname === "/dashboard/app/comments-reported"
                    ? "active"
                    : ""
                } nav-link `}
                aria-current="page"
                to="/dashboard/app/comments-reported"
                onClick={() => {}}
              >
                <FaCommentDots size={24} />
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">Reported Comments</Tooltip>
                  }
                >
                  <i className="sidenav-mini-icon">
                    {" "}
                    <FaCommentDots size={22} />{" "}
                  </i>
                </OverlayTrigger>
                <span className="item-name">Reported Comments</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`${
                  location.pathname === "/dashboard/app/send-msg"
                    ? "active"
                    : ""
                } nav-link `}
                aria-current="page"
                to="/dashboard/app/send-msg"
                onClick={() => {}}
              >
                <MdOutlineLocalPostOffice size={24} />
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled">Send Email</Tooltip>}
                >
                  <i className="sidenav-mini-icon">
                    {" "}
                    <MdOutlineLocalPostOffice size={22} />{" "}
                  </i>
                </OverlayTrigger>
                <span className="item-name">Send Email</span>
              </Link>
            </li>
            <Accordion.Item as="li" eventKey="sidebar-user" bsPrefix="nav-item">
              <CustomToggle
                eventKey="sidebar-tournaments"
                // onClick={(activeKey) => setActiveMenu(activeKey)}
              >
                <TbTournament size={24} />
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled">Tournament</Tooltip>}
                >
                  <i className="sidenav-mini-icon">
                    {" "}
                    <TbTournament size={22} />{" "}
                  </i>
                </OverlayTrigger>
                <span className="item-name">Tournaments</span>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="sidebar-tournaments">
                <ul className="sub-nav">
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/add-tournaments"
                          ? "active"
                          : ""
                      } nav-link`}
                      to="/dashboard/app/add-tournaments"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Add Tournaments
                          </Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> A </i>
                      </OverlayTrigger>
                      <span className="item-name">Add Tournaments</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/tournaments-list"
                          ? "active"
                          : ""
                      } nav-link`}
                      to="/dashboard/app/tournaments-list"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Tournaments List
                          </Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> L </i>
                      </OverlayTrigger>
                      <span className="item-name">Tournaments List</span>
                    </Link>
                  </li>
                </ul>
              </Accordion.Collapse>
            </Accordion.Item>
            <Accordion.Item as="li" eventKey="sidebar-user" bsPrefix="nav-item">
              <CustomToggle
                eventKey="sidebar-referal"
                // onClick={(activeKey) => setActiveMenu(activeKey)}
              >
                <AiOutlineShareAlt size={24} />
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled">Referal</Tooltip>}
                >
                  <i className="sidenav-mini-icon">
                    {" "}
                    <AiOutlineShareAlt size={22} />{" "}
                  </i>
                </OverlayTrigger>
                <span className="item-name">Referal</span>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="sidebar-referal">
                <ul className="sub-nav">
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/gen-referal"
                          ? "active"
                          : ""
                      } nav-link`}
                      to="/dashboard/app/gen-referal"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Generate Referal
                          </Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> G </i>
                      </OverlayTrigger>
                      <span className="item-name">Generate Referal</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard/app/referal-list"
                          ? "active"
                          : ""
                      } nav-link`}
                      to="/dashboard/app/referal-list"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">Referals List</Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon"> L </i>
                      </OverlayTrigger>
                      <span className="item-name">All Referals</span>
                    </Link>
                  </li>
                </ul>
              </Accordion.Collapse>
            </Accordion.Item>
          </>
        ) : (
          <Accordion.Item as="li" eventKey="sidebar-user" bsPrefix="nav-item">
            <CustomToggle
              eventKey="sidebar-tournaments"
              // onClick={(activeKey) => setActiveMenu(activeKey)}
            >
              <TbTournament size={24} />
              <OverlayTrigger
                overlay={<Tooltip id="tooltip-disabled">Tournament</Tooltip>}
              >
                <i className="sidenav-mini-icon">
                  {" "}
                  <TbTournament size={22} />{" "}
                </i>
              </OverlayTrigger>
              <span className="item-name">Tournaments</span>
              <i className="right-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </i>
            </CustomToggle>
            <Accordion.Collapse eventKey="sidebar-tournaments">
              <ul className="sub-nav">
                <li className="nav-item">
                  <Link
                    className={`${
                      location.pathname === "/dashboard/app/add-tournaments"
                        ? "active"
                        : ""
                    } nav-link`}
                    to="/dashboard/app/add-tournaments"
                  >
                    <i className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <g>
                          <circle
                            cx="12"
                            cy="12"
                            r="8"
                            fill="currentColor"
                          ></circle>
                        </g>
                      </svg>
                    </i>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">Add Tournaments</Tooltip>
                      }
                    >
                      <i className="sidenav-mini-icon"> A </i>
                    </OverlayTrigger>
                    <span className="item-name">Add Tournaments</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`${
                      location.pathname === "/dashboard/app/tournaments-list"
                        ? "active"
                        : ""
                    } nav-link`}
                    to="/dashboard/app/tournaments-list"
                  >
                    <i className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <g>
                          <circle
                            cx="12"
                            cy="12"
                            r="8"
                            fill="currentColor"
                          ></circle>
                        </g>
                      </svg>
                    </i>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          Tournaments List
                        </Tooltip>
                      }
                    >
                      <i className="sidenav-mini-icon"> L </i>
                    </OverlayTrigger>
                    <span className="item-name">Tournaments List</span>
                  </Link>
                </li>
              </ul>
            </Accordion.Collapse>
          </Accordion.Item>
        )}
      </Accordion>
    </>
  );
};

export default VerticalNav;
