import React, { useEffect, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import Card from "../../../components/Card";
import { getdeletedusers } from "../../../Hooks/userHooks";
import useFullPageLoader from "../../../Hooks/useFullPageLoader";
import { Link, useHistory } from "react-router-dom";

const DeletedUserList = () => {
  const [item, setItem] = useState([]);
  const [userCount, setUserCount] = useState();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  let history = useHistory();
  const [search, setSearch] = useState("");

  const fetchUsers = () => {
    showLoader();
    getdeletedusers().then((res) => {
      if (res.success === false) {
        setUserCount("Error: " + res.message);
        hideLoader();
      } else {
        setItem(res.data);
        setUserCount("User Count: " + res.data.length);
        hideLoader();
      }
    });
  };

  const nav = (id) => {
    history.push(`/dashboard/app/user-profile/${id}`);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const filteredItems = item.filter((user) => {
    return user.name.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <>
      <div>
        <Row>
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Deleted Users</h4>
                </div>
                <div className="search-bar">
                  <input
                    type="text"
                    className="form-control"
                    id="inputGroup-sizing-sm"
                    aria-label="Search Deleted users"
                    aria-describedby="button-addon2"
                    name="search"
                    style={{
                      height: "35px",
                      width: "500px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid grey",
                    }}
                    placeholder="Search Deleted Users"
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
                <div>
                  <h4 className="card-title">{userCount}</h4>
                  <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <button
                      className="btn btn-sm btn-primary"
                      style={{ marginTop: "1rem" }}
                      onClick={fetchUsers}
                    >
                      Reload
                    </button>
                  </span>
                </div>
              </Card.Header>
              <Card.Body className="px-0">
                <div className="table-responsive">
                  <table
                    id="user-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="ligth">
                        <th>Profile</th>
                        <th>Name</th>
                        <th>Contact</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th>Join Date</th>
                        <th>Join Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredItems.map((user, idx) => (
                        <tr key={idx}>
                          <td className="text-center">
                            <Image
                              className="bg-soft-primary rounded img-fluid avatar-40 me-3"
                              src={user.profilePic}
                              alt="profile"
                            />
                          </td>
                          <td>{user.name}</td>
                          <td>{user.phone}</td>
                          <td>{user.email}</td>
                          <td>{user.role}</td>
                          <td style={{ textAlign: "start" }}>
                            {user._id.active ? (
                              <p>Active</p>
                            ) : user._id.delete ? (
                              <p>Deleted</p>
                            ) : (
                              "Banned"
                            )}
                          </td>
                          <td>{new String(user.createdAt).split("T")[0]}</td>
                          <td>{new String(user.createdAt).split("T").pop().split(".")[0]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      {loader}
    </>
  );
};

export default DeletedUserList;
