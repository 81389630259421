import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { toast } from "react-hot-toast";
import { getApi } from "../../../backend";
import { FaEdit, FaTrash } from "react-icons/fa";
import "./Paginate/Paginate.css";
import { useEffect } from "preact/hooks";
import DeleteModal from "../../../components/DeleteModal";
import axios from "axios";

const AdminsList = () => {
  // const [adminslist, setAdminList] = useEffect([])
  const [getadmin, setAdmin] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [sizes, setSize] = useState(10);
  let [isOpen, setIsOpen] = useState(false);

  const { data: admins = [], refetch } = useQuery({
    queryKey: ["admins", page, sizes],
    queryFn: async () => {
      let url = `${getApi()}/auth/admins?page=${page}&limit=${sizes}`;
      const res = await fetch(url, {
        headers: {
          Authorization: `Bearer ${
            typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
          }`,
        },
      });
      const data = await res.json();
      setCount(data.data.total);
      return data;
    },
  });

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const adminslist = admins?.data?.admins;
  const pages = Math.ceil(count / sizes);

  console.log(adminslist);

  const handleUpdate = async (event) => {
    event.preventDefault();

    const form = event.target;

    const passwords = form.password.value;
    console.log(passwords);
    const status = form.status.value;

    console.log(getadmin);

    if (passwords && !status) {
      //password
      let res = await axios.put(
        `${getApi()}/auth/change-password/${getadmin._id}`,
        {
          password: passwords,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
            }`,
          },
        }
      );
      if (res.data.success) {
        toast.success(res.data.message);
        refetch();
      } else {
        toast.error(res.data.message);
      }
    } else if (status && !passwords) {
      // //status
      let res = await axios.put(
        `${getApi()}/auth/change-status/${getadmin._id}`,
        {
          status: status,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
            }`,
          },
        }
      );

      if (res.data.success) {
        toast.success(res.data.message);
        refetch();
      } else {
        toast.error(res.data.message);
      }
    } else {
      //password and status
      const [pass, stat] = await Promise.all([
        axios.put(
          `${getApi()}/auth/change-password/${getadmin._id}`,
          {
            password: passwords,
          },
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${
                typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
              }`,
            },
          }
        ),
        axios.put(
          `${getApi()}/auth/change-status/${getadmin._id}`,
          {
            status: status,
          },
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${
                typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
              }`,
            },
          }
        ),
      ]);

      if (pass.data.success && stat.data.success) {
        toast.success(pass.data.message);
        toast.success(stat.data.message);
        refetch();
      }
    }
  };

  return (
    <div className="bg-white p-4 rounded">
      <h1 className="fs-3 mb-4">Admin List</h1>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Role</th>
            <th scope="col">Status</th>
            <th scope="col">username</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {adminslist?.map((admin) => (
            <tr key={admin._id}>
              <th scope="row">{admin.name}</th>
              <td>{admin.email}</td>
              <td>{admin.role}</td>
              <td>{admin.status}</td>
              <td>{admin.username}</td>
              <td>
                <button
                  onClick={() => setAdmin(admin)}
                  type="button"
                  class="border"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  data-bs-whatever="@mdo"
                >
                  <FaEdit className="fs-5 text-warning "></FaEdit>
                </button>
                <button
                  type="button"
                  class="border ms-2"
                  onClick={() => {
                    setAdmin(admin);
                    openModal();
                  }}
                >
                  <FaTrash className="fs-5 text-danger "></FaTrash>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isOpen && (
        <DeleteModal
          isOpen={isOpen}
          openModal={openModal}
          closeModal={closeModal}
          admin={getadmin}
          refetch={refetch}
        />
      )}
      {/* // pagination */}

      <div className="paginate-container">
        <div className="paginate">
          <button
            onClick={() => {
              page > 1 && setPage(page - 1);
            }}
          >
            {"<"}
          </button>
          {[...Array(pages).keys()].map((number) => (
            <button
              key={number}
              className={page === number + 1 ? "selected" : ""}
              onClick={() => setPage(number + 1)}
            >
              {number + 1}
            </button>
          ))}
          <button
            onClick={() => {
              Array(pages).length > page && setPage(page + 1);
            }}
          >
            {">"}
          </button>
          <select
            className="selection"
            onChange={(event) => setSize(event.target.value)}
            defaultValue={sizes}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </select>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Update Password and Status
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form onSubmit={handleUpdate} noValidate="">
                <div class="mb-3">
                  <label htmlFor="recipient-name" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    id="recipient-name"
                    placeholder="Password"
                  />
                </div>
                <div class="mb-3">
                  <label htmlFor="validationDefault04" className="form-label">
                    Status
                  </label>
                  <select name="status" className="form-select" id="validationDefault04" required>
                    <option selected value="active">
                      active
                    </option>
                    <option value="inactive">inactive</option>
                  </select>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Close
                  </button>
                  <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminsList;
