import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Card from "../../../components/Card";
import { getusers } from "../../../Hooks/userHooks";
import { useHistory } from "react-router-dom";
import { getApi } from "../../../backend";
// import ReactPaginate from "react-paginate";
import useFullPageLoader from "../../../Hooks/useFullPageLoader";
import "./css/pagination.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { SearchUser } from "../../../Hooks/userHooks";
import { styled, alpha } from "@mui/material/styles";
// import { BsSearch } from "react-icons/bs";
import { Button, InputBase } from "@mui/material";
// import Stack from "@mui/material/Stack";
// import Pagination from "@mui/material/Pagination";

const SendMsg = () => {
  // const initData = Object.freeze([
  //   {
  //     _id: "loading...",
  //     profilePic: "loading...",
  //     name: "loading...",
  //     phone: "loading...",
  //     email: "loading...",
  //     createdAt: "loading...",
  //     role: "loading...",
  //     active: true,
  //     delete: "",
  //   },
  // ]);
  // const [item1, setItem1] = useState(initData);
  // let history = useHistory();

  // const [currentPage, setcurrentPage] = useState(1);
  // const [pageCount, setPageCount] = useState(1);
  // const [text, setText] = useState('');
  const [item, setItem] = useState([]);
  const [userCount, setUserCount] = useState();
  const [form, setForm] = useState("email");
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [checked, setChecked] = useState([]);
  const [file, setFile] = useState("");
  const [array, setArray] = useState([]);
  const [dataChecked, setDataChecked] = useState([]);
  const [subject, setSubject] = useState("");
  const [arr, setArr] = useState([]);
  const [text, setText] = useState("");
  const [bcc, setBCC] = useState("");
  const [cc, setCC] = useState("");
  const [bccError, setBCCError] = useState("");
  const [ccError, setCCError] = useState("");
  const [user, setUser] = useState({});
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  
  //when user is clicked it should be added in an array. 

  const fileReader = new FileReader();

  const fetchUsers = () => {
    showLoader();
    getusers()
      .then((res) => {
        if (res.success === false) {
          setUserCount("Error: " + res.message);
          hideLoader();
        } else {
          setItem(res.users);
          // setPageCount(res.pages);
          // setUserCount(res.users.length);
          hideLoader();
        }
      })
      .catch((error) => {
        console.error(error);
        hideLoader();
      });
  };

  const Search1 = () => {
    SearchUser(user)
      .then((res) => {
        setSearchedUsers(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChange2 = (e) => {
    e.preventDefault();
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  // const nav = (id) => {
  //   history.push(`/dashboard/app/user-profile/${id}`);
  // };
  useEffect(() => {
    fetchUsers();
  }, []);

  // const handlePageChange = (selectedObject) => {
  //   setcurrentPage(selectedObject.selected);
  //   fetchUsers();
  // };

  // const handletextarea = (e) => {
  //   setText(e.target.value);
  // };
  const changeHandler = (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
  };
  useEffect(() => {
    // console.log(file);
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };
      fileReader.readAsText(file);
    }
  });
  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });
    // console.log(array);
    // let array1 = [];
    const headerKeys = Object.keys(Object.assign({}, ...array));
    const arr2 = [];
    const arr1 = [];
    array.map((user, id) => {
      if (headerKeys[0] === "email") {
        arr2.push(user.email);
      }
      if (headerKeys[1] === "number") {
        arr1.push(user.number);
      }
    });
    // console.log(headerKeys[1] === "number", arr2, arr1);
    setArray(form === "email" ? arr2 : arr1);
  };
  // const handleCheck = (event) => {
  //   var updatedList = [...checked];
  //   if (event.target.checked) {
  //     updatedList = [...checked, event.target.value];
  //   } else {
  //     updatedList.splice(checked.indexOf(event.target.value), 1);
  //   }
  //   setChecked(updatedList);
  //   console.log(checked);
  // };
  // setArray(array)
  const ContactData = array.length > 0 ? array : arr;
  console.log(ContactData, item, dataChecked);

  const validateEmailFormat = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    //  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return emailPattern.test(email);
  };

  const validateForm = () => {
    let isValid = true;
    setBCCError("");
    setCCError("");

    // Validate BCC field
    if (bcc.trim() !== "") {
      if (!validateEmailFormat(bcc)) {
        setBCCError("Invalid email address");
        isValid = false;
      }
    }

    // Validate CC field
    if (cc.trim() !== "") {
      if (!validateEmailFormat(cc)) {
        setCCError("Invalid email address");
        isValid = false;
      }
    }

    return isValid;
  };

  const apiHit = async (e) => {
    e.preventDefault();
    // Validate form inputs
    if (!validateForm()) {
      return;
    }
    let url = `${getApi()}/auth/bulk-emails`;
    showLoader();
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          typeof window !== "undefined" &&
          JSON.parse(localStorage.getItem("token"))
        }`,
      },
      body: JSON.stringify({
        emails: selectedUsers,
        message: text,
        subject: subject,
        bcc: bcc,
        cc: cc,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        alert(json.message);
        hideLoader();
      })
      .catch((err) => {
        console.error(err);
        hideLoader();
      });
  };
  const apiHitMsg = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    let url = `${getApi()}/auth/bulk-sms`;
    showLoader();
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          typeof window !== "undefined" &&
          JSON.parse(localStorage.getItem("token"))
        }`,
      },
      body: JSON.stringify({
        numbers: ContactData,
        message: text,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        alert(json.message);
        hideLoader();
      })
      .catch((err) => {
        // console.log(err);
        hideLoader();
      });
  };
  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
    // console.log(updatedList);
  };
  // const handleChange = (e) => {
  //   const { value, checked } = e.target;
  //   // console.log(e.target.value);
  //   if (value === "AllSelect") {
  //     let temp = searchedUsers.map((user) => {
  //       return { ...user, isChecked: checked };
  //     });
  //     setSearchedUsers(temp);
  //     temp.map((user) => {
  //       if (user.isChecked) {
  //         arr.push(user.email);
  //       } else {
  //         arr.pop(user.email);
  //       }
  //       setSearchedUsers(temp);
  //       // console.log(arr, item, temp);
  //     });
  //   } else {
  //     setArr([]);
  //     let temp1 = searchedUsers.map((user) =>
  //       user.email === value ? { ...user, isChecked: checked } : user
  //     );
  //     var updatedList = [...dataChecked];
  //     if (e.target.checked) {
  //       updatedList = [...dataChecked, e.target.value];
  //     } else {
  //       updatedList.splice(dataChecked.indexOf(e.target.value), 1);
  //     }
  //     setDataChecked(updatedList);
  //     // console.log(dataChecked);
  //     setSearchedUsers(temp1);
  //     // console.log(item);
  //   }
  // };

  // array and one state selectedUser, if checkbox checked push users.

  const handleChange1 = (e) => {
    const { value, checked } = e.target;
    // console.log(e.target.value);
    if (value === "AllSelect") {
      let temp = searchedUsers.map((user) => {
        return { ...user, isChecked: checked };
      });
      setSearchedUsers(temp);
      temp.map((user) => {
        if (user.isChecked) {
          arr.push(user.phone);
        } else {
          arr.pop(user.phone);
        }
        setSearchedUsers(temp);
        // console.log(arr, item, temp);
      });
    } else {
      setArr([]);
      let temp1 = searchedUsers.map((user) =>
        user.phone === value ? { ...user, isChecked: checked } : user
      );
      var updatedList = [...dataChecked];
      if (e.target.checked) {
        updatedList = [...dataChecked, e.target.value];
      } else {
        updatedList.splice(dataChecked.indexOf(e.target.value), 1);
      }
      setDataChecked(updatedList);
      // console.log(dataChecked);
      setSearchedUsers(temp1);
      // console.log(item);
    }
  };
  console.log(dataChecked);
  return (
    <>
      <div>
        <Row>
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Select Message Type</h4>
                  <div
                    className="d-flex"
                    style={{ marginTop: "2rem", marginBottom: "1rem" }}
                  >
                    <Form.Check style={{ marginRight: "0.7rem" }}>
                      <Form.Check.Input
                        type="radio"
                        name="customRadio0"
                        id="email"
                        onClick={() => setForm("email")}
                        defaultChecked
                      />
                      <Form.Check.Label htmlFor="email" className="pl-2">
                        Send Email
                      </Form.Check.Label>
                    </Form.Check>
                    <Form.Check>
                      <Form.Check.Input
                        type="radio"
                        name="customRadio0"
                        id="msg"
                        onClick={() => setForm("msg")}
                      />
                      <Form.Check.Label htmlFor="msg" className="pl-2">
                        Send Message
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                  <div className="d-block justify-content-start">
                    {form === "email" && (
                      <>
                        <Row>
                          <Col>
                            <label>Send Emails</label>
                          </Col>
                        </Row>

                        <Row>
                          <Form.Group className="col-md-6 form-group">
                            <Form.Label
                              htmlFor="subject"
                              style={{ marginTop: "0.5rem" }}
                            >
                              Subject
                            </Form.Label>
                            <Form.Control
                              type="text"
                              id="subject"
                              name="subject"
                              placeholder="Subject"
                              onChange={(e) => setSubject(e.target.value)}
                            />
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group>
                            <Form.Label>CC</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="CC"
                              value={cc}
                              onChange={(e) => setCC(e.target.value)}
                              isInvalid={ccError !== ""}
                            />
                            <Form.Control.Feedback type="invalid">
                              {ccError}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group>
                            <Form.Label>BCC</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="BCC"
                              value={bcc}
                              onChange={(e) => setBCC(e.target.value)}
                              isInvalid={bccError !== ""}
                            />
                            <Form.Control.Feedback type="invalid">
                              {bccError}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>

                        <Row>
                          <Form.Group className="col-md-6 form-group">
                            <Form.Label
                              htmlFor="msg"
                              style={{ marginTop: "0.5rem" }}
                            >
                              Message
                            </Form.Label>
                            <div
                              style={{ maxWidth: "500px", minHeight: "100px" }}
                            >
                              <CKEditor
                                as="textarea"
                                name="msg"
                                style={{ width: "100%", minHeight: "100px" }}
                                placeholder="Write your message here.."
                                editor={ClassicEditor}
                                data={text}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setText(data);
                                }}
                              />
                            </div>
                          </Form.Group>
                        </Row>

                        <Row>
                          <Form.Group className="col-md-6 form-group">
                            <Form.Label
                              htmlFor="subject"
                              className="mx-2"
                              style={{ marginTop: "0.5rem" }}
                            >
                              Send by CSV
                            </Form.Label>
                            <input
                              type={"file"}
                              id={"csvFileInput"}
                              accept={".csv"}
                              onChange={changeHandler}
                            />
                          </Form.Group>

                          <button
                            className="btn btn-sm btn-icon btn-warning my-5"
                            onClick={apiHit}
                            style={{
                              width: "20%",
                              margin: "0.8rem",
                              height: "2rem",
                            }}
                          >
                            Send
                          </button>
                        </Row>
                        {/* <div className="d-flex justify-content-start align-items-center">
                          <span>Select All</span>
                          <input
                            type="checkbox"
                            id="id"
                            name="AllSelect"
                            value="AllSelect"
                            checked={
                              searchedUsers.filter((user) => !user.isChecked)
                                .length === 0
                            }
                            style={{
                              width: "1rem",
                              margin: "8px",
                              justifyContent: "center",
                            }}
                            onChange={handleChange}
                          />
                        </div> */}
                      </>
                    )}
                    {form === "msg" && (
                      <>
                        <Row>
                          <Row>
                            <Col>
                              <label>Send Messages</label>
                            </Col>
                          </Row>
                          <Row>
                            <Form.Group className="col-md-6 form-group">
                              <Form.Label
                                htmlFor="msg"
                                style={{ marginTop: "0.5rem" }}
                              >
                                Message
                              </Form.Label>
                              <div
                                style={{
                                  maxWidth: "500px",
                                  height: "300px",
                                  width: "500px",
                                }}
                              >
                                <CKEditor
                                  as="textarea"
                                  name="msg"
                                  rows="3"
                                  cols="40"
                                  placeholder="Write your message here.."
                                  style={{ marginRight: "0.5rem", width:"500px", height:"500px", row:"6" }}
                                  editor={ClassicEditor}
                                  data={text}
                                  onChange={(editor) => {
                                    const data = editor.getData();
                                    setText(data);
                                  }}
                                />
                              </div>
                            </Form.Group>
                          </Row>

                          <Row>
                            <Form.Group className="col-md-6 form-group">
                              <Form.Label
                                htmlFor="subject"
                                className="mx-2"
                                style={{ marginTop: "0.5rem" }}
                              >
                                Send by CSV File
                              </Form.Label>
                              <input
                                type={"file"}
                                id={"csvFileInput"}
                                accept={".csv"}
                                onChange={changeHandler}
                              />
                            </Form.Group>

                            <button
                              className="btn btn-sm btn-icon btn-warning my-5"
                              onClick={apiHitMsg}
                              style={{
                                width: "20%",
                                margin: "0.8rem",
                                height: "2rem",
                              }}
                            >
                              Send
                            </button>
                          </Row>
                        </Row>

                        {/* <input
                          type="checkbox"
                          id="id"
                          name="vehicle1"
                          value="AllSelect"
                          checked={
                            searchedUsers.filter((user) => !user.isChecked)
                              .length === 0
                          }
                          style={{
                            margin: "0.5rem",
                            width: "25px",
                            left: "30px",
                          }}
                          onChange={handleChange}
                        />
                        <span
                          style={{
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          Select All
                        </span> */}
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <h4 className="card-title">{userCount}</h4>
                </div>
              </Card.Header>

              <Card.Body className="px-0">
                <h4
                  className="card-title"
                  style={{
                    color: "#000",
                    marginLeft: "1rem",
                    height: "30px",
                    marginTop: "1rem",
                  }}
                >
                  Search Users...
                </h4>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="input-group rounded-md input-group-sm w-50 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="inputGroup-sizing-sm"
                      placeholder="Search User"
                      aria-label="Search User"
                      aria-describedby="button-addon2"
                      name="search"
                      style={{
                        height: "35px",
                        marginLeft: "1.5rem",
                        border: "1px solid grey",
                        borderRadius: "5px",
                      }}
                      onChange={(e) => handleChange2(e)}
                    />
                    <button
                      className="btn rounded btn-outline-secondary mb-3 mt-0.8"
                      type="button"
                      id="button-addon2"
                      style={{
                        marginLeft: "1rem",
                        marginBottom: "3rem",
                        height: "35px",
                        margin: "1px solid grey",
                      }}
                      onClick={() => Search1()}
                    >
                      Search{" "}
                    </button>
                  </div>

                  <div>
                    <button
                      className="btn btn-sm btn-primary"
                      style={{
                        marginLeft: "1rem",
                        marginBottom: "2rem",
                        height: "33px",
                      }}
                      onClick={fetchUsers}
                    >
                      Reload
                    </button>
                  </div>
                </div>

                <div className="table-responsive">
                  <table
                    id="user-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="ligth">
                        <th>Select User</th>
                        <th>Profile</th>
                        <th>Name</th>
                        <th>Contact</th>
                        <th>Email</th>
                        <th>Role</th>
                      </tr>
                    </thead>
                    <tbody>
                      {form === "email" && (
                        <>
                          {searchedUsers.map((item, idx) => (
                            <tr key={idx}>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  id={item.id}
                                  // name={item.email}
                                  checked={item?.isChecked || false}
                                  value={item.email}
                                  onChange={handleChange1}
                                />
                              </td>
                              <td className="text-center">
                                <img
                                  className="bg-soft-primary rounded img-fluid avatar-40 me-3"
                                  src={item.profilePic}
                                  alt="profile"
                                />
                              </td>
                              <td>{item.name}</td>
                              <td>{item.phone}</td>
                              <td>{item.email}</td>
                              <td>{item.role}</td>
                            </tr>
                          ))}
                        </>
                      )}
                      {form === "msg" && (
                        <>
                          {searchedUsers.map((item, idx) => (
                            <tr key={idx}>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  id={item.id}
                                  // name={item.email}
                                  checked={item?.isChecked || false}
                                  value={item.phone}
                                  onChange={handleChange1}
                                />
                              </td>
                              <td className="text-center">
                                <img
                                  className="bg-soft-primary rounded img-fluid avatar-40 me-3"
                                  src={item.profilePic}
                                  alt="profile"
                                />
                              </td>
                              <td>{item.name}</td>
                              <td>{item.phone}</td>
                              <td>{item.email}</td>
                              <td>{item.role}</td>
                            </tr>
                          ))}
                        </>
                      )}
                      {form === "csv" && (
                        <>
                          {searchedUsers.map((item, idx) => (
                            <tr key={idx}>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  id={item.id}
                                  name="users"
                                  value={item.phone}
                                  onChange={handleCheck}
                                />
                              </td>
                              <td className="text-center">
                                <img
                                  className="bg-soft-primary rounded img-fluid avatar-40 me-3"
                                  src={item.profilePic}
                                  alt="profile"
                                />
                              </td>
                              <td>{item.name}</td>
                              <td>{item.phone}</td>
                              <td>{item.email}</td>
                              <td>{item.role}</td>
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                 
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      {loader}
    </>
  );
};

export default SendMsg;



// checkbox code start here

//  {/* <table id="user-list-table" className="table table-striped">
//                     <thead>
//                       <tr>
//                         <th>Profile Pic</th>
//                         <th>Name</th>
//                         <th>Status</th>
//                         <th>Role</th>
//                         <th>Email</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {searchedUsers.map((user) => (
//                         <tr key={user._id}>
//                           <td className="text-center">
//                             <input
//                               type="checkbox"
//                               id={item.id}
//                               // name={item.email}
//                               checked={item?.isChecked || false}
//                               value={item.email}
//                               onChange={handleChange}
//                             />
//                           </td>
//                           <td>
//                             <img
//                               src={user.profilePic}
//                               alt="Profile"
//                               style={{
//                                 width: "50px",
//                                 height: "50px",
//                                 objectFit: "cover",
//                                 borderRadius: "50%",
//                               }}
//                             />
//                           </td>
//                           <td>{user.name}</td>
//                           <td>{user.active ? "Active" : "Inactive"}</td>
//                           <td>{user.role}</td>
//                           <td>{user.email}</td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table> */}


    //checkbox code end here //      







// {
//   /* <div className="table-responsive">
//                   <table
//                     id="user-list-table"
//                     className="table table-striped"
//                     role="grid"
//                     data-toggle="data-table"
//                   >
//                     <thead>
//                       <tr className="ligth">
//                         <th>Select User</th>
//                         <th>Profile</th>
//                         <th>Name</th>
//                         <th>Contact</th>
//                         <th>Email</th>
//                         <th>Role</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {form === "email" && (
//                         <>
//                           {item.map((item, idx) => (
//                             <tr key={idx}>
//                               <td className="text-center">
//                                 <input
//                                   type="checkbox"
//                                   id={item.id}
//                                   // name={item.email}
//                                   checked={item?.isChecked || false}
//                                   value={item.email}
//                                   onChange={handleChange}
//                                 />
//                               </td>
//                               <td className="text-center">
//                                 <Image
//                                   className="bg-soft-primary rounded img-fluid avatar-40 me-3"
//                                   src={item.profilePic}
//                                   alt="profile"
//                                 />
//                               </td>
//                               <td>{item.name}</td>
//                               <td>{item.phone}</td>
//                               <td>{item.email}</td>
//                               <td>{item.role}</td>
//                             </tr>
//                           ))}
//                         </>
//                       )}
//                       {form === "msg" && (
//                         <>
//                           {item.map((item, idx) => (
//                             <tr key={idx}>
//                               <td className="text-center">
//                                 <input
//                                   type="checkbox"
//                                   id={item.id}
//                                   // name={item.email}
//                                   checked={item?.isChecked || false}
//                                   value={item.phone}
//                                   onChange={handleChange1}
//                                 />
//                               </td>
//                               <td className="text-center">
//                                 <Image
//                                   className="bg-soft-primary rounded img-fluid avatar-40 me-3"
//                                   src={item.profilePic}
//                                   alt="profile"
//                                 />
//                               </td>
//                               <td>{item.name}</td>
//                               <td>{item.phone}</td>
//                               <td>{item.email}</td>
//                               <td>{item.role}</td>
//                             </tr>
//                           ))}
//                         </>
//                       )}
//                       {form === "csv" && (
//                         <>
//                           {item.map((item, idx) => (
//                             <tr key={idx}>
//                               <td className="text-center">
//                                 <input
//                                   type="checkbox"
//                                   id={item.id}
//                                   name="users"
//                                   value={item.phone}
//                                   onChange={handleCheck}
//                                 />
//                               </td>
//                               <td className="text-center">
//                                 <Image
//                                   className="bg-soft-primary rounded img-fluid avatar-40 me-3"
//                                   src={item.profilePic}
//                                   alt="profile"
//                                 />
//                               </td>
//                               <td>{item.name}</td>
//                               <td>{item.phone}</td>
//                               <td>{item.email}</td>
//                               <td>{item.role}</td>
//                             </tr>
//                           ))}
//                         </>
//                       )}
//                     </tbody>
//                   </table>
//                   <ReactPaginate
//                     pageCount={pageCount}
//                     pageRange={2}
//                     marginPagesDisplayed={2}
//                     onPageChange={handlePageChange}
//                     containerClassName={"container"}
//                     previousLinkClassName={"page"}
//                     breakClassName={"page"}
//                     nextLinkClassName={"page"}
//                     pageClassName={"pageSele"}
//                     disabledClassName={"disabled"}
//                     activeClassName={"activePage"}
//                   />
//                 </div> */
// }
// {
//   /* <Stack
//                     spacing={2}
//                     sx={{ "& .MuiPaginationItem-root": { borderRadius: 0 } }}
//                   >
//                     {currentPage !== 1 ? (
//                       <Pagination
//                         count={pageCount}
//                         page={currentPage}
//                         onChange={handlePageChange}
//                         color="grey"
//                         size="large"
//                         showFirstButton
//                         showLastButton
//                       />
//                     ) : (
//                       <Pagination
//                         count={pageCount}
//                         page={currentPage}
//                         onChange={handlePageChange}
//                         color="grey"
//                         size="large"
//                         hidePrevButton
//                         hideNextButton
//                       />
//                     )}
//                   </Stack> */
// }
