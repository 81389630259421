import React from "react";

//router
import { Switch, Route,Redirect } from "react-router";
//errorHandler
import ErrorHandler from "../views/dashboard/errors/errorHandler";
//layoutpages
import Index from "../views/index";
import Default from "../layouts/dashboard/default";
import Horizontal from "../layouts/dashboard/horizontal";
import Boxed from "../layouts/dashboard/boxed";
import DualHorizontal from "../layouts/dashboard/dual-horizontal";
import DualCompact from "../layouts/dashboard/dual-compact";
import BoxedFancy from "../layouts/dashboard/boxed-fancy";
import Simple from "../layouts/dashboard/simple";
import SignIn from "../views/dashboard/auth/sign-in";

const IndexRouters = () => {
  const isAuthenticated = ErrorHandler();
  return (
    <>
      <Switch>
        <Route exact path="/" component={SignIn}></Route>
        {/* Only allow access to the dashboard when authenticated */}
        <Route path="/dashboard">
          {isAuthenticated ? <Default /> : <Redirect to="/" />}
        </Route>
        {/* Other routes are accessible regardless of authentication status */}
        <Route path="/boxed" component={Boxed}></Route>
        <Route path="/horizontal" component={Horizontal}></Route>
        <Route path="/dual-horizontal" component={DualHorizontal}></Route>
        <Route path="/dual-compact" component={DualCompact}></Route>
        <Route path="/boxedFancy" component={BoxedFancy}></Route>
        <Route path="/auth" component={Simple}></Route>
        <Route path="/errors" component={Simple}></Route>
      </Switch>
    </>
  );
};

export default IndexRouters;
