import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Card from "../../../components/Card";
import { getApi } from "../../../backend";

const ReferalGen = () => {
  const [email, setEmail] = useState();

  const apiHit = async () => {
    // console.log(email);
    let url = `${getApi()}/auth/referal`;
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
        }`,
      },
      body: JSON.stringify({
        email: email,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        alert(json.message);
      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
      });
  };
  // console.log(email)
  return (
    <div>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Generate Referals</h4>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              <div className="new-user-info" style={{ marginLeft: "2rem" }}>
                <Form>
                  <h5 className="mb-3">Enter Email</h5>
                  <Form.Group className="col-md-6 form-group">
                    <Form.Control
                      type="email"
                      id="rpass"
                      placeholder="Email"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                  <Button type="button" variant="btn btn-primary" onClick={apiHit}>
                    Generate Referal
                  </Button>
                </Form>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ReferalGen;
