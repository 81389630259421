import React from "react";
import "./css/loading.css";
import {Puff} from "react-loader-spinner";

const LoaderSpiner = () => {
  return (
    <div className="fp-container">
      <div className="fp-loader">
        <Puff type="Puff" color="#EA6A12" height={200} width={200} />
      </div>
    </div>
  );
};
export default LoaderSpiner;
