import React, { useState } from 'react';
import LoaderSpiner from '../views/dashboard/app/loading';

const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false);
    return [
        loading ? <LoaderSpiner /> : null,
        () => setLoading(true), //Show Loader
        () => setLoading(false) //Hide Loader
    ];
};
export default useFullPageLoader



