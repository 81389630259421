import React from 'react'
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { tw } from "twind"
import axios from 'axios';
import { getApi } from '../backend';
import { toast } from "react-hot-toast"

const DeleteModal = ({ isOpen, openModal, closeModal, admin, refetch }) => {
    // console.log(admin)
    const handleDelete = (id) => {

        if (admin.email === "sunny@playwise.gg") {
            toast.error("You cannot delete the super admin")
            closeModal()
            return
        }

        axios.delete(`${getApi()}/auth/admin/${id}`, {
            headers: {
                Authorization: `Bearer ${typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))}`
            }
        }).then((res) => {
            console.log(res)
            if (res.data.success) {
                toast.success("Admin deleted successfully")
                closeModal()
            }
            else {
                toast.error("Something went wrong")

                closeModal()
            }
            refetch()


        }).catch((err) => {
            console.log(err)
            toast.error(err.message)
            closeModal()
            refetch()
        })
    };

    return (
        <div><Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className={tw("relative z-10 text-xl")} onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter={tw("ease-out duration-300")}
                    enterFrom={tw("opacity-0")}
                    enterTo={tw("opacity-100")}
                    leave={tw("ease-in duration-200")}
                    leaveFrom={tw("opacity-100")}
                    leaveTo={tw("opacity-0")}
                >
                    <div className={tw("fixed inset-0 bg-black bg-opacity-25")} />
                </Transition.Child>

                <div className={tw("fixed inset-0 overflow-y-auto")}>
                    <div className={tw("flex min-h-full items-center justify-center p-4 text-center")}>
                        <Transition.Child
                            as={Fragment}
                            enter={tw("ease-out duration-300")}
                            enterFrom={tw("opacity-0 scale-95")}
                            enterTo={tw("opacity-100 scale-100")}
                            leave={tw("ease-in duration-200")}
                            leaveFrom={tw("opacity-100 scale-100")}
                            leaveTo={tw("opacity-0 scale-95")}
                        >
                            <Dialog.Panel className={tw("w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all")}>
                                <Dialog.Title
                                    as="h3"
                                    className={tw("text-xl font-medium leading-6 text-gray-900")}
                                >
                                    Delete Admin
                                </Dialog.Title>
                                <div className={tw("mt-2")}>
                                    <p className={tw("text-lg text-gray-500")}>
                                        Are you sure you want to delete {admin?.name}? This action cannot be undone.
                                    </p>
                                </div>

                                <div className={tw("mt-4")}>
                                    <button
                                        type="button"
                                        className={tw("inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-lg font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2")}
                                        onClick={() => handleDelete(admin?._id)}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition></div>
    )
}

export default DeleteModal