import React, { useEffect, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Card from "../../../components/Card";
import { HiOutlineBan } from "react-icons/hi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  getReportedUsers,
  banUser,
  deleteUser,
  unbanUser,
} from "../../../Hooks/userHooks";
import { BsCircle } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import useFullPageLoader from "../../../Hooks/useFullPageLoader";
import { Stack } from "@mui/material";
import { Pagination } from "@mui/material";

const UserReported = () => {
  const initData = Object.freeze([
    {
      userId: {
        email: "loading...",
        profilePic: "loading...",
        username: "loading...",
      },
    },
  ]);
  const [item, setItem] = useState(initData);
  const history = useHistory();
  const [userCount, setUserCount] = useState();
  // const [active, setActive] = useState(true);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term

  const fetchUsers = () => {
    showLoader();
    getReportedUsers().then((res) => {
      if (res.success === false) {
        setUserCount("Error: " + res.message);
        hideLoader();
      } else {
        setItem(res.data.reportedUsers);
        setPageCount(res.data.pages);
        setUserCount("User Count: " + res.data.reportedUsers.length);
        hideLoader();
      }
    });
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    fetchUsers();
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const banUserById = async (id) => {
    banUser(id).then((res) => {
      fetchUsers();
      alert(res.message);
    });
  };

  const unbanUserById = async (id) => {
    unbanUser(id).then((res) => {
      fetchUsers();
      alert(res.message);
    });
  };

  const delUser = async (id) => {
    deleteUser(id).then((res) => {
      fetchUsers();
      alert(res.message);
    });
  };

  const nav = (id) => {
    history.push(`/dashboard/app/reported-user-profile/${id}`);
  };

  // Function to handle search term change
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter the reported users based on the search term
  const filteredItems = item.filter((item) => {
    return (
      item.userId &&
      (item.userId.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.userId.email.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  });

  return (
    <>
      <div>
        <Row>
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Reported Users</h4>
                </div>
                  {/* Search Bar */}
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="inputGroup-sizing-sm"
                      aria-label="Search reported users"
                      aria-describedby="button-addon2"
                      name="search"
                      style={{
                        height: "35px",
                        width: "500px",
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                        border: "1px solid grey",
                      }}
                      placeholder="Search reported users..."
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                  </div>
                <div>
                  <h4 className="card-title">{userCount}</h4>
                  <span style={{  display:"flex", alignItems:"center", justifyContent:"center"  }} >
                    <button
                      className="btn btn-sm btn-primary"
                      style={{marginTop:"1rem"}}
                      onClick={fetchUsers}
                    >
                      Reload
                    </button>
                  </span>
                </div>
                
              </Card.Header>
              <Card.Body className="px-0">
                <div className="table-responsive">
                  {/* Table */}
                  <table
                    id="user-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="light">
                        <th>Profile</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Contact</th>
                        <th>Email</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredItems.map((item, idx) => (
                        <tr key={idx}>
                          <td className="text-center">
                            <Image
                              className="bg-soft-primary rounded img-fluid avatar-40 me-3"
                              src={item.userId && item.userId.profilePic}
                              alt="profile"
                            />
                          </td>
                          <td>
                            {item.userId ? (
                              <p
                                style={{ color: "orange", cursor: "pointer" }}
                                onClick={() => nav(item.userId._id)}
                              >
                                {item.userId.username}
                              </p>
                            ) : (
                              <p>Null</p>
                            )}
                          </td>
                          <td>
                            {item.userId ? (
                              <p>
                                {item.userId.active
                                  ? "Active"
                                  : item.userId.ban
                                  ? "Banned"
                                  : "Deleted"}
                              </p>
                            ) : (
                              <p>Null</p>
                            )}
                          </td>
                          <td>
                            {item.userId ? item.userId.phone : <p>Null</p>}
                          </td>
                          <td>
                            {item.userId ? item.userId.email : <p>Null</p>}
                          </td>
                          <td>
                            <div className="flex align-items-center list-user-action mx-1">
                              {item.userId && item.userId.active ? (
                                <Link
                                  className="btn btn-sm btn-icon btn-warning mx-2"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title=""
                                  data-original-title="Add"
                                  to="#"
                                  onClick={() => banUserById(item.userId._id)}
                                >
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="tooltip-disabled">
                                        Ban
                                      </Tooltip>
                                    }
                                  >
                                    <span className="btn-inner">
                                      <HiOutlineBan size={23} />
                                    </span>
                                  </OverlayTrigger>
                                </Link>
                              ) : (
                                <Link
                                  className="btn btn-sm btn-icon btn-info mx-1"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title=""
                                  data-original-title="Add"
                                  to="#"
                                  onClick={() => unbanUserById(item.userId._id)}
                                >
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="tooltip-disabled">
                                        Unban
                                      </Tooltip>
                                    }
                                  >
                                    <span className="btn-inner">
                                      <BsCircle size={23} />
                                    </span>
                                  </OverlayTrigger>
                                </Link>
                              )}
                              {item.userId ? (
                                <Link
                                  className="btn btn-sm btn-icon btn-success"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title=""
                                  data-original-title="Edit"
                                  to="#"
                                  onClick={() => nav(item.userId._id)}
                                >
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="tooltip-disabled">
                                        View
                                      </Tooltip>
                                    }
                                  >
                                    <span className="btn-inner">
                                      <AiOutlineEye size={23} />
                                    </span>
                                  </OverlayTrigger>
                                </Link>
                              ) : (
                                <p></p>
                              )}
                              {item.userId ? (
                                <Link
                                  className="btn btn-sm btn-icon btn-danger mx-2"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title=""
                                  data-original-title="Delete"
                                  to="#"
                                  onClick={() => delUser(item.userId._id)}
                                >
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="tooltip-disabled">
                                        Delete
                                      </Tooltip>
                                    }
                                  >
                                    <span className="btn-inner">
                                      <svg
                                        width="20"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        stroke="currentColor"
                                      >
                                        <path
                                          d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                        <path
                                          d="M20.708 6.23975H3.75"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                        <path
                                          d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                      </svg>
                                    </span>
                                  </OverlayTrigger>
                                </Link>
                              ) : (
                                <p></p>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", padding: "8px" }}
      >
        <Stack
          spacing={2}
          sx={{ "& .MuiPaginationItem-root": { borderRadius: 0 } }}
        >
          {currentPage !== 1 ? (
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={handlePageChange}
              color="grey"
              size="large"
              showFirstButton
              showLastButton
            />
          ) : (
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={handlePageChange}
              color="grey"
              size="large"
              hidePrevButton
              hideNextButton
            />
          )}
        </Stack>
      </div>
      {loader}
    </>
  );
};

export default UserReported;
