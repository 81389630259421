import React, { useState, useEffect } from "react";
import FsLightbox from "fslightbox-react";
import { Row, Col, Image, Form, Nav, Dropdown, Tab } from "react-bootstrap";
import Card from "../../../components/Card";
import { Link, useParams } from "react-router-dom";
// img
import avatars11 from "../../../assets/images/avatars/01.png";
import avatars22 from "../../../assets/images/avatars/avtar_1.png";
import avatars33 from "../../../assets/images/avatars/avtar_2.png";
import avatars44 from "../../../assets/images/avatars/avtar_3.png";
import avatars55 from "../../../assets/images/avatars/avtar_4.png";
import avatars66 from "../../../assets/images/avatars/avtar_5.png";
import avatars2 from "../../../assets/images/avatars/02.png";
import avatars3 from "../../../assets/images/avatars/03.png";
import avatars4 from "../../../assets/images/avatars/04.png";
import avatars5 from "../../../assets/images/avatars/05.png";

import icon1 from "../../../assets/images/icons/01.png";
import icon2 from "../../../assets/images/icons/02.png";
import icon4 from "../../../assets/images/icons/04.png";
import icon8 from "../../../assets/images/icons/08.png";

import icon5 from "../../../assets/images/icons/05.png";
import shap2 from "../../../assets/images/shapes/02.png";
import shap4 from "../../../assets/images/shapes/04.png";
import shap6 from "../../../assets/images/shapes/06.png";
import pages2 from "../../../assets/images/pages/02-page.png";

import ShareOffcanvas from "../../../components/partials/components/shareoffcanvas";
import axios from "axios";
import { getApi } from "../../../backend";

const UserAllPost = () => {
  const initData = Object.freeze({
    accountType: "",
    bio: "",
    coverPic: "",
    createdAt: "",
    email: "",
    followers: "",
    followings: "",
    name: "",
    numberofPosts: "",
    profilePic: "",
    role: "",
    socialLinks: "",
    username: "",
    systemDetail: "",
  });
  const [toggler, setToggler] = useState(false);
  const [user, setUser] = useState(initData);

  const [postUpdate, setPostUpdate] = useState("");
  const [post, setPost] = useState([]);
  const [userUpdate, setUsertUpdate] = useState({});
  const [socialLinks, setSocialLinks] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    userData();
    userPosts();
  }, []);

  const userData = async () => {
    const config = {
      method: "get",
      url: `${getApi()}/auth/users/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
        }`,
      },
    };
    try {
      const res = await axios(config);
      setUsertUpdate(res.data.data._id);
      // console.log(res.data);
      setUser(res.data.data);
      setSocialLinks(res.data.data.socialLinks);
    } catch (error) {
      // console.log(error);
    }
  };

  // console.log(userUpdate);
  const userPosts = async () => {
    const config = {
      method: "get",
      url: `${getApi()}/post/posts/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
        }`,
      },
    };
    try {
      const res = await axios(config);
      // setUser(res.data.data);
      setPost(res.data.data);
      setPostUpdate(res.data.data[res.data.data.length - 1].createdAt);
      // console.log(res.data.data);
      // setSocialLinks(res.data.data.socialLinks)
    } catch (error) {
      // console.log(error);
    }
  };

  const format = (dateString) => {
    const options = { day: "numeric", month: "numeric", year: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  // console.log("user", post);
  // console.log("socialLinks", userUpdate)
  return (
    <>
      <Tab.Container defaultActiveKey="first">
        <Row>
          <Col lg="12">
            <Card>
              <Card.Body>
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <div className=
                  
                  
                  "d-flex flex-wrap align-items-center">
                    <div className="profile-img position-relative me-3 mb-3 mb-lg-0 profile-logo profile-logo1">
                      <Image
                        className="theme-color-yellow-img img-fluid rounded-pill avatar-100"
                        src={user.profilePic}
                        alt="profile-pic"
                      />
                    </div>
                    <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                      <h4 className="me-2 h4">{user.name}</h4>
                      <span> - {user.email}</span>
                    </div>
                  </div>
                  <Nav
                    as="ul"
                    className="d-flex nav-pills mb-0 text-center profile-tab"
                    data-toggle="slider-tab"
                    id="profile-pills-tab"
                    role="tablist"
                  >
                    <Nav.Item as="li">
                      <Nav.Link eventKey="first">Profile</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item as="li">
                      <Nav.Link eventKey="fourth">Feed</Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link eventKey="second">Activity</Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link eventKey="third">Friends</Nav.Link>
                    </Nav.Item> */}
                  </Nav>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="12" className="col-lg-3">
            <Card>
              <Card.Header className="d-flex align-items-center justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Posts</h4>
                </div>
                <span>{post.length} Posts</span>
                {/* <span> Posts</span> */}
              </Card.Header>
              <Card.Body>
                <div className="d-grid justify-content-start gap-card grid-cols-4">
                  {/* {post.map((pt)=>(
                                        console.log(pt.media.length !== '0' ? pt.media.map((media)=>(media)) : "0")
                                    ))}      */}
                  {post.length !== 0 ? (
                    post.map((pt) => (
                      <Link
                        to={`/dashboard/app/user-reported-details/${pt._id}`}
                        onClick={() => setToggler(!toggler)}
                      >
                        <div
                          className="rounded d-flex flex-column align-items-center"
                          style={{
                            height: "160px",
                            backgroundColor: "#fce9de",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            className="w-100 align-items-center h-100 my-2"
                            style={{ marginLeft: "50%" }}
                          >
                            {pt.media.length !== "0" &&
                              pt.media.map((media) => (
                                // media.url
                                <>
                                  {/* <Link to={`/dashboard/app/user-reported-details/${pt._id}`} onClick={() => setToggler(!toggler)}> */}
                                  <Image
                                    src={media.url}
                                    style={{ width: "120px", height: "120px" }}
                                    className="img-fluid bg-soft-info rounded"
                                    alt="profile-image"
                                  />
                                  {/* {media} */}
                                  {/* </Link> */}
                                </>
                              ))}
                          </span>

                          <span className="d-flex align-items-center color-dark">
                            {/* <Link to={`/dashboard/app/user-reported-details/${pt._id}`} onClick={() => setToggler(!toggler)}> */}
                            <p className="text-dark">{pt.caption}</p>
                            {/* </Link> */}
                            {/* <p className="text-dark">{pt.caption}</p> */}
                          </span>
                        </div>
                      </Link>
                    ))
                  ) : (
                    <>
                      <h2>No post Found</h2>
                    </>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default UserAllPost;
