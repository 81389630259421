import React, { useEffect, useState } from "react";
import { getAllPosts } from "../../../Hooks/postHooks";
import useFullPageLoader from "../../../Hooks/useFullPageLoader";
import { useHistory } from "react-router-dom";
import Card from "../../../components/Card";
import { Row, Col, Image } from "react-bootstrap";

const PostList = () => {
  const [posts, setPosts] = useState([]);
  const [postCount, setPostCount] = useState(0);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const fetchPosts = () => {
    console.log("Fetching posts");
    showLoader();
    getAllPosts()
      .then((response) => {
        const data = response.data;
        console.log("API Response:", data);
        setPosts(data.posts);
        setPostCount(data.total);
        hideLoader();
      })
      .catch((error) => {
        console.error("Error fetching posts:", error);
        setPosts([]);
        setPostCount(0);
        hideLoader();
      });
  };

  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      setSearchResults([]);
      return;
    }

    const filteredPosts = posts.filter((post) =>
      post.userId.username.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setSearchResults(filteredPosts);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setSearchResults([]);
  };

  const truncateCaption = (caption, wordsCount) => {
    const words = caption.split(" ");
    if (words.length > wordsCount) {
      return words.slice(0, wordsCount).join(" ");
    }
    return caption;
  };

  const nav = (id) => {
    history.push(`/dashboard/app/post-details/${id}`);
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  console.log("Posts state:", posts);
  console.log("Post count state:", postCount);

  return (
    <>
      <div>
        <Row>
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Post List</h4>
                  <span>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={fetchPosts}
                    >
                      Reload
                    </button>
                  </span>
                </div>
                <div className="input-group rounded-md input-group-sm w-50 mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="inputGroup-sizing-sm"
                    placeholder="Search By Name"
                    aria-label="Search Caption"
                    aria-describedby="button-addon2"
                    name="search"
                    style={{
                      height: "35px",
                    }}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <button
                    className="btn rounded btn-outline-secondary mb-3 mt-0.8"
                    type="button"
                    id="button-addon2"
                    style={{
                      fontSize: "15px",
                      marginLeft: "1rem",
                      marginBottom: "3rem",
                      height: "35px",
                      margin: "1px solid grey",
                    }}
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                  <button
                    className="btn rounded btn-outline-secondary mb-3 mt-0.8"
                    type="button"
                    style={{
                      fontSize: "15px",
                      marginLeft: "1rem",
                      marginBottom: "3rem",
                      height: "35px",
                      margin: "1px solid grey",
                    }}
                    onClick={handleClearSearch}
                  >
                    Clear
                  </button>
                </div>
              </Card.Header>
              <Card.Body className="px-0">
                <div className="table-responsive">
                  <table
                    id="post-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="ligth">
                        <th>Media</th>
                        <th>UserName</th>
                        <th>Caption</th>
                        <th>Created At</th>
                        <th>Visibility</th>
                        <th>Number of Post</th>
                        <th>Media Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(searchResults.length > 0 ? searchResults : posts).map(
                        (post) => {
                          return (
                            <tr key={post._id}>
                              <td
                                className="text-center"
                                onClick={() => nav(post._id)}
                                style={{ color: "orange", cursor: "pointer" }}
                              >
                                {post.mediaType === "text" ? (
                                  <span>No media</span>
                                ) : (
                                  <Image
                                    className="bg-soft-primary rounded img-fluid avatar-40 me-3"
                                    src={post.userId.profilePic}
                                    alt="post media"
                                  />
                                )}
                              </td>
                              <td>{post.userId.username}</td>
                              <td>
                                {truncateCaption(post.caption, 10)}
                                {post.caption.split(" ").length > 10 && (
                                  <button
                                    className="btn btn-link p-0 ml-2"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      alert(post.caption);
                                    }}
                                  >
                                    ...
                                  </button>
                                )}
                              </td>
                              <td>
                                {new Date(post.createdAt)
                                  .getDate()
                                  .toString()
                                  .padStart(2, "0")}
                                -
                                {(new Date(post.createdAt).getMonth() + 1)
                                  .toString()
                                  .padStart(2, "0")}
                                -{new Date(post.createdAt).getFullYear()}
                              </td>
                              <td>{post.visibility}</td>
                              <td>{post.tags.length}</td>
                              <td>{post.mediaType}</td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      {loader}
    </>
  );
};

export default PostList;
