import React , {useState} from 'react';
import axios from 'axios';


const createRewards = () => {

    const rewardType = ["playstore", "gamecredits", "task", 'ads', 'giftcardsandcoupons', 'spinwheel', 'zomato', 'merchandise', 'others'] // to be decided 


  const [rewardData, setRewardData] = useState({
        name: '',
        rewardType: '',
        disabled: false,
        description: '',
        provider: '',
        redeem: {
          amount: 0,
          type: '',
          currency: ''
        },
        spend: {
          amount: 0,
          type: '',
          currency: ''
        },
        stock: 0,
        expiresAt: '',
        media: [], // Initialize media as an empty array
        opcode: ''
      });

      const [formErrors, setFormErrors] = useState({});

    const handleInputChange = (event) => {
    const { name, value } = event.target;

    setRewardData((prevData) => ({ ...prevData, [name]: value }));
    setFormErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
          newErrors[name] = '';
    
        return newErrors;
      });
  };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await axios.post('/api/rewards', rewardData); // Adjust the URL accordingly
          console.log(response.data); // Log the newly created reward
          // You can add further actions after successful creation, such as displaying a success message or redirecting the user
        } catch (error) {
          console.error('Error creating reward:', error);
          // Handle errors, such as displaying an error message to the user
        }
      };
  return (
    <div className="bg-white rounded-md relative z-10">
        <div className="pt-6 px-6 text-2xl font-bold text-black">
          <h4>Add Rewards</h4>
        </div>

        <form  onSubmit= {handleSubmit} className="p-5 flex flex-wrap">

        <div className="w-full md:w-1/2 px-3 mb-4">
            <label htmlFor="rewardName" className="text-gray-700 text-md  mb-2 block">
              Reward Name:
            </label>
            <input
              name="name"
              type="text"
              value={rewardData.name}
              onChange={handleInputChange}
              placeholder="Reward Name"
              className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm py-3 px-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
            {/* {formErrors.organiserName && (
              <p className="text-red-500 text-sm">{formErrors.organiserName}</p>
            )} */}
          </div>

          <div className="w-full md:w-1/2 px-3 mb-4">
                    <label htmlFor="rewardTtype" className="text-gray-700 text-md  mb-2 block">
                        Reward Type:
                    </label>
                    <select
                        name="rewardType"
                        value={rewardData.rewardType}
                        onChange={handleInputChange}
                        className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm p-1.5 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    >
                        <option value="">Select Reward Type</option>
                        {rewardType.map((rewards, index) => (
                            <option key={index} value={rewards}>
                                {rewards}
                            </option>
                        ))}
                    </select>


                    {/* {formErrors.platform && (
              <p className="text-red-500 text-sm">{formErrors.platform}</p>
            )} */}
                </div>

                <div className="w-full md:w-1/2 px-3 mb-4">
            <label htmlFor="code" className="text-gray-700 text-md  mb-2 block">
              Code:
            </label>
            <input
              name="name"
              type="text"
              value={rewardData.opcode}
              onChange={handleInputChange}
              placeholder="opcode"
              className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm py-3 px-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
            {/* {formErrors.organiserName && (
              <p className="text-red-500 text-sm">{formErrors.organiserName}</p>
            )} */}
          </div>


          <div className="w-full md:w-1/2 px-3 mb-4">
                    <label
                        htmlFor="description"
                        className="text-gray-700 text-md mb-2"
                    >
                        Description:
                    </label>
                    <textarea
                        name="description"
                        id="description"
                        value={rewardData.description}
                        onChange={handleInputChange}
                        placeholder="Enter game description"
                        className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm p-1.5 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                        rows="4"
                    ></textarea>
                    {/* {formErrors.description && (
                        <p className="text-red-500 text-sm">{formErrors.description}</p>
                    )} */}
                </div>


        </form>

    </div>
  )
}

export default createRewards
