import axios from "axios";
import { getApi } from "../backend";

export const banUser = async (id) => {
  let url = `${getApi()}/auth/ban/${id}`;
  await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
      }`,
    },
    body: JSON.stringify({
      status: false,
    }),
  })
    .then((response) => response.json())
    .then((json) => {
      alert(json.message);
    })
    .catch((err) => {
      alert(err.message);
    });
};

export const unbanUser = async (id) => {
  let url = `${getApi()}/auth/ban/${id}`;
  await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
      }`,
    },
    body: JSON.stringify({
      status: true,
    }),
  })
    .then((response) => response.json())
    .then((json) => {
      alert(json.message);
    })
    .catch((err) => {
      alert(err.message);
    });
};

export const deleteUser = async (id) => {
  const config = {
    method: "put",
    url: `${getApi()}/auth/delete/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
      }`,
    },
  };

  try {
    const res = await axios(config);
    // console.log(res);
    return res.data;
  } catch (error) {
    // console.log(error, "users");
    return error;
  }
};

export const getusers = async (pg) => {
  const config = {
    method: "get",
    url: `${getApi()}/auth/users?page=${pg}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
      }`,
    },
  };

  try {
    const res = await axios(config);
    return res.data.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getdummyusers = async () => {
  const config = {
    method: "get",
    url: `${getApi()}/auth/dummy-users`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
      }`,
    },
  };

  try {
    const res = await axios(config);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getdeletedusers = async () => {
  const config = {
    method: "get",
    url: `${getApi()}/auth/deleted-users`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
      }`,
    },
  };

  try {
    const res = await axios(config);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getReportedUsers = async () => {
  const config = {
    method: "get",
    url: `${getApi()}/auth/reportedUsers`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
      }`,
    },
  };

  try {
    const res = await axios(config);
    // console.log(res.data)
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const userInfo = async (id) => {
  // console.log("API ID", id)
  const config = {
    method: "get",
    url: `${getApi()}/auth/users/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
      }`,
    },
  };

  try {
    const res = await axios(config);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const reportedUserInfo = async (id) => {
  const config = {
    method: "get",
    url: `${getApi()}/auth/reportedUser/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
      }`,
    },
  };

  try {
    const res = await axios(config);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const SearchUser = async (user) => {
  const Data = { keyword: user.search };
  const config = {
    method: "POST",
    url: `${getApi()}/auth/users/search`,
    data: Data,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
      }`,
    },
  };
  try {
    // console.log(config);
    const res = await axios(config);
    if (res.status === 200) {
      // console.log(res.data);
      return res.data;
    }
  } catch (error) {
    return error.response.data;
  }
};
