import React, { useEffect, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import Card from "../../../components/Card";
import {
  getTournaments,
  deleteTour,
  getModalData,
} from "../../../Hooks/tournamentHooks";
import useFullPageLoader from "../../../Hooks/useFullPageLoader";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { AiOutlineEye } from "react-icons/ai";
import { Link, useHistory } from "react-router-dom";
import jsPDF from "jspdf";
import { Toaster, toast } from "react-hot-toast";
import "./Paginate/Paginate.css";
import Modal from "./MultipleStageModal";
import { tw } from "twind";
import ViewStageModal from "./view-stage-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/Button";
import jwt_decode from "jwt-decode";
import axios from "axios";
import LoaderSpiner from "./loading";
import { getApi } from "../../../backend";
import moment from "moment";
import { convertUTCtoISTForDisplay } from "./dateFormat.js";

const TournamentsList = () => {
  const initData = Object.freeze([
    {
      eventName: "loading...",
      gameName: "loading...",
      rewards: "loading...",
      registration: {
        open: "loading",
        close: "loading",
      },
      nature: "loading...",
      link: "loading...",
      contact: "loading...",
      cover: "loading...",
    },
  ]);

  const [item, setItem] = useState(initData);
  const [tournamentCount, setTournamentCount] = useState();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  let history = useHistory();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [sizes, setSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [stages, setStages] = useState(0);
  const [filter, setFilter] = useState("all");
  const [tourId, setTourId] = useState("");
  const [viewModal, setViewModal] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [allTournaments, setAllTournaments] = useState([]);
  const [loading, setLoading] = useState(true);

  const formatDateToYYMMDD = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const currentDate = new Date();

  const handleDateFilter = () => {
    // Reset the filter to show all tournaments
    setItem(allTournaments);
    setTournamentCount();

    // Format the start and end dates to yy-mm-dd format
    const formattedStartDate = startDate ? formatDateToYYMMDD(startDate) : null;
    const formattedEndDate = endDate ? formatDateToYYMMDD(endDate) : null;

    // Filter the tournaments based on start and end dates
    if (formattedStartDate && formattedEndDate) {
      const filtered = allTournaments.filter((tournament) => {
        const tournamentStartDate = formatDateToYYMMDD(
          tournament.registration.open
        );
        const tournamentEndDate = formatDateToYYMMDD(
          tournament.registration.close
        );

        return (
          tournamentStartDate >= formattedStartDate &&
          tournamentEndDate <= formattedEndDate
        );
      });

      setItem(filtered);
      setTournamentCount("Tournament Count: " + filtered.length);
    }
  };

  const handleDateReset = () => {
    setStartDate(null);
    setEndDate(null);
    // Reset the filter to show all tournaments
    setItem(allTournaments);
    setTournamentCount();
  };

  const handleChange = (event) => {
    event.preventDefault();
    setFilter(event.target.value);
    setSearchKeyword("");
    setPage(1);
  };

  console.log(allTournaments);

  const fetchTournament = () => {
    showLoader();
    getTournaments(page, sizes).then((res) => {
      console.log("Data fetched by Madhav", res);
      if (res.success === false) {
        setTournamentCount("Error: " + res.message);
        hideLoader();
      } else {
        setAllTournaments(res.data.tournaments);

        if (filter === "all") {
          setItem(res.data.tournaments);
          setCount(res.data.total);
          setTournamentCount(
            "Tournament Count: " + res.data.tournaments.length
          );
        } else if (filter === "open") {
          let sorted = res.data.tournaments.filter(
            (item) => item.status === "open"
          );
          setItem(sorted);
          setCount(sorted.length);
          setTournamentCount("Tournament Count: " + sorted.length);
        } else if (filter === "closed") {
          let sorted = res.data.tournaments.filter(
            (item) => item.status === "closed"
          );
          setItem(sorted);
          setCount(sorted.length);
          setTournamentCount("Tournament Count: " + sorted.length);
        } else if (filter === "organizer") {
          let filtered = res.data.tournaments.filter((item) => {
            return item.organiser.name
              .toLowerCase()
              .includes(searchKeyword.toLowerCase());
          });
          setItem(filtered);
          setCount(filtered.length);
          setTournamentCount("Tournament Count: " + filtered.length);
        }
        hideLoader();
      }
    });
  };

  const getAllTournament = async () => {
    const allTour = await axios({
      url: `${getApi()}/tournament/all?&page=${page}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? JSON.parse(localStorage.getItem("token"))
            : ""
        }`,
      },
    });

    console.log(allTour);
    setAllTournaments(allTour?.data?.data?.tournaments);
    setLoading(false);
  };

  useEffect(() => {
    // fetchTournament();
    getAllTournament();
  }, [page, sizes, filter, searchKeyword]);

  const pages = Math.ceil(count / sizes);

  // console.log("count", count);

  const nav = (id) => {
    history.push(`/dashboard/app/tournaments-details/${id}`);
  };

  const getLoggedInUserDetails = () => {
    const token = localStorage.getItem("token"); // Assuming the token is stored in localStorage
    if (token) {
      const decodedToken = jwt_decode(token);
      // console.log("ankit", decodedToken);

      return decodedToken; // Assuming the user ID is stored in the token's payload
    }
    return null;
  };

  // const loggedInUserEmail = getLoggedInUserDetails().email;
  // const adminRole = getLoggedInUserDetails().role;

  const delTour = async (item) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (isConfirmed) {
      try {
        const response = await axios({
          url: `${getApi()}/tournament/del/${item}`,
          method: "delete",
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? JSON.parse(localStorage.getItem("token"))
                : ""
            }`,
          },
        });

        if (response.data.success) {
          toast.success(response.data.message);
          getAllTournament();
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  if (loading) {
    return <LoaderSpiner />;
  }

  const print = () => {
    const pdf = new jsPDF("p", "pt", "a4");
    const columns = ["S.No.", "Code", "Email", "Created At", "Refer Count"];
    var rows = [];
    var count = 1;
    for (let i = 0; i < item.length; i++) {
      var temp = [
        count++,
        item[i].eventName,
        new String(item[i].registration.open).split("T")[0],
        new String(item[i].registration.close).split("T")[0],
        item[i]?.organiser?.name,
      ];
      rows.push(temp);
    }
    pdf.text(235, 40, "Playwise Referals List");
    pdf.autoTable(columns, rows, {
      startY: 65,
      theme: "grid",
      styles: {
        font: "times",
        halign: "center",
        cellPadding: 3.5,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        textColor: [0, 0, 0],
      },
      headStyles: {
        textColor: [0, 0, 0],
        fontStyle: "normal",
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [234, 106, 18],
      },
      alternateRowStyles: {
        fillColor: [212, 212, 212],
        textColor: [0, 0, 0],
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
      },
      rowStyles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
      },
      tableLineColor: [0, 0, 0],
    });
    // console.log(pdf.output("datauristring"));
    pdf.save("pdf");
  };

  console.log(filter, "filter");

  return (
    <>
      <div>
        <Toaster />
        <Row>
          <Col sm="12">
            <Card>
              <Card.Header
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="header-title">
                  <h4 className="card-title">Tournament List</h4>
                  <span>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={fetchTournament}
                    >
                      Reload
                    </button>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={print}
                      style={{ marginLeft: "15px" }}
                    >
                      Export
                    </button>
                  </span>
                  <h4 style={{ marginTop: "15px" }} className="card-title">
                    {tournamentCount}
                  </h4>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <span style={{ margin: "0px", width: "70px" }}>
                      Filter by:
                    </span>
                    <select
                      value={filter}
                      onChange={handleChange}
                      style={{ marginRight: "5px" }}
                    >
                      <option value="all">All</option>
                      <option value="open">Open</option>
                      <option value="closed">Closed</option>
                      <option value="organizer">Organizer</option>
                    </select>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <span style={{ margin: "0px", width: "70px" }}>
                        Filter by Date:
                      </span>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        placeholderText="Start Date"
                        dateFormat="yyyy-MM-dd"
                      />
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        placeholderText="End Date"
                        dateFormat="yyyy-MM-dd"
                      />
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={handleDateFilter}
                      >
                        Apply
                      </button>
                      <button
                        className="btn btn-sm btn-secondary"
                        onClick={handleDateReset}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </Card.Header>

              <Card.Body className="px-0">
                {item?.length === 0 ? (
                  <>
                    <h3 className="mx-4">No Tournament data Found</h3>
                  </>
                ) : (
                  <>
                    {/* <Card.Body> */}
                    <div className="table-responsive">
                      <table
                        id="user-list-table"
                        className="table table-striped"
                        role="grid"
                        data-toggle="data-table"
                      >
                        <thead>
                          <tr className="ligth">
                            <th>Cover</th>
                            <th>Tournament Name</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Contact Name</th>
                            <th>Status</th>
                            <th>Nature</th>
                            <th min-width="100px">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allTournaments?.map((item, idx) => (
                            <tr key={idx}>
                              <td
                                className="text-center"
                                onClick={() => nav(item._id)}
                                style={{ cursor: "pointer" }}
                              >
                                <Image
                                  className="bg-soft-primary rounded img-fluid avatar-40 me-3"
                                  src={item.banner}
                                  alt="profile"
                                />
                              </td>
                              <td
                                onClick={() => nav(item._id)}
                                style={{ cursor: "pointer" }}
                              >
                                {item.eventName}
                              </td>

                              {item.startTime === undefined ? (
                                <td>No Data</td>
                              ) : (
                                <td>{item?.registration?.open.slice(0, 10)}</td>
                              )}
                              {item.registration === undefined ? (
                                <td>No Data</td>
                              ) : (
                                <td>
                                  {item?.registration?.close.slice(0, 10)}
                                </td>
                              )}
                              <td>
                                {item.organiser ? item.organiser.name : null}
                              </td>
                              {/* <td>{item.isOpen ? "Open" : "Close"}</td> */}
                              {/* <td>
                                {formatDateToYYMMDD(currentDate) >=
                                  formatDateToYYMMDD(item.registration?.open) &&
                                formatDateToYYMMDD(currentDate) <=
                                  formatDateToYYMMDD(item.registration?.close)
                                  ? "Open"
                                  : "Closed"}
                              </td> */}

                              <td>
                                {!item.isOpen
                                  ? "Close"
                                  : convertUTCtoISTForDisplay(new Date()) <
                                    convertUTCtoISTForDisplay(
                                      item.registration.open
                                    )
                                  ? "yet_to_open"
                                  : convertUTCtoISTForDisplay(new Date()) >=
                                      convertUTCtoISTForDisplay(
                                        item.registration.open
                                      ) &&
                                    convertUTCtoISTForDisplay(new Date()) <=
                                      convertUTCtoISTForDisplay(
                                        item.registration.close
                                      )
                                  ? "open"
                                  : "Close"}
                              </td>

                              <td
                                className={tw("cursor-pointer")}
                                onClick={() => {
                                  if (!item.stage_details_added) {
                                    setModalOpen(true);
                                    setStages(item.numberOfMatches);
                                    setTourId(item._id);
                                  } else {
                                    setTourId(item._id);
                                    setViewModal(true);
                                    setTourId(item._id);
                                  }
                                }}
                              >
                                {item.type}
                                {/* {item.nature === "multiple" ? (
                                  <>
                                    <span
                                      className={`badge ${
                                        item.stage_details_added
                                          ? "bg-success"
                                          : "bg-primary"
                                      } p-1 `}
                                    >
                                      Multiple
                                    </span>
                                    ({item.numberOfMatches})
                                  </>
                                ) : (
                                  <>{item.nature}</>
                                )} */}
                              </td>
                              <td>
                                <div className="flex align-items-center list-user-action">
                                  <Link
                                    className="btn btn-sm btn-icon btn-success me-2"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title=""
                                    data-original-title="Edit"
                                    to="#"
                                    onClick={() => nav(item._id)}
                                  >
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip id="tooltip-disabled">
                                          View More
                                        </Tooltip>
                                      }
                                    >
                                      <span className="btn-inner">
                                        <AiOutlineEye size={23} />
                                      </span>
                                    </OverlayTrigger>
                                  </Link>
                                  <Link
                                    className="btn btn-sm btn-icon btn-warning"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title=""
                                    disable="true"
                                    data-original-title="Add"
                                    to={`/dashboard/app/tournaments-update/${item._id}`}
                                    // onClick={() => banUserById(item._id)}
                                  >
                                    <span>
                                      <svg
                                        width="20"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                        <path
                                          d="M15.1655 4.60254L19.7315 9.16854"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                      </svg>
                                    </span>
                                  </Link>

                                  <div
                                    className="btn btn-sm btn-icon btn-danger ms-2"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title=""
                                    data-original-title="Delete"
                                    to="#"
                                    onClick={() => delTour(item._id)}
                                  >
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip id="tooltip-disabled">
                                          Delete
                                        </Tooltip>
                                      }
                                    >
                                      <span className="btn-inner">
                                        <svg
                                          width="20"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          stroke="currentColor"
                                        >
                                          <path
                                            d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                          <path
                                            d="M20.708 6.23975H3.75"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                          <path
                                            d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                        </svg>
                                      </span>
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {/* </Card.Body> */}
                  </>
                )}

                {modalOpen && (
                  <Modal
                    setModalOpen={setModalOpen}
                    stages={stages}
                    id={tourId}
                  />
                )}
                {viewModal && (
                  <ViewStageModal
                    isOpen={viewModal}
                    openModal={() => {
                      setViewModal(true);
                    }}
                    closeModal={() => {
                      setViewModal(false);
                    }}
                    id={tourId}
                  />
                )}

                <div className="paginate-container">
                  <div className="paginate">
                    <button
                      onClick={() => {
                        page > 1 && setPage(page - 1);
                      }}
                    >
                      {"<"}
                    </button>
                    {[...Array(pages).keys()].map((number) => (
                      <button
                        key={number}
                        className={page === number + 1 ? "selected" : ""}
                        onClick={() => setPage(number + 1)}
                      >
                        {number + 1}
                      </button>
                    ))}
                    <button
                      onClick={() => {
                        Array(pages).length > page && setPage(page + 1);
                      }}
                    >
                      {">"}
                    </button>
                    <select
                      className="selection"
                      onChange={(event) => setSize(event.target.value)}
                      defaultValue={sizes}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      {loader}
    </>
  );
};

export default TournamentsList;
