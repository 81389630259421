import React, { useState, useEffect } from "react";
import { Row, Col, Image, Nav, Tab } from "react-bootstrap";
import Card from "../../../components/Card";
import { useParams, useHistory } from "react-router-dom";
import jsPDF from "jspdf";
import "bootstrap/dist/css/bootstrap.min.css";
import playlogo from "../../../assets/images/logo.png";
import { tw } from "twind";
import Modal from "./MultipleStageModal";
import ViewStageModal from "./view-stage-modal";
import axios from "axios";
import Tournament from "../components/Tournament";
import { renderToString } from "react-dom/server";
import { ErrorIcon, toast } from "react-hot-toast";
import juice from "juice";
import LoaderSpiner from "./loading";
import { MDXProvider } from "@mdx-js/react";
import { getApi } from "../../../backend";
import moment from "moment-timezone";
import { convertUTCtoISTForDisplay } from "./dateFormat.js";

// import Toc from "./markdown/toc.mdx";

const components = {
  // Define any custom components used in your MDX file
  // For example, if you want to style headings differently
  h1: (props) => <h1 style={{ color: "blue" }}>{props.children}</h1>,
};

const TournamentDetails = () => {
  const initData = Object.freeze({
    contact: "",
    createdAt: "",
    registration: "",
    eventName: "",
    gameName: "",
    links: [],
    type: "",
    slots: "",
    nature: "",
    banner: "",
    entryFee: "",
    numberOfMatches: "",
    whatsapp: "",
    discord: "",
    toc: [],
    largerBanner: "",
    // prizePool:{},
    // prizes: [{}],
    // discordLink: "",
    // message: ""
  });
  let history = useHistory();

  const [loading, setLoading] = useState(true);
  const [tournament, setTournament] = useState(initData);
  const [rewards, setRewards] = useState([]);
  const [toc, setToc] = useState([]);
  const [organiser, setOrganiser] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dummyData, setDummyData] = useState([]);
  const [nullTournament, setNullTournament] = useState("Tournament");
  const { id } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  const deleteTeam = async (tournament_id, teamId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this team?"
    );

    if (!confirmDelete) {
      return;
    }
    try {
      const deleteTeam = await axios({
        url: `${getApi()}/tournament/team/delete/${tournament_id}/${teamId}`,
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            localStorage.getItem("token")
              ? JSON.parse(localStorage.getItem("token"))
              : ""
          }`,
        },
      });

      console.log(deleteTeam.status, deleteTeam.data);

      if (deleteTeam.status === 200) {
        toast.success(deleteTeam.data?.message);
        await getAllTournamentById();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error(error.response?.data?.message);
    }
  };

  const getAllTournamentById = async () => {
    const allTour = await axios({
      url: `${getApi()}/tournament/${id}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? JSON.parse(localStorage.getItem("token"))
            : ""
        }`,
      },
    });

    setTournament(allTour?.data?.data);
    setLoading(false);
  };

  const getAllGames = async () => {
    try {
      let allGames = await axios({
        url: `${getApi()}/games`,
        method: "get",
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token")
              ? JSON.parse(localStorage.getItem("token"))
              : ""
          }`,
        },
      });
      return Promise.resolve(allGames);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handlePrint = async () => {
    setIsLoading(true);
    const htmlContent = renderToString(<Tournament data={tournament} />);
    const inlinedHtmlContent = juice(htmlContent);
    console.log(inlinedHtmlContent);
    try {
      setIsLoading(false);
      return alert("Coming soon");
      const response = await axios({
        url: `${getApi()}/tournament/generate`,
        method: "post",
        data: { html: inlinedHtmlContent }, // Make sure this is your actual HTML content
        responseType: "blob", // Important to handle the PDF binary data
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token")
              ? JSON.parse(localStorage.getItem("token"))
              : ""
          }`,
        },
      });

      if (response.status === 200) {
        setIsLoading(false);
        toast.success("Pdf generate successfully");
      }

      // Create a URL for the blob
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", `${tournament.eventName}.pdf`);
      document.body.appendChild(fileLink);

      fileLink.click();
      document.body.removeChild(fileLink);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getAllTournamentById();
    const games = getAllGames();
  }, []);

  const navigateToResult = () => {
    history.push(`/dashboard/app/tournament/getResult/${id}`);
  };

  const navigateToDeclareWinner = () => {
    history.push(`/dashboard/app/tournament/declareWinner/${id}`);
  };

  if (loading) {
    return <LoaderSpiner />;
  }

  return (
    <>
      <Tab.Container defaultActiveKey="first">
        <Row>
          <Col lg="12">
            <Card>
              <Card.Body>
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                      <h4 className="me-2 h4">{nullTournament}</h4>
                    </div>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={handlePrint}
                      style={{ marginLeft: "15px" }}
                    >
                      {isLoading ? "Please wait Exporting..." : "Export"}
                    </button>
                  </div>
                  <Nav
                    as="ul"
                    className="d-flex nav-pills mb-0 text-center profile-tab"
                    data-toggle="slider-tab"
                    id="profile-pills-tab"
                    role="tablist"
                  >
                    {/* <Nav.Item as="li">
                      <Nav.Link eventKey="first"></Nav.Link>
                    </Nav.Item> */}
                  </Nav>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* <Row> */}
          <Col lg="12">
            <Row>
              <Tab.Content className="profile-content w-full">
                <Tab.Pane eventKey="first" id="profile-feed">
                  <Card>
                    <Card.Body>
                      <div className="text-center">
                        <div className="user-profile">
                          <div className="">
                            <Col lg="12">
                              <div className="contain flex flex-col">
                                <label className="text-primary">
                                  Banner Image
                                </label>
                                <Image
                                  className="theme-color-yellow-img img-fluid"
                                  src={tournament.largerBanner}
                                  alt="profile-pic"
                                  style={{ height: "500px" }}
                                />
                              </div>
                            </Col>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Tab.Pane>
              </Tab.Content>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col lg="8">
            <Row>
              <Tab.Content className="profile-content">
                <Tab.Pane eventKey="first" id="profile-feed">
                  <Card>
                    <Card.Body>
                      <div className="text-center">
                        <div className="user-profile">
                          <div className="space-y-10">
                            <Col lg="12">
                              <div className="flex flex-col contain">
                                <label className="text-primary">
                                  Cover Image
                                </label>
                                <Image
                                  className="theme-color-yellow-img img-fluid"
                                  src={tournament.banner}
                                  alt="profile-pic"
                                  style={{ height: "500px", width: "800px" }}
                                />
                              </div>
                            </Col>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3">
                        .
                        <Row>
                          <Col sm="4">
                            <p className="d-inline-block pl-3 text-primary">
                              Tournament Status:
                            </p>
                          </Col>
                          <Col>
                            <p className="d-inline-block">
                              {!tournament.isOpen
                                ? "Close"
                                : convertUTCtoISTForDisplay(new Date()) <
                                  convertUTCtoISTForDisplay(
                                    tournament.registration.open
                                  )
                                ? "yet_to_open"
                                : convertUTCtoISTForDisplay(new Date()) >=
                                    convertUTCtoISTForDisplay(
                                      tournament.registration.open
                                    ) &&
                                  convertUTCtoISTForDisplay(new Date()) <=
                                    convertUTCtoISTForDisplay(
                                      tournament.registration.close
                                    )
                                ? "open"
                                : "Close"}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="4">
                            <p className="d-inline-block pl-3 text-primary">
                              Event Name:
                            </p>
                          </Col>
                          <Col>
                            <p className="d-inline-block">
                              {tournament.eventName}
                            </p>
                          </Col>
                        </Row>
                      </div>
                      <div className="mt-3">
                        <Row>
                          <Col sm="4">
                            <p className="d-inline-block pl-3 text-primary">
                              Game Name:
                            </p>
                          </Col>
                          <Col>
                            <p className="d-inline-block">
                              {tournament?.gameId?.gameName}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Header>
                      <div className="header-title">
                        <h4 className="card-title font-bold py-2">
                          Tournament Details
                        </h4>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <div className="my-2">
                        <Row>
                          <Col sm="4">
                            <h6 className="mb-1 text-primary">Created At:</h6>
                          </Col>
                          <Col>
                            <p>
                              {
                                new String(
                                  tournament.createdAt
                                    ? tournament.createdAt
                                    : null
                                ).split("T")[0]
                              }{" "}
                              <span className="ms-2">
                                <span className="mb-1 text-primary">Time:</span>
                                <span>
                                  {" "}
                                  {
                                    new String(
                                      tournament.createdAt
                                        ? convertUTCtoISTForDisplay(
                                            tournament.createdAt
                                          )
                                        : null
                                    )
                                      .split("T")
                                      .pop()
                                      .split(".")[0]
                                  }
                                </span>
                              </span>
                            </p>
                          </Col>
                        </Row>
                      </div>

                      <div className="mt-2">
                        <Row>
                          <Col sm="4">
                            <h6 className="mb-1 text-primary">
                              Organiser Details:
                            </h6>
                          </Col>
                          <Row>
                            <div className="mx-[35%]">
                              <p>
                                <span className="mb-1 text-primary">Name:</span>
                                <span className="ms-3">
                                  {tournament.organiser
                                    ? tournament.organiser.name
                                    : null}
                                </span>
                              </p>
                              <p>
                                <span className="mb-1 text-primary">
                                  Email:
                                </span>
                                <span className="ms-3">
                                  {tournament.organiser
                                    ? tournament.organiser.email
                                    : null}
                                </span>
                              </p>
                              <p>
                                <span className="mb-1 text-primary">
                                  Mobile:
                                </span>
                                <span className="ms-3">
                                  {tournament.organiser
                                    ? tournament.organiser.mobile
                                    : null}
                                </span>
                              </p>
                              <p>
                                <span className="mb-1 text-primary">
                                  Discord Link:
                                </span>
                                <span className="ms-3">
                                  {tournament?.organiser?.discord
                                    ? tournament?.organiser?.discord
                                    : null}
                                </span>
                              </p>
                              <p>
                                <span className="mb-1 text-primary">
                                  Whatsapp Link:
                                </span>
                                <span className="ms-3">
                                  {tournament?.organiser?.whatsapp
                                    ? tournament?.organiser?.whatsapp
                                    : null}
                                </span>
                              </p>
                            </div>
                          </Row>
                        </Row>
                      </div>

                      <div className="mt-2">
                        <Row>
                          <Col sm="4">
                            <h6 className="mb-1 text-primary">Link:</h6>
                          </Col>
                          <Col>
                            <ul>
                              {tournament?.links?.map((link, index) => (
                                <li key={index}>{link}</li>
                              ))}
                            </ul>
                          </Col>
                        </Row>
                      </div>

                      <div className="mt-2">
                        <Row>
                          <Col sm="4">
                            <h6 className="mb-1 text-primary">Nature:</h6>
                          </Col>
                          <Col>
                            <p>{tournament.nature}</p>
                          </Col>
                        </Row>
                      </div>
                      <div className="mt-2">
                        <Row>
                          <Col sm="4">
                            <h6 className="mb-1 text-primary">
                              Registration Open:
                            </h6>
                          </Col>

                          <Col>
                            <p>
                              {
                                new String(
                                  tournament.registration
                                    ? convertUTCtoISTForDisplay(
                                        tournament.registration.open
                                      )
                                    : null
                                ).split("T")[0]
                              }
                              <span className="ms-2">
                                <span className="mb-1 text-primary">Time:</span>
                                <span>
                                  {" "}
                                  {
                                    new String(
                                      tournament.registration
                                        ? convertUTCtoISTForDisplay(
                                            tournament.registration.open
                                          )
                                        : null
                                    )
                                      .split("T")
                                      .pop()
                                      .split(".")[0]
                                  }
                                </span>
                              </span>
                            </p>
                          </Col>
                        </Row>
                      </div>
                      <div className="mt-2">
                        <Row>
                          <Col sm="4">
                            <h6 className="mb-1 text-primary">
                              Registration Close:
                            </h6>
                          </Col>
                          <Col>
                            <p>
                              {
                                new String(
                                  tournament.registration
                                    ? convertUTCtoISTForDisplay(
                                        tournament.registration.close
                                      )
                                    : null
                                ).split("T")[0]
                              }{" "}
                              <span className="ms-2">
                                <span className="mb-1 text-primary">Time:</span>
                                <span>
                                  {" "}
                                  {
                                    new String(
                                      tournament.registration
                                        ? convertUTCtoISTForDisplay(
                                            tournament.registration.close
                                          )
                                        : null
                                    )
                                      .split("T")
                                      .pop()
                                      .split(".")[0]
                                  }
                                </span>
                              </span>
                            </p>
                          </Col>
                        </Row>
                      </div>
                      <div className="my-2">
                        <Row>
                          <Col sm="4">
                            <h6 className="mb-1 text-primary">
                              Starting Time :
                            </h6>
                          </Col>
                          <Col>
                            <p>
                              {
                                new String(
                                  tournament.startTime
                                    ? convertUTCtoISTForDisplay(
                                        tournament.startTime
                                      )
                                    : null
                                ).split("T")[0]
                              }{" "}
                              <span className="ms-2">
                                <span className="mb-1 text-primary">Time:</span>
                                <span>
                                  {" "}
                                  {
                                    new String(
                                      tournament.startTime
                                        ? convertUTCtoISTForDisplay(
                                            tournament.startTime
                                          )
                                        : null
                                    )
                                      .split("T")
                                      .pop()
                                      .split(".")[0]
                                  }
                                </span>
                              </span>
                            </p>
                          </Col>
                        </Row>
                      </div>
                      <div className="mt-2">
                        <Row>
                          <Col sm="4">
                            <h6 className=" text-primary">Slots:</h6>
                          </Col>
                          <Col>
                            <p>{tournament.totalSlots}</p>
                          </Col>
                        </Row>
                      </div>
                      <div className="my-2">
                        <Row>
                          <Col sm="4">
                            <h6 className=" text-primary">
                              Total Filled Slots:
                            </h6>
                          </Col>
                          <Col>
                            <p>
                              {tournament.slotsFilled
                                ? tournament.slotsFilled
                                : "0"}
                            </p>
                          </Col>
                        </Row>
                      </div>
                      <div className="mt-2">
                        <Row>
                          <Col sm="4">
                            <h6 className="mb-1 text-primary">Type:</h6>
                          </Col>
                          <Col>
                            <p>{tournament.type}</p>
                          </Col>
                        </Row>
                      </div>
                      <div className="my-2">
                        <Row>
                          <Col sm="4">
                            <h6 className="mb-1 text-primary">
                              Maximum Players :
                            </h6>
                          </Col>
                          <Col>
                            <p>{tournament.maxPlayers}</p>
                          </Col>
                        </Row>
                      </div>
                      <div className="mt-2">
                        <Row>
                          <Col sm="4">
                            <h6 className="mb-1 text-primary">Entry Fee:</h6>
                          </Col>
                          <Col>
                            <p>{tournament.entryFee}</p>
                          </Col>
                        </Row>
                      </div>
                      <div className="mt-2">
                        <Row>
                          <Col sm="4">
                            <h6 className="mb-1 text-primary">
                              No. of Matches:
                            </h6>
                          </Col>
                          <Col>
                            <p>{tournament.numberOfMatches}</p>
                          </Col>
                        </Row>
                      </div>

                      {/* <div className="mt-2">
                        <Row>
                          <Col sm="4">
                            <h6 className="mb-1 text-primary">Prize Pool:</h6>
                          </Col>
                          <Row>
                            <div className="mx-[35%]">
                              <p>
                                <span className="mb-1 text-primary">Amount:</span>
                                <span className="ms-3">
                                  {tournament.prizePool
                                    ? tournament.prizePool.amount
                                    : null}
                                </span>
                              </p>
                              <p>
                                <span className="mb-1 text-primary">Prize Type:</span>
                                <span className="ms-3">
                                  {tournament.prizePool ? tournament.prizePool.prizeType : null}
                                </span>
                              </p>
                              <p>
                                <span className="mb-1 text-primary">Currency:</span>
                                <span className="ms-3">
                                  {tournament.prizePool ? tournament.prizePool.currency : null}
                                </span>
                              </p>
                            </div>
                          </Row>
                        </Row>
                      </div>

                      <div className="mt-2">
                        <Row>
                          <Col sm="4">
                            <h6 className="mb-1 text-primary">Prize Details:</h6>
                          </Col>
                          <Row>
                            <div className="mx-[35%]">
                              {tournament.prizes && tournament.prizes.length > 0 ? (
                                tournament.prizes.map((prize, index) => (
                                  <div key={index}>
                                    <p>
                                      <span className="mb-1 text-primary">Prize Name:</span>
                                      <span className="ms-3">{prize.prize_name}</span>
                                    </p>
                                    <p>
                                      <span className="mb-1 text-primary">Amount:</span>
                                      <span className="ms-3">{prize.amount}</span>
                                    </p>
                                    <p>
                                      <span className="mb-1 text-primary">Rank:</span>
                                      <span className="ms-3">{prize.rank}</span>
                                    </p>

                                  </div>
                                ))
                              ) : (
                                <p>No prizes available</p>
                              )}
                            </div>
                          </Row>
                        </Row>
                      </div> */}

                      <div className="mt-2">
                        <Row>
                          <Col sm="4">
                            <h6 className="mb-1 text-primary">Message:</h6>
                          </Col>
                          <Col>
                            <p>{tournament.message}</p>
                          </Col>
                        </Row>
                      </div>
                    </Card.Body>
                  </Card>
                </Tab.Pane>
              </Tab.Content>
            </Row>
          </Col>

          <Col lg="4">
            <Col>
              <Card>
                <Card.Header>
                  <div className="header-title">
                    <Row>
                      <h4 className="card-title font-bold py-2">Prize Pool</h4>
                    </Row>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="">
                    <Row>
                      <div>
                        <p>
                          <span className="mb-1 text-primary">Amount:</span>
                          <span className="ms-3">
                            {tournament.prizePool
                              ? tournament.prizePool.amount
                              : null}
                          </span>
                        </p>
                        <p>
                          <span className="mb-1 text-primary">Prize Type:</span>
                          <span className="ms-3">
                            {tournament.prizePool
                              ? tournament.prizePool.prizeType
                              : null}
                          </span>
                        </p>
                        <p>
                          <span className="mb-1 text-primary">Currency:</span>
                          <span className="ms-3">
                            {tournament.prizePool
                              ? tournament.prizePool.currency
                              : null}
                          </span>
                        </p>
                      </div>
                    </Row>
                  </div>

                  <div className="mt-2">
                    <Row>
                      <Col sm="8">
                        <h6 className="mb-1 text-primary">Prize Details:</h6>
                      </Col>
                      <Row>
                        <div>
                          {tournament.prizes && tournament.prizes.length > 0 ? (
                            tournament.prizes.map((prize, index) => (
                              <div key={index} className="">
                                <p>
                                  <span className="mb-1 text-black font-bold">
                                    Rank:
                                  </span>
                                  <span className="ms-3 text-black font-bold">
                                    {prize.rank}
                                  </span>
                                </p>
                                <p>
                                  <span className="mb-1 text-primary">
                                    Amount:
                                  </span>
                                  <span className="ms-3">{prize.amount}</span>
                                </p>
                                <p>
                                  <span className="mb-1 text-primary">
                                    Prize Name:
                                  </span>
                                  <span className="ms-3">
                                    {prize.prize_name}
                                  </span>
                                </p>
                                {/* <p>
                                      <span className="mb-1 text-primary">Amount:</span>
                                      <span className="ms-3">{prize.amount}</span>
                                    </p> */}
                                {/* <p>
                                      <span className="mb-1 text-primary">Rank:</span>
                                      <span className="ms-3">{prize.rank}</span>
                                    </p> */}
                              </div>
                            ))
                          ) : (
                            <p>No prizes available</p>
                          )}
                        </div>
                      </Row>
                    </Row>
                  </div>
                  {/* <ul className="list-inline m-0 ">
                    {rewards.map((d) => (
                      <li className="d-flex mb-2">{d}</li>
                    ))}
                  </ul> */}
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card>
                <Card.Header>
                  <div className="header-title">
                    <Row>
                      <Col>
                        <h4 className="card-title font-bold py-2">TOC</h4>
                      </Col>
                      {/* <Col className="py-2">{tournament.toc.length}</Col> */}
                    </Row>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Col
                    dangerouslySetInnerHTML={{ __html: tournament.toc }}
                  ></Col>
                  {/* <Col> {tournament.toc}</Col> */}
                  {/* // <ol>
                  //   {tournament?.toc?.map((toc, index) => (
                  //     <li key={index}>{index + 1}. {toc}</li>
                  //   ))}
                  // </ol> */}
                  <Col>
                    {/* <MDXProvider components={components}>
                      <Toc />
                    </MDXProvider> */}
                  </Col>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card style={{ maxHeight: "600px", overflowY: "auto" }}>
                <style>
                  {`
                /* Hide scrollbar for Chrome and Brave */
                ::-webkit-scrollbar {
                  width: 0;
                }
              `}
                </style>
                <Card.Header>
                  <div className="header-title">
                    <Row>
                      <Col>
                        <h4 className="card-title font-bold py-2">
                          Registered Teams
                        </h4>
                      </Col>
                    </Row>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row>
                    {tournament.teams &&
                    tournament.teams.teams &&
                    tournament.teams.teams.length > 0 ? (
                      <ul>
                        {tournament.teams.teams.map((teamData, index) => (
                          <li key={index} className=" mt-1">
                            <div className="flex justify-between items-center gap-2">
                              <p className="font-bold text-black text-md block">
                                Team {index + 1}:
                              </p>
                              <button
                                className="text-red-500"
                                onClick={() =>
                                  deleteTeam(
                                    tournament._id,
                                    teamData.teamId._id
                                  )
                                }
                              >
                                Delete Team
                              </button>
                            </div>
                            <div className="">
                              <p>
                                <span className="mb-1 text-primary">
                                  Leader Name:
                                </span>
                                <span className="ms-3">
                                  {teamData.teamId.leaderGameName}
                                </span>
                              </p>
                              <p>
                                <span className="mb-1 text-primary">
                                  Team Name:
                                </span>
                                <span className="ms-3">
                                  {teamData.teamId.teamName}
                                </span>
                              </p>

                              <p className="text-primary">Players:</p>
                              <div className="ms-3 ">
                                {teamData.teamId.players &&
                                teamData.teamId.players.length > 0 ? (
                                  teamData.teamId.players.map(
                                    (playersData, index) => (
                                      <p key={index}>
                                        <div className="flex space-x-2 space-y-1">
                                          <span className="mt-1 text-primary">
                                            {index + 1}.
                                          </span>
                                          <span className="ms-3">
                                            <img
                                              className="w-6 h-6 rounded-full"
                                              src={
                                                playersData.userId.profilePic
                                              }
                                              alt="profile-pic"
                                            />
                                          </span>
                                          <span className="ms-3">
                                            {playersData.userId.username}
                                          </span>
                                        </div>
                                      </p>
                                    )
                                  )
                                ) : (
                                  <p>No players data</p>
                                )}
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>No teams available for this tournament.</p>
                    )}
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <button
                className="text-white bg-primary text-lg font-bold p-2 cursor-pointer w-full "
                onClick={navigateToResult}
              >
                Get results
              </button>
            </Col>

            <Col>
              <button
                onClick={navigateToDeclareWinner}
                className="text-white bg-primary text-lg font-bold mt-4 p-2 cursor-pointer w-full"
              >
                Declare Winner
              </button>
            </Col>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default TournamentDetails;
