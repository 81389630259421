/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Card from "../../../components/Card";
import { HiOutlineBan } from "react-icons/hi";
import { AiOutlineEye } from "react-icons/ai";
import { BsCircle } from "react-icons/bs";
import {
  getdummyusers,
  banUser,
  deleteUser,
  unbanUser,
} from "../../../Hooks/userHooks";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import useFullPageLoader from "../../../Hooks/useFullPageLoader";
import toast, { Toaster } from "react-hot-toast";

const UserDummy = () => {
  const initData = Object.freeze([
    {
      _id: "loading...",
      profilePic: "loading...",
      name: "loading...",
      phone: "loading...",
      email: "loading...",
      createdAt: "loading...",
      role: "loading...",
      active: true,
    },
  ]);

  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [changeData, setChangeData] = useState("");
  const [item, setItem] = useState(initData);
  let history = useHistory();
  const [check, setCheck] = useState(false);
  const [checkbx, setCheckbx] = useState("disabled");
  const [userCount, setUserCount] = useState();
  const [dataChecked, setDataChecked] = useState([]);
  const [checkData, setCheckData] = useState([]);
  const [arr, setArr] = useState([]);
  const [res, setRes] = useState("");
  const [searchUser, setSearchUser] = useState("");
const [filteredItems, setFilteredItems] = useState([]);


  // console.log(item, "item");

  const fetchUsers = () => {
    showLoader()
    getdummyusers().then((res) => {
      if (res.success === false) {
        setUserCount("Error: " + res.message);
        hideLoader()
      } else {
        setItem(res.data);
        setFilteredItems(res.data);
        setUserCount("Dummy User Count: " + res.data.length);
        hideLoader()
      }
    });
  };

  useEffect(() => {
    fetchUsers()
  }, []);

  const handleSearch = (e) => {
    setSearchUser(e.target.value);
    const filtered = item.filter((user) =>
      user.email.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredItems(filtered);
  };
  

  useEffect(() => {
    if (dataChecked.length !== 0) {
      setCheckbx("enabled")
    }
    else if(checkData.length !== 0) {
      setCheckbx("enabled")
    }
    else{
      setCheckbx("disabled")
    }
  });

  // console.log(checkbx, checkData.length, dataChecked.length);  
  const handleChange = (e) => {
    
    const { value, checked } = e.target;
    // console.log(e.target.value);
    setChangeData({
      ...changeData,
      [e.target.name]: e.target.value,
    })
    if (value === "AllSelect") {
      let temp = item.map((user) => {
        return { ...user, isChecked: checked };
      });
      setItem(temp);
      temp.map((user) => {
        if (user.isChecked) {
          arr.push(user._id);
        } else {
          arr.pop(user._id);
          setDataChecked([]);
          setChangeData("");
        }
        setItem(temp);
        setCheckData(arr);
        // console.log(arr, item, temp);
      });
    }
    else {
      setArr([]);
      let temp1 = item.map((user) =>
        user._id === value ? { ...user, isChecked: checked } : user
      );
      var updatedList = [...dataChecked];
      if (e.target.checked) {
        updatedList = [...dataChecked, e.target.value];
      } else {
        updatedList.splice(dataChecked.indexOf(e.target.value), 1);
      }
      setDataChecked(updatedList);
      // console.log(dataChecked);
      setItem(temp1);
      // console.log(item); 
    }
  };
  const banUserById = async (id) => {
    // alert(id)
    banUser(id).then((res) => {
      // console.log(res)
      fetchUsers()
      alert(res.message);
    });
  };

  const nav = (id) => {
    history.push(`/dashboard/app/dummy-user-profile/${id}`);
  };
  const unbanUserById = async (id) => {
    // alert(id)
    unbanUser(id).then((res) => {
      // console.log(res)
      fetchUsers()
      alert(res.message);
    });
  };

  const delUser = async (id) => {
    // alert(id)
    deleteUser(id).then((res) => {
      // console.log(res)
      fetchUsers()
      alert(res.message);
    });
  };

  const DeleteUsers =()=> {

    if (changeData.AllSelect === "AllSelect") {
      checkData.map((data) => (
        deleteUser(data).then((ss) =>(
          // console.log(ss.message),
          setRes(ss.message)
        ))
      ))
      setCheckData([]);
      fetchUsers();
    }
    else {
      dataChecked.map((data) => (
        deleteUser(data).then((ss) => (
          // console.log(ss.message),
          setRes(ss.message)
        ))
      ))
      setDataChecked([]);
      fetchUsers();
    }
    toast.success(res);
  }
  const handleChange1=() => {
    setCheck(!check);
  }
  // console.log(checkData, dataChecked );
  return (
    <>
      <Toaster />
      <div>
        <Row>
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Dummy User List</h4>
                  <span style={{ marginTop: "1rem", display: "flex", alignItems: "center", justifyContent: "center" }} >
                  <h8>Delete selected Users</h8>
                      <button className={ `${checkbx} btn mx-4 btn-sm btn-primary`}
                            onClick={DeleteUsers}>
                            Delete
                  </button>
                  </span>
                </div>
                <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="inputGroup-sizing-sm"
                      aria-label="Search Dummy users"
                      aria-describedby="button-addon2"
                      name="search"
                      style={{
                        height: "35px",
                        width: "500px",
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                        border: "1px solid grey",
                      }}
                      placeholder="Search Dummy users..."
                      value={searchUser}
                      onChange={handleSearch}
                    />
                  </div>
                <div>
                  <h4 className="card-title">{userCount}</h4>
                  <span style={{ marginTop: "1rem", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={fetchUsers}
                    >
                      Reload
                    </button>
                  </span>
                </div>
              </Card.Header>
              <Card.Body className="px-0">
                <div className="table-responsive">
                  <table
                    id="user-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="ligth">
                        <th>
                        <input
                          type="checkbox"
                          id="id"
                          name="AllSelect"
                          value="AllSelect"
                          className=""
                          checked={
                            item.filter((user) => user?.isChecked !== true)
                              .length < 1
                          }        
                          onChange={handleChange}
                        />
                        </th>
                        <th>Email</th>
                        <th>Password</th>
                        <th>Active</th>
                        <th>Join Date</th>
                        <th>Join Time</th>
                        <th min-width="100px">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredItems.map((item, idx) => (
                        <tr key={idx}>
                          <th >
                          <input
                            type="checkbox"
                            id={item.id}
                            // name={item.email}
                            checked={item?.isChecked || false}
                            value={item._id}
                            // style={{ margin: "1rem" }}
                            onChange={handleChange}
                            />
                            </th>
                          <td>{item.email}</td>
                          <td>{"User@1234"}</td>
                          <td>{item.active ? <p>Yes</p> : <p>No</p>}</td>
                          <td>{new String(item.createdAt).split('T')[0]}</td>
                          <td>{new String(item.createdAt).split('T').pop().split('.')[0]}</td>                          
                          <td>
                            <div className="flex align-items-center list-user-action">
                              {item.active ? (
                                <Link
                                  className="btn btn-sm btn-icon btn-warning mx-1"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title=""
                                  data-original-title="Add"
                                  to="#"
                                  onClick={() => banUserById(item._id)}
                                >
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="tooltip-disabled">
                                        Ban
                                      </Tooltip>
                                    }
                                  >
                                    <span className="btn-inner">
                                      <HiOutlineBan size={23} />
                                    </span>
                                  </OverlayTrigger>
                                </Link>
                              ) : (
                                <Link
                                  className="btn btn-sm btn-icon btn-info mx-1"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title=""
                                  data-original-title="Add"
                                  to="#"
                                  onClick={() => unbanUserById(item._id)}
                                >
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="tooltip-disabled">
                                        Unban
                                      </Tooltip>
                                    }
                                  >
                                    <span className="btn-inner">
                                      <BsCircle size={23} />
                                    </span>
                                  </OverlayTrigger>
                                </Link>
                              )}
                              <Link
                                className="btn btn-sm btn-icon btn-success"
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Edit"
                                to="#"
                                onClick={() => nav(item._id)}
                              >
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      View
                                    </Tooltip>
                                  }
                                >
                                  <span className="btn-inner">
                                    <AiOutlineEye size={23} />
                                  </span>
                                </OverlayTrigger>
                              </Link>{" "}
                              <Link
                                className="btn btn-sm btn-icon btn-danger"
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Delete"
                                to="#"
                                onClick={() => delUser(item._id)}
                              >
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      Delete
                                    </Tooltip>
                                  }
                                >
                                  <span className="btn-inner">
                                    <svg
                                      width="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      stroke="currentColor"
                                    >
                                      <path
                                        d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M20.708 6.23975H3.75"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                    </svg>
                                  </span>
                                </OverlayTrigger>
                              </Link>{" "}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      {loader}
    </>
  );
};

export default UserDummy;
