import React, { useState, useEffect } from "react";
import { Row, Col, Image } from "react-bootstrap";
import Card from "../../../components/Card";
import { Link, useParams } from "react-router-dom";
import { reportedUserInfo } from "../../../Hooks/userHooks";
import { HiOutlineBan } from "react-icons/hi";
import { BsCircle } from "react-icons/bs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { banUser, deleteUser, unbanUser } from "../../../Hooks/userHooks";

const ReportedUserProfile = () => {
  const initData = Object.freeze({
    userId: "",
  });
  const reInit = Object.freeze([
    {
      date: "",
      reason: "",
      userId: "",
    },
  ]);
  const [user, setUser] = useState(initData);
  const [reporter, setReporter] = useState(reInit);
  const { id } = useParams();

  const userInfo = () => {
    reportedUserInfo(id).then((res) => {
      if (res.success === false) {
        setUser({ username: "No User Found" });
      } else {
        setUser(res.data.userId);
        setReporter(res.data.reportedBy);
      }
    });
  };

  useEffect(() => {
    userInfo();
  }, []);

  const banUserById = async (id) => {
    banUser(id).then((res) => {
      alert(res.message);
      userInfo();
    });
  };

  const unbanUserById = async (id) => {
    unbanUser(id).then((res) => {
      alert(res.message);
      userInfo();
    });
  };

  const delUser = async (id) => {
    deleteUser(id).then((res) => {
      alert(res.message);
      userInfo();
    });
  };
  // console.log(user, "user");
  // console.log(reporter, "reporter");
  return (
    <div>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">
                  Reported User
                  <span style={{ color: "#EA6A12", marginLeft: "1rem" }}>
                    {user.username}
                  </span>
                </h4>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              <div className="table-responsive">
                <table
                  id="user-list-table"
                  className="table table-striped"
                  role="grid"
                  data-toggle="data-table"
                >
                  <thead>
                    <tr className="ligth">
                      <th>Name</th>
                      <th>Profile</th>
                      <th>Contact</th>
                      <th>Email</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{user.username}</td>
                      <td>
                        <Image
                          className="bg-soft-primary rounded img-fluid avatar-40 me-3"
                          src={user.profilePic}
                          alt="profile"
                        />
                      </td>
                      <td>{user.phone}</td>
                      <td>{user.email}</td>
                      <td>
                        <div className="flex align-items-center list-user-action">
                          {user.active ? (
                            <Link
                              className="btn btn-sm btn-icon btn-warning mx-1"
                              data-toggle="tooltip"
                              data-placement="top"
                              title=""
                              data-original-title="Add"
                              to="#"
                              onClick={() => banUserById(user._id)}
                            >
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-disabled">Ban</Tooltip>
                                }
                              >
                                <span className="btn-inner">
                                  <HiOutlineBan size={23} />
                                </span>
                              </OverlayTrigger>
                            </Link>
                          ) : (
                            <Link
                              className="btn btn-sm btn-icon btn-info mx-1"
                              data-toggle="tooltip"
                              data-placement="top"
                              title=""
                              data-original-title="Add"
                              to="#"
                              onClick={() => unbanUserById(user._id)}
                            >
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-disabled">Unban</Tooltip>
                                }
                              >
                                <span className="btn-inner">
                                  <BsCircle size={23} />
                                </span>
                              </OverlayTrigger>
                            </Link>
                          )}
                          <Link
                            className="btn btn-sm btn-icon btn-danger"
                            data-toggle="tooltip"
                            data-placement="top"
                            title=""
                            data-original-title="Delete"
                            to="#"
                            onClick={() => delUser(user._id)}
                          >
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-disabled">Delete</Tooltip>
                              }
                            >
                              <span className="btn-inner">
                                <svg
                                  width="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  stroke="currentColor"
                                >
                                  <path
                                    d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                  <path
                                    d="M20.708 6.23975H3.75"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                  <path
                                    d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>
                              </span>
                            </OverlayTrigger>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Reported By </h4>
              </div>
              <div>
                <h4 className="card-title">{user.username}</h4>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              <div className="table-responsive">
                <table
                  id="user-list-table"
                  className="table table-striped"
                  role="grid"
                  data-toggle="data-table"
                >
                  <thead>
                    <tr className="ligth">
                      <th>Reason</th>
                      <th>Date</th>
                      <th>Profile</th>
                      <th>User Name</th>
                      <th>Phone</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reporter.map((item, i) => (
                      <tr>
                        <td>{item.reason}</td>
                        <td>{item.date}</td>
                        <td>
                          <Image
                            className="bg-soft-primary rounded img-fluid avatar-40 me-3"
                            src={item.userId.profilePic}
                            alt="profile"
                          />
                        </td>
                        <td>{item.userId.username}</td>
                        <td>{item.userId.phone}</td>
                        <td>{item.userId.email}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ReportedUserProfile;
