import axios from "axios";
import { getApi } from "../backend";

export const getReportedPosts = async () => {
  const config = {
    method: "get",
    url: `${getApi()}/post/reported`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
      }`,
    },
  };
  try {
    const res = await axios(config);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const deletePost = async (id) => {
  if (!id) {
    throw new Error("Invalid post ID");
  }

  if (isNaN(id)) {
    throw new Error("Post ID must be a number");
  }

  let url = `${getApi()}/post/deletePost/${id}`;
  await fetch(url, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
      }`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      alert(json.message);
    })
    .catch((err) => {
      alert(err.message);
    });
};


export const reportedPostInfo = async (id) => {
  const config = {
    method: "get",
    url: `${getApi()}/post/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
      }`,
    },
  };

  try {
    const res = await axios(config);
    console.log("hii",res);
    if (res.status === 200) {
      console.log("data", res);
      return res.data;
    }
  } catch (error) {
    return error.response.data;
  }
};




export const getReportedComments = async () => {
  const config = {
    method: "get",
    url: `${getApi()}/post/reportedComments`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
      }`,
    },
  };
  try {
    const res = await axios(config);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const reportedCommentInfo = async (id) => {
  const config = {
    method: "get",
    url: `${getApi()}/post/reportedComment/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
      }`,
    },
  };

  try {
    const res = await axios(config);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteComment = async (pid, cid) => {
  let url = `${getApi()}/post/${pid}/deleteComment?commentId=${cid}`;
  await fetch(url, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
      }`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      alert(json.message);
    })
    .catch((err) => {
      alert(err.message);
    });
};



export const getAllPosts = async () => {
  const config = {
    method: "get",
    url: `${getApi()}/post/allPosts`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" && JSON.parse(localStorage.getItem("token"))
      }`,
    },
  };

  try {
    const res = await axios(config);
    
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};


export const fetchAdminPostDetails = async (id) => {
  const config = {
    method: "get",
    url: `${getApi()}/post/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" &&
        JSON.parse(localStorage.getItem("token"))
      }`,
    },
  };

  try {
    const res = await axios(config);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
