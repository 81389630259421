import axios from 'axios'
import React, {useEffect, useState} from 'react'
import toast from 'react-hot-toast';
import { useParams, useHistory } from 'react-router-dom'
import { Row, Col, Image, Nav, Tab } from "react-bootstrap";
import Card from "../../../components/Card";
import { getApi } from '../../../backend';

const gamesdetails = () => {
    const history = useHistory();

    const {id } = useParams();

    const [games, setGames] = useState();


    const getGamesById = async () => {
        try{
        let response = await axios({
        url: `${getApi()}/games/${id}`,
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")
                ? JSON.parse(localStorage.getItem("token"))
                : ""
              }`,
           },  
          });

          if(response.data.success){
            console.log(response);
            // history.push(`/dashboard/app/games/games-details/${id}`)
            setGames(response?.data?.data);
        }

        }catch(error){
            console.log(error);
            toast.error(error.message);
        }
    }

    useEffect(() => {
        getGamesById();
    }, [])
  return (
    <>
      <Tab.Container defaultActiveKey="first">
          <Col lg="12">
            <Row >
              <Tab.Content className="profile-content w-full">
                <Tab.Pane eventKey="first" id="profile-feed">
                  <Card>
                    <Card.Body>
                      <div className="text-center">
                        <div className="user-profile">
                          <div className="">
                            <Col lg="12">
                              <div className="contain">
                                <Image
                                  className="theme-color-yellow-img img-fluid"
                                  src={games?.coverUrl}
                                  alt="profile-pic"
                                  style={{ height: "500px" }}
                                />
                              </div>
                            </Col>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Tab.Pane>
              </Tab.Content>
            </Row>

            <Card>
                    <Card.Header>
                      <div className="header-title">
                        <h4 className="card-title font-bold py-2">Game Details</h4>
                      </div>
                    </Card.Header>
                    <Card.Body>

            <div className="mt-2">
                        <Row>
                          <Col sm="4">
                            <h6 className="mb-1 text-primary">Game Name:</h6>
                          </Col>
                          <Col>
                            <p>{games?.gameName
                                    || null}</p>
                          </Col>
                        </Row>
                      </div>

                      <div className="mt-2">
                        <Row>
                          <Col sm="4">
                            <h6 className="mb-1 text-primary">Short Name:</h6>
                          </Col>
                          <Col>
                            <p>{games?.short_name
                                    || null}</p>
                          </Col>
                        </Row>
                      </div>

                      <div className="mt-2">
                        <Row>
                          <Col sm="4">
                            <h6 className="mb-1 text-primary">Platform:</h6>
                          </Col>
                          <Col>
                            <p>{games?.platform
                                    || null}</p>
                          </Col>
                        </Row>
                      </div>

                      <div className="mt-2">
                        <Row>
                          <Col sm="4">
                            <h6 className="mb-1 text-primary">Description:</h6>
                          </Col>
                          <Col>
                            <p>{games?.description
                                    || null}</p>
                          </Col>
                        </Row>
                      </div>
                      </Card.Body>
                      </Card>

       </Col>
      </Tab.Container>
    
    </>
   
   )
}

export default gamesdetails;