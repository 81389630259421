//router
import IndexRouters from "./router/index";

//scss
import "./assets/scss/hope-ui.scss";
import "./assets/scss/dark.scss";
import "./assets/scss/rtl.scss";
import "./assets/scss/custom.scss";
import "./assets/scss/customizer.scss";
import { Toaster } from "react-hot-toast";

//qwertyu
function App() {
  return (
    <div className="App">
      <IndexRouters />
      <Toaster></Toaster>
    </div>
  );
}

export default App;
