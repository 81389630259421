import axios from "axios";
import { env, getApi } from "../backend";

export const addTournament = async (data) => {
  let url = `${getApi()}/tournament/add`;
  const config = {
    method: "post",
    url: url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" &&
        JSON.parse(localStorage.getItem("token"))
      }`,
    },
  };
  try {
    const res = await axios(config);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const UpdateTournament = async (data, id) => {
  let url = `${getApi()}/tournament/${id}`;
  const config = {
    method: "put",
    url: url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" &&
        JSON.parse(localStorage.getItem("token"))
      }`,
    },
  };
  try {
    const res = await axios(config);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getTournaments = async (page, sizes) => {
  let url;
  console.log(sizes, page);
  // if (env === "test") {
  //   url = `https://playwisebackendtest.azurewebsites.net/api/mobile/v1/tournament/all?page=${page}&limit=${sizes}`;
  // } else {
  //   url = `https://playwisebackend.azurewebsites.net/api/mobile/v1/tournament/all?page=${page}&limit=${sizes}`;
  // }
  const config = {
    method: "get",
    url: `${getApi()}/tournament/all?page=${page}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" &&
        JSON.parse(localStorage.getItem("token"))
      }`,
    },
  };

  try {
    const res = await axios(config);
    // console.log(res.data, "fetch");
    return res.data;
  } catch (error) {
    // console.log(error.response.data, "users");
    return error.response.data;
  }
};

export const getTournamentsById = async (id) => {
  let url;
  // if (env === "test") {
  //   url = `https://playwisebackendtest.azurewebsites.net/api/mobile/v1/tournament/tour/pc/${id}`;
  // } else {
  //   url = `https://playwisebackend.azurewebsites.net/api/mobile/v1/tournament/tour/pc/${id}`;
  // }
  const config = {
    method: "get",
    url: `${getApi()}/tournament/tour/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" &&
        JSON.parse(localStorage.getItem("token"))
      }`,
    },
  };

  try {
    const res = await axios(config);
    // console.log(res.data);
    return res.data;
  } catch (error) {
    // console.log(error.response.data, "users");
    return error.response.data;
  }
};

export const deleteTour = async (id) => {
  const config = {
    method: "delete",
    url: `${getApi()}/tournament/del/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" &&
        JSON.parse(localStorage.getItem("token"))
      }`,
    },
  };

  try {
    const res = await axios(config);
    // console.log(res)
    return res.data;
  } catch (error) {
    // console.log(error.response.data, "users");
    return error.response.data;
  }
};

export const addStageData = (data, id) => {
  return axios
    .put(
      `${getApi()}/tournament/group/${id}`,
      {
        stages: data,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            typeof window !== "undefined" &&
            JSON.parse(localStorage.getItem("token"))
          } `,
        },
      }
    )
    .then((res) => {
      console.log(res.data);
      if (res.data.success) {
        return res.data;
      }
    })
    .catch((err) => {
      console.log(err);
      return err.response.data;
    });
};

export const getModalData = async (id) => {
  let url = `${getApi()}/tournament/tour/stage/${id}`;

  console.log("data fetched" + id);

  const config = {
    method: "get",
    url: url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        typeof window !== "undefined" &&
        JSON.parse(localStorage.getItem("token"))
      }`,
    },
  };

  try {
    const res = await axios(config);
    console.log(res.data, "fetch");
    console.log("API response data:", res.data);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
