import React, { useState, useEffect } from "react";
import axios from "axios";
import { ErrorIcon, toast } from "react-hot-toast";
import LoaderSpiner from "./loading.js";

import { useParams, useHistory } from "react-router-dom";
import TournamentDetails from "./tournament-details.js";
import { getApi } from "../../../backend.js";
import moment from "moment-timezone";
import { convertUTCtoISTForDisplay } from "./dateFormat.js";

const declareWinner = () => {
  const { tournament_id } = useParams();

  const [result, setResult] = useState([]);

  const initData = Object.freeze({
    contact: "",
    createdAt: "",
    registration: "",
    eventName: "",
    gameName: "",
    links: [],
    type: "",
    slots: "",
    nature: "",
    banner: "",
    entryFee: "",
    numberOfMatches: "",
    whatsapp: "",
    discord: "",
    toc: [],
    largerBanner: "",
    // prizePool:{},
    // prizes: [{}],
    // discordLink: "",
    // message: ""
  });

  const [loading, setLoading] = useState(true);

  const [selectedTeams, setSelectedTeams] = useState([
    {
      teamId: "",
      prizeId: "",
    },
  ]);
  let history = useHistory();

  const [tournament, setTournament] = useState(initData);

  const getResult = async () => {
    const result = await axios({
      url: `${getApi()}/tournament/getResult/${tournament_id}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? JSON.parse(localStorage.getItem("token"))
            : ""
        }`,
      },
    });
    console.log(result?.data?.data);
    setResult(result?.data);
    setLoading(false);
  };

  useEffect(() => {
    getResult();
  }, []);

  console.log(result);

  const getAllTournamentById = async () => {
    const allTour = await axios({
      url: `${getApi()}/tournament/${tournament_id}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? JSON.parse(localStorage.getItem("token"))
            : ""
        }`,
      },
    });

    console.log(allTour?.data?.data);
    setTournament(allTour?.data?.data);
  };

  console.log(tournament);

  useEffect(() => {
    getAllTournamentById();
  }, []);
  console.log(tournament);

  const [winnersDeclared, setWinnersDeclared] = useState(false);

  useEffect(() => {
    const resultsDeclaredValue = result?.data?.resultInfo?.resultsDeclared;

    if (resultsDeclaredValue !== false && resultsDeclaredValue !== undefined) {
      setWinnersDeclared(true); // Set winnersDeclared to true when results are declared
    }
  }, [result]);

  const handleTeamSelection = (index, teamId) => {
    const newSelectedTeams = [...selectedTeams];
    newSelectedTeams[index] = {
      teamId: teamId,
      prizeId: tournament?.prizes[index]?._id,
    };

    setSelectedTeams(newSelectedTeams);
  };

  const [formErrors, setFormErrors] = useState({});

  const handleDeclareWinners = async (event) => {
    event.preventDefault();
    const currentTime = new Date();
    const closeTime = new Date(
      convertUTCtoISTForDisplay(tournament?.registration?.close)
    );
    const startTime = new Date(
      convertUTCtoISTForDisplay(tournament?.startTime)
    );

    if (
      currentTime.getTime() > closeTime.getTime() &&
      currentTime.getTime() > startTime.getTime()
    ) {
      console.log("selected teams", selectedTeams);

      // const selectedTeamsValid = selectedTeams.every(team => team && team.teamId);
      console.log(selectedTeams);

      const selectedTeamsValid = selectedTeams.some(
        (team) => !team.prizeId || !team.teamId
      );

      if (selectedTeamsValid) {
        const newErrors = {};
        selectedTeams.forEach((team, index) => {
          if (!team || !team.teamId) {
            newErrors[`team${index}`] = "Team is required";
          }
        });
        setFormErrors(newErrors);
        console.log(formErrors);
        return;
      }

      const winnersData = [];

      selectedTeams.forEach((team, index) => {
        winnersData.push({
          prize: team.prizeId,
          team: team.teamId,
        });
      });

      console.log("Winners Data:", winnersData);

      try {
        const response = await axios({
          url: `${getApi()}/tournament/declareWinner/${tournament_id}`,
          method: "post",
          data: { winners: winnersData },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? JSON.parse(localStorage.getItem("token"))
                : ""
            }`,
          },
        });

        if (response.data.success) {
          toast.success(response.data.message);
          history.push(`/dashboard/app/tournament/getResult/${tournament_id}`);
          console.log(response);
        }
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      }
    } else {
      toast.error("Tournament not ended yet");
    }
  };
  if (loading) {
    return <LoaderSpiner />;
  }

  return (
    <div className="bg-white rounded-md relative z-10">
      <div className="pt-6 px-6 text-2xl font-bold text-black">
        <h1>Declare Winners</h1>
      </div>

      {winnersDeclared ? (
        <p className="p-5  text-lg">Result declared already!!</p>
      ) : (
        <form className="p-5" onSubmit={handleDeclareWinners}>
          <div className="flex flex-col px-5 py-2 ">
            <div className="flex flex-col flex-wrap space-y-3">
              <table style={{ borderSpacing: "10px" }}>
                <thead className="text-black">
                  <tr>
                    <th>Prize Name</th>
                    <th>Rank</th>
                    <th>
                      Amount (
                      {tournament?.prizePool?.prizeType === "playCoins"
                        ? "Playcoins"
                        : tournament?.prizePool?.currency}
                      )
                    </th>
                    <th>Select Team</th>
                  </tr>

                  <tr>
                    <td colSpan="4">
                      <hr />
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {tournament?.prizes && tournament.prizes.length > 0 ? (
                    tournament?.prizes?.map((prize, index) => (
                      <React.Fragment key={index}>
                        <tr key={index} className="m-2">
                          <td>{prize.prize_name}</td>
                          <td>{prize.rank}</td>
                          <td>{prize.amount}</td>
                          <td>
                            <select
                              name={`winner-${index}`}
                              className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm p-1.5 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                              value={selectedTeams[index]?.teamId || ""}
                              onChange={(e) =>
                                handleTeamSelection(index, e.target.value)
                              }
                            >
                              <option value="" disabled>
                                Select Team
                              </option>
                              {tournament?.teams?.teams?.map((team) => (
                                <option
                                  key={team.teamId._id}
                                  value={team.teamId._id}
                                  disabled={selectedTeams.some(
                                    (selected) =>
                                      selected?.teamId === team.teamId._id
                                  )}
                                >
                                  {team.teamId.teamName}
                                </option>
                              ))}
                            </select>
                            {formErrors[`team${index}`] && (
                              <div className="text-red-500 text-sm">
                                {formErrors[`team${index}`]}
                              </div>
                            )}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))
                  ) : (
                    <p>No teams Registered yet!!!</p>
                  )}
                </tbody>
              </table>
            </div>

            <div>
              <button type="submit" className="bg-primary p-2 mt-4 text-white">
                Declare Winners
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default declareWinner;
