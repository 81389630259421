import React, { useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getApi } from '../../../backend';

const createGame = () => {

    const history = useHistory();
    const [gameData, setGameData] = useState({
        gameName: '',
        short_name: '',
        description: '',
        platform: '',
        cover: null,
    });

    const gamesPlatform = ['pc', 'mobile', 'console'];

    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleChange = (event) => {
    const { name, value } = event.target;

    setGameData((prevData) => ({ ...prevData, [name]: value }));
    setFormErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
          newErrors[name] = '';
    
        return newErrors;
      });
  };

    const handleCover = (e) => {
        const { name } = e.target;
        setGameData({ ...gameData, [name]: e.target.files[0] });

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            cover: null, 
          }));
      };

      const isValidImageType = (file) => {
        const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
      
        return allowedTypes.includes(file.type);
      };

    const handleCreateGames = async (event) => {
        event.preventDefault();
        
        const errors = validate(gameData);
        const formData = new FormData();

        Object.entries(gameData).forEach(([key,value]) => {
            if(!Array.isArray(value) && typeof value !=="object"){
                formData.append(key,value);
            }
        });

        if(gameData.cover instanceof File) {
            formData.append("cover", gameData.cover)
        }

        if (Object.keys(errors).length > 0) {

            setFormErrors(errors);
            console.log('Form validation failed:', errors);
          }
          else {
            setFormErrors({});
            setIsSubmitting(true);

        try {
            const response = await axios({
                url: `${getApi()}/games/createGame`,
                method:"post",
                data: formData,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")
                        ? JSON.parse(localStorage.getItem("token"))
                        : ""
                      }`,
                  },
            })

            if(response.data.success){
                toast.success(response.data.message);
                history.push("/dashboard/app/games-list")
                console.log('Game created successfully:', response.data);

                // Optionally, reset the form after successful submission
                setGameData({
                    gameName: '',
                    short_name: '',
                    description: '',
                    platform: '',
                    cover: null,
                });
            }
        }catch(error) {
            console.log(error);
            toast.error(error.message);
        }
        finally {
            setIsSubmitting(false); // 
        }
    }
}
    

    const validate = (values) => {
        const errors = {};
        if(!values.gameName.trim()){
            errors.gameName= "Game Name is required";
        }
        if(!values.short_name.trim()){
            errors.short_name= "Game short Name is required";
        }
        if(!values.platform.trim()){
            errors.platform= "Platform is required";
        }
        if(!values.description.trim()){
            errors.description= "Game Description is required";
        }
        if (!values.cover || !(values.cover instanceof File)) {
            errors.cover = 'Cover image is required';
          } else if (!isValidImageType(values.cover)) {
            errors.cover = 'Invalid image format. Only Jpg, png, gif accepted';
          }

          return errors;
    }

    return (
        <div className="bg-white rounded-md relative z-10">
            <div className="pt-6 px-6 text-2xl font-bold text-black">
                <h4>Create Game</h4>
            </div>
            <form onSubmit={ handleCreateGames} className="p-5 flex flex-wrap">


       <div className='flex flex-wrap'>
                <div className="w-full md:w-1/2 px-3 mb-4">
                    <label htmlFor="gameName" className="text-gray-700 text-md  mb-2 block">
                        Game Name:
                    </label>
                    <input
                        name="gameName"
                        type="text"
                        value={gameData.gameName}
                        onChange={handleChange}
                        placeholder="Game Name"
                        className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm py-3 px-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    />
                    {formErrors.gameName && (
              <p className="text-red-500 text-sm">{formErrors.gameName}</p>
            )}
                </div>

                <div className="w-full md:w-1/2 px-3 mb-4">
                    <label htmlFor="shortName" className="text-gray-700 text-md l,;'./
                    d]e[-o] mb-2 block">
                        Short Name:
                    </label>
                    <input
                        name="short_name"
                        type="text"
                        value={gameData.short_name}
                        onChange={handleChange}
                        placeholder="Short Name"
                        className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm py-3 px-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    />
                     {formErrors.short_name && (
              <p className="text-red-500 text-sm">{formErrors.short_name}</p>
            )} 
                </div>
                <div className="w-full md:w-1/2 px-3 mb-4">
                    <label htmlFor="gameName" className="text-gray-700 text-md  mb-2 block">
                        Game Platform:
                    </label>
                    <select
                        name="platform"
                        value={gameData.platform}
                        onChange={handleChange}
                        className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm p-1.5 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    >
                        <option value="">Select Platform</option>
                        {gamesPlatform.map((platform, index) => (
                            <option key={index} value={platform}>
                                {platform}
                            </option>
                        ))}
                    </select>


                    {formErrors.platform && (
              <p className="text-red-500 text-sm">{formErrors.platform}</p>
            )}
                </div>


                <div className="w-full md:w-1/2 px-3 mb-4">
                    <label
                        htmlFor="cover"
                        className="text-gray-700 text-md mb-2"
                    >
                        Cover Image
                    </label>
                    <input
                        type="file"
                        id="cover"
                        name="cover"
                        onChange={handleCover}
                        placeholder="Banner URL"
                        className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm  px-2 py-1 h-8 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    />
                    {formErrors.cover && (
                        <p className="text-red-500 text-sm">{formErrors.cover}</p>
                    )}
                </div>

                <div className="w-full md:w-1/2 px-3 mb-4">
                    <label
                        htmlFor="description"
                        className="text-gray-700 text-md mb-2"
                    >
                        Description:
                    </label>
                    <textarea
                        name="description"
                        id="description"
                        value={gameData.description}
                        onChange={handleChange}
                        placeholder="Enter game description"
                        className="border text-gray-900 text-sm border-gray-300 rounded-md shadow-sm p-1.5 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                        rows="4"
                    ></textarea>
                    {formErrors.description && (
                        <p className="text-red-500 text-sm">{formErrors.description}</p>
                    )}
                </div>
                </div>

                <div className="px-3 block">
            <button
              type="submit"
              className="bg-blue-500 block hover:bg-blue-700 text-white font-bold py-2 px-4 mt-2 rounded focus:outline-none focus:shadow-outline block"
              disabled={Object.keys(formErrors).length === 0 && isSubmitting}
            >
             {isSubmitting ? 'Submitting...' : 'Add Game'}
            </button>
          </div>
            </form>
        </div>
    );
};

export default createGame;
